import React from 'react';

export const SummaryInstructions = ({ children }: { children: string }) => {
  return (
    <div className="summary-howto-country">
      <div
        className="summary-howto-country-content"
        dangerouslySetInnerHTML={{ __html: children }}
      ></div>
    </div>
  );
};
