import React, { PropsWithChildren } from 'react';
import './Container.scss';

export const Container = ({
  children,
  className,
}: PropsWithChildren & {
  className?: string;
}) => {
  return <div className={`wrapper ${className ?? ''}`}>{children}</div>;
};
