import moment from 'moment';

// Import only the locales we are going to use
import 'moment/locale/nl';
import 'moment/locale/nl-be';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/da';
import 'moment/locale/it';
import 'moment/locale/es';

// Export moment with correct locale
export default moment;
