import { ISfyProduct } from "../lib/firestore/sfyShops/collections/sfyProducts/ISfyProduct";
import { deriveOrder, deriveRootOrder } from "./orders/derived_order";
import { BaseRmaRequest, TRMAItem } from "./rma_request_types";
import { SfyLineItem, SfyOrderType } from "./sfy_order_types";
import { TProductData } from "./TProductData";

export const validSubjects = ["order", "rma", "projected_order", "line_item", "product"] as const;

export type ConditionSubject = (typeof validSubjects)[number];

export const validRMAProperties = [
  "exchanges_count",
  "refunds_count",
  "return_reason_ids",
] as const;

export type ConditionRMAProperties = (typeof validRMAProperties)[number];

export const validProjectedOrderProperties = ["order_value_excl_shipping"] as const;

export type ConditionProjectedOrderProperties = (typeof validProjectedOrderProperties)[number];

export const validOrderProperties = [
  ...validProjectedOrderProperties,
  "customer_name",
  "order_value_incl_shipping",
  "shipping_value",
  "shipping_country_code",
  "tags",
] as const;

export type ConditionOrderProperties = (typeof validOrderProperties)[number];

export const validLineItemProperties = ["product_tags"] as const;

export type ConditionLineItemProperties = (typeof validLineItemProperties)[number];

export const validProductProperties = ["tags"] as const;

export type ConditionProductProperties = (typeof validProductProperties)[number];

export const validNumericProperties = [
  "order_value_excl_shipping",
  "order_value_incl_shipping",
  "shipping_value",
  "exchanges_count",
  "refunds_count",
] as const;

export type ConditionNumericProperties = (typeof validNumericProperties)[number];

export const validStringProperties = ["customer_name", "shipping_country_code"] as const;

export type ConditionStringProperties = (typeof validStringProperties)[number];

export const validArrayProperties = [
  "tags",
  "properties",
  "product_tags",
  "return_reason_ids",
] as const;

export type ConditionArrayProperties = (typeof validArrayProperties)[number];

export const validNumericOperators = ["==", ">=", "<=", "!=", ">", "<"] as const;

export type ConditionNumericOperator = (typeof validNumericOperators)[number];

export const validStringOperators = ["==", "!=", "includes"] as const;

export type ConditionStringOperator = (typeof validStringOperators)[number];

export const validArrayOperators = ["includes"] as const;

export type ConditionArrayOperator = (typeof validArrayOperators)[number];

export type SubjectProperties<T extends ConditionSubject> = T extends "order"
  ? ConditionOrderProperties
  : T extends "rma"
  ? ConditionRMAProperties
  : T extends "projected_order"
  ? ConditionProjectedOrderProperties
  : T extends "line_item"
  ? ConditionLineItemProperties
  : T extends "product"
  ? ConditionProductProperties
  : never;

export type SubjectCondition<T extends ConditionSubject> =
  | {
      property: {
        subject: T;
        property: SubjectProperties<T> & ConditionNumericProperties;
      };
      operator: ConditionNumericOperator;
      value: number;
    }
  | {
      property: {
        subject: T;
        property: SubjectProperties<T> & ConditionStringProperties;
      };
      operator: ConditionStringOperator;
      value: string;
    }
  | {
      property: {
        subject: T;
        property: SubjectProperties<T> & ConditionArrayProperties;
      };
      operator: ConditionArrayOperator;
      value: string;
    };

export type OrderCondition = SubjectCondition<"order">;
export type ProjectedOrderCondition = SubjectCondition<"projected_order">;
export type RmaCondition = SubjectCondition<"rma">;
export type LineItemCondition = SubjectCondition<"line_item">;

export type ConditionNode<T extends ConditionSubject> = SubjectCondition<T> | ConditionBranching<T>;

export const validBooleanOperators = ["and", "or"] as const;

type ConditionBranching<T extends ConditionSubject> = {
  operator: (typeof validBooleanOperators)[number];
  nodes: ConditionNode<T>[];
};

export type PropertyMap<T> = Record<T & ConditionNumericProperties, number> &
  Record<T & ConditionStringProperties, string> &
  Record<T & ConditionArrayProperties, string[]>;

export type PropertiesMap<T extends ConditionSubject> = {
  [K in T]: PropertyMap<SubjectProperties<K>>;
};

export const deriveOrderProperties = (
  order: SfyOrderType,
): PropertyMap<ConditionOrderProperties> => ({
  order_value_excl_shipping: Number(order.totalPrice) - Number(order.totalShippingPrice || 0),
  order_value_incl_shipping: Number(order.totalPrice),
  shipping_value: Number(order.totalShippingPrice || 0),
  customer_name: [order.billingAddress.firstName, order.billingAddress.lastName]
    .filter(Boolean)
    .join(" "),
  shipping_country_code: order.shippingAddress.countryCode,
  tags: order.tags.split(", "),
});

export const deriveProjectedOrderProperties = (
  order: SfyOrderType,
  rma: BaseRmaRequest<any>,
): PropertyMap<ConditionProjectedOrderProperties> => {
  const projectedOrder = deriveOrder(deriveRootOrder(order), rma);

  return {
    order_value_excl_shipping: projectedOrder.totalPrice,
  };
};

export const deriveRMAProperties = <T>(
  rma: BaseRmaRequest<T>,
): PropertyMap<ConditionRMAProperties> => ({
  exchanges_count: (rma.rmaItems ?? []).filter((item) => item.type === "exchange").length,
  refunds_count: (rma.rmaItems ?? []).filter((item) => item.type === "return").length,
  return_reason_ids: rma.rmaItems?.flatMap((item) => item.reasonIds ?? []) ?? [],
});

export const deriveLineItemProperties = (
  lineItem: SfyLineItem,
  product: TProductData,
): PropertyMap<ConditionLineItemProperties> => {
  if (lineItem.productId !== product.id) {
    throw new Error("Line item and product do not match");
  }

  return {
    product_tags: product.tags,
  };
};

export const deriveCombinedLineItemProperties = (
  lineItems: (SfyLineItem | TRMAItem)[],
  products: Partial<ISfyProduct>[],
): PropertyMap<ConditionLineItemProperties> => {
  const productTags = lineItems.reduce((acc, lineItem) => {
    const productId = "productId" in lineItem ? lineItem.productId : lineItem.originalProductId;

    const product = products.find((p) => {
      return p.id === productId;
    });

    if (!product) {
      throw new Error(`Product ${productId} not found`);
    }

    return [...acc, ...(product.tags ?? [])];
  }, [] as string[]);

  return {
    product_tags: productTags,
  };
};

export const deriveProductProperties = (
  product: Pick<TProductData, "tags">,
): PropertyMap<ConditionProductProperties> => ({
  tags: product.tags,
});

type CompareFn = {
  (a: number, b: number, operator: ConditionNumericOperator): boolean;
  (a: string, b: string, operator: ConditionStringOperator): boolean;
  (a: string[], b: string, operator: ConditionArrayOperator): boolean;
};

const compare: CompareFn = (a, b, operator) => {
  switch (operator) {
    case "==":
      return a.toString().toLowerCase() === b.toString().toLowerCase();
    case ">=":
      return a >= b;
    case "<=":
      return a <= b;
    case "!=":
      return a.toString().toLowerCase() !== b.toString().toLowerCase();
    case ">":
      return a > b;
    case "<":
      return a < b;
    case "includes": {
      if (Array.isArray(a)) {
        return a.includes(b as string);
      }

      const aLowercase = (a as string).toLowerCase();
      const bLowercase = (b as string).toLowerCase();
      return aLowercase.indexOf(bLowercase) !== -1;
    }
  }
};

export const evaluateCondition = <T extends ConditionSubject>(
  condition: ConditionNode<T>,
  props: PropertiesMap<T>,
): boolean => {
  if ("nodes" in condition && condition.operator === "and") {
    return condition.nodes.every((node) => evaluateCondition(node, props));
  }
  if ("nodes" in condition && condition.operator === "or") {
    return condition.nodes.some((node) => evaluateCondition(node, props));
  }

  if ("nodes" in condition) {
    throw new Error("Invalid condition");
  }

  // This was too complex for TS to figure out
  return compare(
    (props[condition.property.subject] as any)[condition.property.property],
    condition.value as any,
    condition.operator as any,
  );
};
