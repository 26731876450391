import { useEffect, useState } from 'react';
import app from 'firebase/app';
import { BaseRmaRequest, SelectedCarrier } from '../../../../functions/src/shared';
import { useFirebase } from '../firebase/context';

export const useReturnOption = (
  rmaRequestId: string,
): {
  isFetching: boolean;
  returnOption: SelectedCarrier | null;
} => {
  const firebase = useFirebase();
  const [isFetching, setIsFetching] = useState(false);
  const [returnOption, setReturnOption] = useState<SelectedCarrier | null>(null);

  useEffect(() => {
    const fetchReturnOption = async () => {
      const { rmaRequestDoc } = firebase;

      setIsFetching(true);

      // Start listening to rmaRequestDoc changes
      const doc = await rmaRequestDoc(rmaRequestId).get();

      if (!doc.exists) {
        throw new Error(`No rmaRequest document with id ${rmaRequestId} found.`);
      }

      const { return: returnInfo } = doc.data() as BaseRmaRequest<app.firestore.Timestamp>;

      if (typeof returnInfo === 'object') {
        setReturnOption(returnInfo);
      }
      setIsFetching(false);
    };

    fetchReturnOption();
  }, [firebase, rmaRequestId]);

  return {
    isFetching,
    returnOption,
  };
};
