/**
 * Get the URL parameters
 *
 * @return {string} The Shopify Domain name
 */
export const getShopifyDomain = () => {
  const paths = window.location.pathname.split('/').filter((e) => e);
  const shop = paths.shift();

  return shop ? `${shop}.myshopify.com` : null;
};
