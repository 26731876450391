import { DerivedOrderState } from "../orders/derived_order";
import { LineItemAdjustment } from "../rma_request_types";
import { LineItemDiscountProvider, lineItemDiscountsProviders } from "./LineItemDiscountsProvider";
import { calculateLineItemPrice } from "./calculateLineItemPrice";

export const calculateLineItemAdjustments = (
  nextState: DerivedOrderState,
  lineItemDiscountsProvider: LineItemDiscountProvider,
) => {
  const keptItems = nextState.lineItems;

  const discountAdjustmentProvider = lineItemDiscountsProviders[lineItemDiscountsProvider];

  return keptItems
    .map((item): LineItemAdjustment | null => {
      if (!item.id || !("totalDiscount" in item)) {
        // This is a draft item, so no adjustments
        return null;
      }

      const updatedDiscounts = discountAdjustmentProvider(item, null, nextState);

      const totalDiscounts = item.discountAllocations.reduce(
        (acc, allocation) => acc + Number(allocation.amount),
        0,
      );
      const totalDiscountSingle = totalDiscounts / item.quantity;

      const originalPaidPrice = Number(item.price) - totalDiscountSingle;
      const newPaidPrice = calculateLineItemPrice(
        Number(item.price),
        updatedDiscounts,
        lineItemDiscountsProvider,
      );

      const difference = newPaidPrice - originalPaidPrice;
      // Otherwise you can get rounding error values, like 0.0000000000000001
      const roundedDifference = Math.floor(difference * 100) / 100;

      if (roundedDifference === 0) {
        return null;
      }

      const adjustment: LineItemAdjustment = {
        lineItemId: item.id,
        amount: roundedDifference,
      };

      return adjustment;
    })
    .filter((adjustment): adjustment is NonNullable<typeof adjustment> => adjustment !== null);
};
