import React from 'react';

import { Notification } from '../Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { interpolateAppText, useAppTexts } from '../../hooks/useAppTexts';

export const ReviewComment = ({
  isCommentFieldOpen,
  minCommentCharacters,
  handleCommentValueChange,
  handleAddComment,
  handleClearComment,
  addCommentText,
  clearCommentText,
  infoText,
}) => {
  const appTexts = useAppTexts();

  const commentFieldMarkup = isCommentFieldOpen && (
    <textarea
      autoFocus
      rows="6"
      className="textarea review-comment-field"
      placeholder={interpolateAppText(appTexts.itemWizard.typeCommentHereAndCount, {
        count: minCommentCharacters,
      })}
      onChange={(event) => handleCommentValueChange(event.target.value)}
    ></textarea>
  );

  const actionMarkup = isCommentFieldOpen ? (
    <button
      className="button is-fullwidth custom-button has-icon "
      onClick={() => handleClearComment()}
    >
      <FontAwesomeIcon icon="comment-slash" />
      <span>{clearCommentText}</span>
    </button>
  ) : (
    <button
      className="button is-fullwidth custom-button has-icon "
      onClick={() => handleAddComment()}
    >
      <FontAwesomeIcon icon="comment-dots" />
      <span>{addCommentText}</span>
    </button>
  );

  return (
    <div className="review-block">
      <div className="review-comment">
        <Notification hide={isCommentFieldOpen}>
          <p>{infoText}</p>
        </Notification>

        {commentFieldMarkup}
        {actionMarkup}
      </div>
    </div>
  );
};
