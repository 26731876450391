import { TrackingStatus } from '../../../../../functions/src/shared';

export const determineTrackingStatus = (
  currentStatus: TrackingStatus | null,
  rmaIsCompleted?: boolean,
) => {
  if (rmaIsCompleted) {
    return TrackingStatus.Delivered;
  }

  return currentStatus;
};
