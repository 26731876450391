import {
  SubmitRequestResponse,
  TDeliveryType,
  AppTexts,
  TSubmitRequestPayload,
  SelectedCarrier,
} from '../../../../../functions/src/shared';
import { appConfig } from '../../config';
import {
  CollectedWizardData,
  WizardAction,
  mapWizardActionToPayloadAction,
} from '../../contexts/WizardContext/WizardContext';
import { interpolateAppText } from '../../hooks/useAppTexts';

export const submitReturn = async (
  collected: Partial<Record<string, WizardAction | null>>,
  comment: string,
  token: string,
  appTexts: AppTexts,
  supportEmail: string,
  deliveryOption: TDeliveryType,
  returnOption: SelectedCarrier, // should contain the alternative phonenumber
): Promise<SubmitRequestResponse> => {
  // set comment to null if it's an empty string
  const fixedComment = comment.length > 0 ? comment : null;

  const payload: TSubmitRequestPayload = {
    rma_items: createRmaItems(collected as CollectedWizardData),
    comment: fixedComment,
    return: returnOption,
    delivery: deliveryOption,
  };

  const url = `${appConfig.cloudFunctionsUri}/CustomerSession/submit-request`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-shopify-rma-auth-token': token,
    },
    body: JSON.stringify(payload),
  };

  try {
    const response = await fetch(url, options);

    switch (response.status) {
      case 200:
        const responseData = (await response.json()) as SubmitRequestResponse;
        return responseData;
      case 406:
        throw appTexts.review.error.notAccepted;
      case 408:
        throw appTexts.generic.error.timeout;
      default:
        throw interpolateAppText(appTexts.generic.error.other, { supportEmail: supportEmail });
    }
  } catch (error) {
    console.error('Error submitting /CustomerSession/submit-request: ', error);
    throw interpolateAppText(appTexts.generic.error.other, { supportEmail: supportEmail });
  }
};

export const createRmaItems = (collected: CollectedWizardData) => {
  const rmaItems = Object.values(collected)
    .filter((item): item is WizardAction => Boolean(item?.initialItem))
    .map(mapWizardActionToPayloadAction);

  return rmaItems;
};
