import { useCallback, useState } from 'react';
import { BaseRmaRequest } from '../../../../functions/src/shared';
import { useFirebase } from '../firebase/context';

export const useRmaRequest = (rmaRequestId: string) => {
  const firebase = useFirebase();
  const [isLoadingRmaRequest, setIsLoadingRmaRequest] = useState(false);
  const [rmaRequestError, setRmaRequestError] = useState<Error | null>(null);
  const [rmaRequest, setRmaRequest] = useState<BaseRmaRequest | null>(null);

  const fetchRmaRequest = useCallback(async () => {
    setIsLoadingRmaRequest(true);

    try {
      const req = await firebase.rmaRequestDoc(rmaRequestId).get();
      setRmaRequest(req.data() as BaseRmaRequest);
    } catch (error: any) {
      setRmaRequestError(error);
    } finally {
      setIsLoadingRmaRequest(false);
    }
  }, [firebase, rmaRequestId]);

  return {
    fetchRmaRequest,
    rmaRequestError,
    rmaRequest,
    isLoadingRmaRequest,
  };
};
