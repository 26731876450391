import React from 'react';

export const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
    enableBackground="new 0 0 20 20"
    xmlSpace="preserve"
  >
    <g>
      <polygon points="14.6,5.6 8,12.2 5.4,9.7 4,11.1 8,15.1 16,7.1  " />
      <path d="M10,0C4.5,0,0,4.5,0,10c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C20,4.5,15.5,0,10,0z M10,18   c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8c4.4,0,8,3.6,8,8C18,14.4,14.4,18,10,18z" />
    </g>
  </svg>
);
