import React, { useEffect } from 'react';

/**
 * @desc opens a modal window. The content is derived from the children prop
 * @prop {bool} show - hide/show the modal
 * @prop {function} onClose - trigger used to close the modal
 * @prop {string} customClass - additional class to control the style of the modal component
 * @return {component}
 */

export const Modal = ({ show, customClass, onClose, children }) => {
  // prevent page scrolling when modal is active
  useEffect(() => {
    if (show) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'unset';
    }
  }, [show]);

  return (
    <div className={`modal ${show ? 'is-active' : ''} ${customClass ? customClass : ''}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content">{children}</div>
      <button className="modal-close is-large" onClick={onClose} aria-label="close" />
    </div>
  );
};
