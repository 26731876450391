import { SfyOrderType } from "../sfy_order_types";
import { BaseRmaRequest } from "../rma_request_types";
import {
  ConditionNode,
  PropertiesMap,
  deriveOrderProperties,
  deriveProjectedOrderProperties,
  deriveRMAProperties,
  evaluateCondition,
  validSubjects,
} from "../properties_conditions";

export type Adjustment = {
  description: string;
  type: "fixed_amount" | "percentage";
  amount: number;
};

export type AppliedAdjustment = {
  description: string;
  type: "fixed_amount";
  amount: number;
};

export const validAdjustmentSubjects = [
  validSubjects[0],
  validSubjects[1],
  validSubjects[2],
] as const;

export type AdjustmentSubject = (typeof validAdjustmentSubjects)[number];

export type AdjustmentConditionNode = ConditionNode<AdjustmentSubject>;

export type AdjustmentSet = {
  adjustments: Adjustment[];
  condition: AdjustmentConditionNode;
};

export type AppliedAdjustmentsData = {
  totalAdjustments: number;
  adjustments: AppliedAdjustment[];
};

type AdjustmentPropertiesMap = PropertiesMap<"order" | "rma" | "projected_order">;

/**
 * Calculate adjustments for a specific input. This can be used both as a potential adjustment
 * or for a created RMA.
 */
export const calculateAdjustments = <T>(
  order: SfyOrderType,
  rma: BaseRmaRequest<T>,
  adjustmentSets: AdjustmentSet[],
): AppliedAdjustmentsData => {
  const props: AdjustmentPropertiesMap = {
    order: deriveOrderProperties(order),
    rma: deriveRMAProperties(rma),
    projected_order: deriveProjectedOrderProperties(order, rma),
  };

  const adjustmentsToApply = adjustmentSets.flatMap((adjustmentSet) => {
    const { adjustments, condition } = adjustmentSet;

    const shouldApply = evaluateCondition(condition, props);

    return shouldApply ? adjustments : [];
  });

  const rmaValue = rma.rmaTotalOrderRefundWorth ?? 0;

  const appliedAdjustments = adjustmentsToApply.map((adjustment): AppliedAdjustment => {
    if (adjustment.type === "fixed_amount") {
      return adjustment as AppliedAdjustment;
    }

    return {
      ...adjustment,
      type: "fixed_amount",
      amount: rmaValue * adjustment.amount,
    };
  });

  return {
    totalAdjustments: appliedAdjustments.reduce((acc, adjustment) => acc + adjustment.amount, 0),
    adjustments: appliedAdjustments,
  };
};
