import { SelectedCarrier, SelectedSendcloudOption } from '../../../../functions/src/shared';
import { WizardAction } from '../contexts/WizardContext/WizardContext';

export const filterByWeight = (
  options: SelectedCarrier[],
  wizardData: Partial<Record<string, WizardAction | null>>,
) => {
  return options.reduce((filteredOptions: SelectedCarrier[], option) => {
    // for now only applicable for Sendcloud
    if (!['sendcloud'].includes(option.labelProvider)) {
      filteredOptions.push(option);
      return filteredOptions;
    }

    // this could be better, but for now it works
    const sendCloudOption = option as SelectedCarrier & SelectedSendcloudOption;

    // check weights of collected products and hide methods that are not matching
    if (
      wizardData &&
      sendCloudOption.properties?.min_weight &&
      sendCloudOption.properties?.max_weight
    ) {
      const totalWeight = Object.values(wizardData).reduce((weight, item) => {
        return weight + (item?.initialItem.variant.weight_grams || 0);
      }, 1); // sendcloud does not want 0 values and starts with 1

      if (
        totalWeight >= sendCloudOption.properties.min_weight &&
        totalWeight <= sendCloudOption.properties.max_weight
      ) {
        filteredOptions.push(sendCloudOption);
      }
    } else {
      // add option
      filteredOptions.push(sendCloudOption);
    }

    return filteredOptions;
  }, []);
};
