import { TDeliveryType } from "./TDeliveryTypes";
import { TDiscountForOrder } from "./TDiscountForOrder";
import { SelectedCarrier } from "./CarrierOption";
import { ReturnAddressType } from "./ReturnAddress";
import { RMAProviderOrNone } from "./RMAProvider.enum";
import { TLabelProviders } from "./TLabelProviders";
import { AppliedAdjustmentsData } from "./adjustments/rma_adjustments";
import { TSubmitRequestPayloadAction } from "./api_payloads";
import { GlobalConfig } from "./configs/GlobalConfig";

export type TRMAReturnCondition = "OK" | "Damaged";

export type TRMAOperation = "exchange" | "return" | "combination" | "unknown";

export enum TrackingStatus {
  Submitted = "submitted",
  DroppedOff = "droppedOff",
  InTransit = "inTransit",
  Delivered = "delivered",
}

export type TrackingCheckpoint = {
  checkpoint_time: string;
  tag: TrackingStatus;
  slug: string;
  message: string;
  subtag_message: string;
};

export type TTrackingInfo<TimeType> = {
  trackingNumber: string;
  trackingLink: string;
  checkpoints: TrackingCheckpoint[];
  note: string;
  title: string;
  customerName: string;
  status: TrackingStatus;
  statusMessage: string;
  lastUpdate: TimeType;
};

export type LineItemAdjustment = {
  lineItemId: number;
  /**
   * Positive is increased cost, negative is decreased cost
   * @example
   * * 10 original paid with amount:  2 => new amount: 12
   * * 10 original paid with amount: -3 => new amount: 7
   */
  amount: number;
};

export type BaseRmaRequest<TimeType = number> = {
  rmaName: string;
} & Partial<{
  source: string;
  adjustments: AppliedAdjustmentsData;
  customerName: string | null;
  customerNote: string | null;
  adminNote: string | null;
  rmaHasError: boolean;
  tracking: TTrackingInfo<TimeType>;
  paperlessMethod: { name: string; code: number; barcode?: string } | null;
  orderCreatedAt: TimeType | null;
  orderFirstFulfillmentAt: TimeType | null;
  orderCurrency: string;
  orderId: number;
  orderName: string;
  orderShippingCountry: string | null;
  orderShippingCountryCode: string | null;
  orderDiscounts: TDiscountForOrder | null;
  draftOrderId: number | null;
  draftOrderInvoiceUrl: string | null;
  exchangeOrderId: number | null;
  rmaFlagReasons: string[];
  rmaIsFlagged: boolean;
  rmaItems: TRMAItem[];
  /** In case a kept item's discounts are invalid because of another return, we store it here */
  keptItemsAdjustments: LineItemAdjustment[];
  rmaRemoteOrderId: number | string | null;
  rmaRemoteId: number | string | null;
  /** Monta-specific field */
  rmaRemoteShipperId: number | null;
  /** Monta-specific field */
  rmaRemoteShipperCode: string | null;
  rmaRemoteLabelIsPaid: boolean | null;
  rmaReturnCheckoutUrl: string; // in case external payment is required
  rmaReturnLabel: string | string[] | null; // path to GCS with the label
  rmaReturnLabelError: string | null; // Error message when the label could not be obtained
  rmaCommercialInvoice: string | string[] | null; // path to GCS with commercial invoice PDF
  rmaIsSubmitted: boolean;
  rmaIsShipping: boolean;
  rmaIsPaymentPending: boolean;
  rmaIsProcessed: boolean;
  rmaIsReturned: boolean;
  rmaIsUnprocessable: boolean;
  rmaIsProcessError: boolean;
  rmaIsProcessedByAutoPilot: boolean;
  rmaIsProcessedInBulk: boolean;
  rmaIsCompleted: boolean;
  /** were all items of the request returned? */
  rmaAllItemsReturned: boolean;
  rmaHandedInAutoProcessed: boolean;
  rma3PLAutoProcessed: boolean;
  rmaSubmittedAt: TimeType | null;
  rmaShippedAt: TimeType | null;
  rmaReturnedAt: TimeType | null;
  rmaPaymentPendingAt: TimeType | null;
  rmaProcessedAt: TimeType | null;
  rmaProcessErrorAt: TimeType | null;
  returnLabelEmailSentAt: TimeType | null;
  returnDelayedEmailSentAt: TimeType | null;
  handedInEmailSentAt: TimeType | null;
  rmaType: TRMAOperation;
  rmaQuantity: number;
  rmaExchangeProcessed: TimeType | null;
  rmaRefundProcessed: TimeType | null;
  rmaTotalProductReturnWorth: number | null;
  /** Name is wrong, actually represents how much should be refunded because of exchange price mismatch. */
  rmaTotalProductExchangeWorth: number | null;
  /** Total value of refund-actions only */
  rmaTotalOrderRefundWorth: number | null;
  /** In case it was changed because not all items were returned */
  rmaTotalOrderRefundOldWorth?: number | null;
  rmaTotalOrderAdditionalPaymentWorth: number | null;
  rmaTotalRefundedInShopify: number | null;
  timeline: TRMATimeline<TimeType>[];
  rmaProvider: RMAProviderOrNone;
  refunds: {
    id: string; // unique ID that identifies the refund,
    items: { id: string; quantity: number }[];
    refundedCents: number;
  }[];
  delivery: TDeliveryType;
  returnInfo: TRMAReturnInformation;
  return: SelectedCarrier;
  tags: string[];
  seenDeduplicationIds?: Partial<Record<string, true>>;
}>;

export type TRMAReturnInformation = ReturnAddressType & {
  handler: keyof TLabelProviders;
};

export type RichRmaItem = TRMAItem & {
  originalPriceFormatted: string | null;
  originalDiscountedPriceFormatted: string | null;
  exchangePriceFormatted: string | null;
  exchangeDiscountedPriceFormatted: string | null;
};

export type RichRmaRequest<TimeType = number> = BaseRmaRequest<TimeType> & {
  rmaId: string;
} & Partial<{
    orderCreatedAtFormatted: string | null;
    rmaSubmittedAtFormatted: string | null;
    rmaProcessedAtFormatted: string | null;
    rmaReturnedAtFormatted: string | null;
    rmaShippedAtFormatted: string | null;
    status: {
      id: RmaStatus;
      date: number | null | undefined;
      dateFormatted: string | null;
    };
    rmaTotalProductReturnWorthFormatted: string | null;
    rmaTotalProductExchangeWorthFormatted: string | null;
    rmaTotalOrderRefundWorthFormatted: string | null;
    rmaTotalOrderRefundOldWorthFormatted: string | null;
    rmaTotalOrderAdditionalPaymentWorthFormatted: string | null;
    isReturnWindowClosed: boolean;
    itemTypeCounters: Record<TRMAOperation, number>;
    tracking: TTrackingInfo<TimeType> & {
      lastUpdateFormatted: string | null;
    };
    rmaItems: RichRmaItem[];
    timeline: RichRmaTimeline[];
  }>;

export enum RmaStatus {
  Submitted = "submitted",
  Shipping = "shipping",
  Returned = "returned",
  PaymentPending = "paymentPending",
  Processed = "processed",
  ProcessError = "processError",
}

export type TRMAItem = Partial<{
  lineItemId: number | null;
  exchangeLineItemId: number;
  originalSku: string | null;
  originalBundleSkus: string[] | null;
  originalProductId: number | null;
  originalProductTitle: string | null;
  originalVariantTitle: string | null;
  originalVariantId: number | null;
  originalPrice: number | null;
  originalDiscountedPrice: number | null;
  originalPaid: number | null;
  /** A convoluted value that's pretty much the exchange value, making sure you don't over-refund */
  originalValue: number | null;
  /** Value of the original in case of exchange AFTER re-applying transferred discounts */
  originalExchangeValue: number | null;
  exchangeSku: string | null;
  exchangeProductId: number | null;
  exchangeProductTitle: string | null;
  exchangeVariantId: number | null;
  exchangeVariantTitle: string | null;
  /** Exchange item value BEFORE re-applying transferred discounts */
  exchangePrice: number | null;
  /** Exchange item value AFTER re-applying transferred discounts */
  exchangeDiscountedPrice: number | null;
  type: TRMAOperation;
  /** Internal Ids of the reasons. This field will not exist in older RMA docs */
  reasonIds?: string[] | null;
  /** Third party ids of reasons, can be empty */
  reasons?: string[];
  /** Human readable reasons. This field will not exist in older RMA docs */
  reasonStrings?: string[];
  additionalComment: string | null;
  additionalReason: string | null;
  additionalCostOrRefund: number | null;
  totalAdditionalCostOrRefund: number | null;
  rmaReturned: boolean;
  rmaReturnedCondition: TRMAReturnCondition;
  rmaReturnedExternalComment: string;
  rmaReturnedExternalReference: string;
  rmaReturnedExternalReturnReasonId: number;
}>;

export type TShippingAndExchangeItem = {
  name: string;
  count: number;
};

export type RichRmaTimeline<TimeType = number> = TRMATimeline<TimeType> & {
  exactTimeFormatted: string | null;
  distanceTimeFormatted: string | null;
};

export type TRMATimeline<TimeType = number> = {
  time: TimeType;
  type: TimelineType;
  additionalPayload?: string[];
  message: string;
};

export type TimelineType = "ERROR" | "INFO";

export type TAdjustmentTarget = "all" | "default" | string;

export const quickSelectTypes = ["same-product", "same-tag", "neighboring-option"] as const;

export type QuickSelectType = (typeof quickSelectTypes)[number];

export type QuickSelect = {
  headerCopy?: string;
} & (
  | {
      type: (typeof quickSelectTypes)[0];
    }
  | {
      type: (typeof quickSelectTypes)[1];
      tagWithWildcard: string;
      /**
       * These option values need to match with alternative variant in order to be eligible for quick-select
       * @example ["Size"] // If returned product is Size M, then only Size M alternatives are eligible for quick-select
       */
      frozenOptions?: string[];
    }
  | QuickSelectNeighbourOption
);

export type QuickSelectNeighbourOption = {
  type: (typeof quickSelectTypes)[2];
  optionName: string;
  suggestionDepth: number;
  suggestedChoices: "lower" | "higher" | "both";
  lowerOptionTexts: string[];
  higherOptionTexts: string[];
};

export type Reason = {
  id: string;
  thirdPartyId?: string;
  label: string;
  additionalReasons?: Reason[];
  isInputField: boolean;
  inputFieldLabel?: string;
  inputFieldOptional?: boolean;
  quickSelects?: QuickSelect[];
  randomize?: boolean;
  group?: string | null;
};

export const wildcardToRegex = (wildcardedString: string): RegExp => {
  return new RegExp(`^${wildcardedString.replace(/\*/g, ".*")}$`);
};

export const createRmaItem = (
  rmaItem: TSubmitRequestPayloadAction,
  globalConfig: GlobalConfig,
): TRMAItem => {
  const reasons = globalConfig.reasons?.filter((r) => rmaItem.reasonIds?.includes(r.id));

  return {
    lineItemId: rmaItem.line_item_id || null,
    originalSku: rmaItem.original.sku || null,
    originalProductId: rmaItem.original.product_id || null,
    originalProductTitle: rmaItem.original.product_title || null,
    originalVariantId: rmaItem.original.variant_id || null,
    originalVariantTitle: rmaItem.original.variant_title || null,

    exchangeSku: rmaItem.exchange?.sku || null,
    exchangeProductId: rmaItem.exchange?.product_id || null,
    exchangeProductTitle: rmaItem.exchange?.product_title || null,
    exchangeVariantId: rmaItem.exchange?.variant_id || null,
    exchangeVariantTitle: rmaItem.exchange?.variant_title || null,
    type: rmaItem.type === "Exchange" ? "exchange" : "return",
    reasonIds: rmaItem.reasonIds || null,
    reasons: reasons?.map((r) => r.thirdPartyId).filter((r): r is string => Boolean(r)),
    reasonStrings: reasons?.map((r) => r.label),
    additionalComment: rmaItem.additionalComment || null,
    additionalReason: rmaItem.additionalReason || null,
  };
};

export enum HardcodedReasonIds {
  RETURN_TO_SENDER = "RETURN_TO_SENDER",
}
