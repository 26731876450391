import React, { PropsWithChildren, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

type NotificationStatus = 'info' | 'success' | 'warning' | 'danger';
type NotificationSize = 'small' | 'large';

type IProps = {
  status?: NotificationStatus;
  size?: NotificationSize;
  hide?: boolean;
  message?: ReactNode;
} & PropsWithChildren;

/**
 * @desc shows a notification with status color, icon and message. The message is derived from the children or message prop
 * @prop status - (optional) - determines the display colors of the notification.
 *   possible statuses:
 *     'info'
 *     'success'
 *     'warning'
 *     'danger'
 * @prop size - (optional) - 'small' or 'large'. With nothing specified the size is normal
 */
export const Notification = ({ status, size, hide, message, children }: IProps) => {
  if (hide) return null;

  return (
    <div className={`notification ${getStatus(status).className} ${getSize(size)}`}>
      <div className="notification-header">
        <div className="notification-header-icon">
          <FontAwesomeIcon icon={getStatus(status).icon} size="lg" />
        </div>
        <div className="notification-header-message">{message || children}</div>
      </div>
      {message && <div className="notification-body">{children}</div>}
    </div>
  );
};

const getStatus = (
  status: NotificationStatus | undefined,
): {
  icon: IconName;
  className?: string;
} => {
  switch (status) {
    case 'info':
      return { icon: 'info-circle', className: 'is-info' };
    case 'success':
      return { icon: 'check', className: 'is-success' };
    case 'warning':
      return { icon: 'exclamation-triangle', className: 'is-warning' };
    case 'danger':
      return { icon: 'exclamation-circle', className: 'is-danger' };
    default:
      return { icon: 'info-circle' };
  }
};

const getSize = (size?: NotificationSize) => {
  let className = '';

  switch (size) {
    case 'small':
      className = 'is-size-7';
      break;
    case 'large':
      className = 'is-size-5';
      break;
    default:
    // do nothing
  }

  return className;
};
