import React, { Fragment, useEffect } from 'react';

import { useLoadedFirebaseData } from '../../firebase/hooks';
import { Helmet } from 'react-helmet-async';
import { WizardContext } from '../../contexts/WizardContext/WizardContext';
import { SummaryInstructions } from './SummaryInstructions';
import { SummaryItemCard } from './SummaryItemCard';
import { Notification } from '../Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useShippingLabel } from '../../hooks/useShippingLabel';
import { useReturnOption } from '../../hooks/useReturnOption';
import { getEstimatedProcessingTime } from '../../helpers/getEstimatedProcessingTime';
import { MultipleRefundsPossibleNotification } from '../MultipleRefundsPossibleNotification';
import moment from '../../helpers/localizedMoment';
import { localeMapping } from '../../contexts/AppTextsContext/localeMapping';
import { interpolateAppText, useAppTexts } from '../../hooks/useAppTexts';
import { useNonNullableContext } from '../../hooks/useNonNullableContext';
import { useLocation } from 'react-router-dom';
import { useOrderData } from '../../hooks/useOrderData';
import { useCommercialInvoice } from '../../hooks/useCommercialInvoice';

const Summary = () => {
  const location = useLocation<{ rmaRequestId: string }>();
  const firebase = useLoadedFirebaseData();
  const appTexts = useAppTexts();
  const { allActions, exchangeActions, hasActions, hasExchangeActions, refundTotal } =
    useNonNullableContext(WizardContext);
  const {
    publicConfig: appThemeData,
    discounts,
    config: { countrySettings },
  } = firebase;
  const { appTitle, supportEmail, language } = appThemeData;

  const { provideCommercialInvoice = false } = countrySettings?.['default'] || {};

  // Set moment locale based on domain language
  moment.locale(localeMapping(language));

  const { rmaRequestId } = location.state;

  const {
    paperless,
    isFetchingLabel,
    labelError,
    fetchShippingLabel,
    shippingLabelUrl,
    checkoutUrl,
  } = useShippingLabel(rmaRequestId);

  const {
    fetchCommercialInvoiceUrl,
    commercialInvoiceUrl,
    commercialInvoiceError,
    isCommercialInvoiceRequired,
    isFetchingCommercialInvoice,
  } = useCommercialInvoice(rmaRequestId);

  const { isFetching, returnOption } = useReturnOption(rmaRequestId);
  const {
    order: { email },
  } = useOrderData();

  const estimatedProcessingTimeBusinessDays = getEstimatedProcessingTime(firebase);

  // on first load effect
  useEffect(() => {
    if (
      returnOption &&
      returnOption.labelProvider !== 'budbee' &&
      !paperless && // this does not provide an url, so we need to check this explicit
      !shippingLabelUrl
    )
      fetchShippingLabel();
  }, [fetchShippingLabel, returnOption, shippingLabelUrl, paperless]);

  useEffect(() => {
    if (!commercialInvoiceUrl) fetchCommercialInvoiceUrl();
  }, [fetchCommercialInvoiceUrl, commercialInvoiceUrl]);

  const handleDownloadLabel = () => {
    if (!shippingLabelUrl) return;

    const anchor = window.document.createElement('a');
    anchor.href = shippingLabelUrl;
    anchor.target = '_blank';
    anchor.click();
  };

  const handleDownloadCommercialInvoice = () => {
    if (!commercialInvoiceUrl) return;

    const anchor = window.document.createElement('a');
    anchor.href = commercialInvoiceUrl;
    anchor.target = '_blank';
    anchor.click();
  };

  const handleRedirect = () => {
    if (!checkoutUrl) return;

    const anchor = window.document.createElement('a');
    anchor.href = checkoutUrl;
    anchor.click();
  };

  // Items to send back
  const itemsToSendBack = hasActions
    ? allActions.map(({ initialItem }, i) => {
        return (
          <SummaryItemCard
            key={`item-to-send-back-${i}`}
            image={initialItem.variant.image}
            options={initialItem.product.options}
            productTitle={initialItem.product.title}
            variantTitle={initialItem.variant.title}
            variants={initialItem.product.variants}
          />
        );
      })
    : null;

  // Items to receive
  const itemsToReceive = hasExchangeActions
    ? exchangeActions.map(({ exchangeFor }, i) => {
        return (
          <SummaryItemCard
            key={`item-to-receive-${i}`}
            image={exchangeFor.variant.image}
            options={exchangeFor.product.options}
            productTitle={exchangeFor.product.title}
            variantTitle={exchangeFor.variant.title}
            variants={exchangeFor.product.variants}
          />
        );
      })
    : null;

  const summaryMarkup = (
    <div className="items-list">
      <h1 className="items-list-title summary">{appTexts.summary.title}</h1>
      {refundTotal > 0 && discounts?.isExchangeOrder && <MultipleRefundsPossibleNotification />}
      <div className="summary-block-wrap">
        <div className="summary-action-bar">
          {returnOption === null && (
            <h2 className="summary-action-bar-title">{appTexts.summary.thanksSubmitting}</h2>
          )}
          {returnOption?.labelProvider === 'budbee' &&
            (isFetching || returnOption === null || !('estimatedPickupTime' in returnOption) ? (
              <React.Fragment>
                <h2 className="summary-action-bar-title">{appTexts.summary.submittingPickUp}</h2>
                <progress className="progress is-small is-primary" max="100"></progress>
              </React.Fragment>
            ) : (
              <div>
                <h2 className="summary-action-bar-title">{appTexts.summary.thanksSubmitting}</h2>
                <span>
                  {interpolateAppText(appTexts.summary.thanksSubmittingBudbee, {
                    day: moment(returnOption.estimatedPickupTime?.start).format('dddd, MMM Do'),
                    start: moment(returnOption.estimatedPickupTime?.start).format('LT'),
                    stop: moment(returnOption.estimatedPickupTime?.stop).format('LT'),
                    phoneNumber: returnOption.phoneNumber,
                  })}
                </span>
              </div>
            ))}
          {!checkoutUrl &&
            returnOption &&
            returnOption.labelProvider !== 'budbee' &&
            (isFetchingLabel ||
            (provideCommercialInvoice &&
              isCommercialInvoiceRequired &&
              isFetchingCommercialInvoice) ? (
              <Fragment>
                <h2 className="summary-action-bar-title">{appTexts.summary.generatingLabel}</h2>
                <progress className="progress is-small is-primary" max="100"></progress>
              </Fragment>
            ) : (
              !paperless && (
                <Fragment>
                  {shippingLabelUrl && !checkoutUrl && !labelError ? (
                    <Fragment>
                      <h2 className="summary-action-bar-title">{appTexts.summary.labelReady}</h2>
                      <button
                        className="button is-primary custom-button has-icon"
                        onClick={handleDownloadLabel}
                      >
                        <span>{appTexts.summary.downloadLabel}</span>
                        <FontAwesomeIcon icon="download" />
                      </button>
                    </Fragment>
                  ) : (
                    <h2 className="summary-action-bar-title">
                      <Notification size="small" status="warning">
                        {interpolateAppText(appTexts.summary.noLabel, {
                          supportEmail: supportEmail,
                        })}
                      </Notification>
                    </h2>
                  )}
                  {provideCommercialInvoice &&
                    isCommercialInvoiceRequired &&
                    !isFetchingCommercialInvoice && (
                      <Fragment>
                        {commercialInvoiceUrl && !commercialInvoiceError ? (
                          <div style={{ marginTop: '2rem' }}>
                            <button
                              className="button is-primary custom-button has-icon"
                              onClick={handleDownloadCommercialInvoice}
                            >
                              <span>{appTexts.summary.downloadCommercialInvoice}</span>
                              <FontAwesomeIcon icon="download" />
                            </button>
                          </div>
                        ) : (
                          <h2 className="summary-action-bar-title">
                            <Notification size="small" status="warning">
                              {interpolateAppText(appTexts.summary.noCommercialInvoice, {
                                supportEmail: supportEmail,
                              })}
                            </Notification>
                          </h2>
                        )}
                      </Fragment>
                    )}
                </Fragment>
              )
            ))}
          {checkoutUrl && (
            <Fragment>
              <h2 className="summary-action-bar-title">{appTexts.summary.paymentLinkReady}</h2>
              <button className="button is-primary custom-button" onClick={handleRedirect}>
                <span>{appTexts.summary.redirectToPayment}</span>
              </button>
            </Fragment>
          )}
          {email && !checkoutUrl && (
            <p className="summary-email-to">
              {paperless ? (
                <Fragment>
                  {appTexts.summary.mailWillBeSentToPaperless}
                  <span> {email}</span>
                  <br />
                  {appTexts.summary.mailWillBeSentToPaperlessSub}
                </Fragment>
              ) : shippingLabelUrl ? (
                <Fragment>
                  {appTexts.summary.mailWillBeSentTo}
                  <span> {email}</span>
                </Fragment>
              ) : (
                <Fragment>
                  {appTexts.summary.mailWillBeSentToOnlyInstructions}
                  <span> {email}</span>
                </Fragment>
              )}
            </p>
          )}
        </div>
        <div className="summary-howto">
          {estimatedProcessingTimeBusinessDays && (
            <div className="summary-howto-returntime">
              <span className="summary-howto-returntime-icon">
                <FontAwesomeIcon icon="info-circle" />
              </span>
              <p className="summary-howto-returntime-text">
                {interpolateAppText(appTexts.summary.estimatedProcessingTime, {
                  count: estimatedProcessingTimeBusinessDays.toString(),
                })}
              </p>
            </div>
          )}

          <h2 className="summary-howto-title">{appTexts.summary.howToTitle}</h2>

          <SummaryInstructions>{returnOption?.returnInstructionsHtml ?? ''}</SummaryInstructions>
        </div>

        {itemsToSendBack && (
          <div className="review-block">
            <h2 className="review-block-title">{appTexts.summary.itemsToSendBack}</h2>
            <div>{itemsToSendBack}</div>
          </div>
        )}

        {itemsToReceive && (
          <div className="review-block">
            <h2 className="review-block-title">{appTexts.summary.itemsToReceive}</h2>
            <div>{itemsToReceive}</div>
          </div>
        )}
      </div>
    </div>
  );

  // in case a redirect link is there, redirect
  if (checkoutUrl) {
    window.location.replace(checkoutUrl);
  }

  return (
    <React.Fragment>
      {/** @ts-ignore see https://github.com/staylor/react-helmet-async/issues/182 */}
      <Helmet>
        <title>{`${appTitle} - ${appTexts.summary.pageTitle}`}</title>
      </Helmet>
      {summaryMarkup}
    </React.Fragment>
  );
};

export default Summary;
