import React from 'react';
import './Timeline.scss';
import { TimelineElement } from './TimelineNode';

export const Timeline = ({ elements }) => {
  return (
    <div
      className={`timeline ${
        elements.length > 2 ? 'timeline--highfidelity' : 'timeline--lowfidelity'
      }`}
    >
      {elements.map(({ IconComponent, title, isActive, key }, index) => (
        <TimelineElement
          IconComponent={IconComponent}
          title={title}
          key={key}
          isActive={isActive}
          isFirst={index === 0}
        />
      ))}
    </div>
  );
};
