// Sourced from https://gist.github.com/psdtohtml5/7000529

function addBusinessDays(date, numberOfDays) {
  date = new Date(date.getTime());
  const day = date.getDay();
  date.setDate(
    date.getDate() +
      numberOfDays +
      (day === 6 ? 2 : +!day) +
      Math.floor((numberOfDays - 1 + (day % 6 || 1)) / 5) * 2,
  );
  return date;
}

module.exports = {
  addBusinessDays,
};
