
module.exports = {
  "bulmaCssVariablesDefs": {
    "primary": {
      "calls": [
        {
          "fn": "lighten",
          "fnArg": "500",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "dark-color",
          "fnArg": null,
          "composeArg": null
        },
        {
          "fn": "light-color",
          "fnArg": null,
          "composeArg": null
        }
      ]
    },
    "product": {
      "calls": [
        {
          "fn": "lighten",
          "fnArg": "500",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": null
        }
      ]
    },
    "secondary": {
      "calls": [
        {
          "fn": "lighten",
          "fnArg": "500",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        },
        {
          "fn": "color-invert",
          "fnArg": null,
          "composeArg": null
        }
      ]
    }
  }
}
