import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { WizardContext } from '../../contexts/WizardContext/WizardContext';
import { useFirebase } from '../../firebase/context';
import { warmUpFunctions } from '../../helpers/warmUpFunctions';
import { Notification } from '../Notification';
import { ReturnAlreadySubmittedNotification } from '../ReturnAlreadySubmittedNotification';
import { MultipleRefundsPossibleNotification } from '../MultipleRefundsPossibleNotification';
import { ReviewAddress } from './ReviewAddress';
import { ReviewDelivery } from './ReviewDelivery';
import { ReviewComment } from './ReviewComment';
import { ReviewItems } from './ReviewItems';
import { ReviewRefund } from './ReviewRefund';
import { submitReturn } from './submitReturn';
import { ReviewReturn } from './ReviewReturn';
import { interpolateAppText, useAppTexts } from '../../hooks/useAppTexts';
import { useNonNullableContext } from '../../hooks/useNonNullableContext';
import { TDeliveryType } from '../../../../../functions/src/shared';
import { useOrderData } from '../../hooks/useOrderData';
import { useHistory } from 'react-router-dom';
import { usePublicConfig, useSessionData } from '../../firebase/hooks';

const phoneNumberRegex = /^(\+|00)[1-9][0-9 \-().]{7,32}$/;

const Review = () => {
  const history = useHistory();
  const appTexts = useAppTexts();
  const {
    collectedWizardData,
    hasActions,
    hasExchangeActions,
    isReturnAlreadySubmitted,
    refundTotal,
    returnOption,
    setWizardData,
  } = useNonNullableContext(WizardContext);
  const firebase = useFirebase();
  const { userData, shippingAddress, deliveryOptions, discounts } = useSessionData();
  const { appTitle, supportEmail, language } = usePublicConfig();

  const {
    order: { currency, email },
  } = useOrderData();
  const { token } = userData;

  const [isCommentFieldOpen, setIsCommentFieldOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [streetAddress, setStreetAddress] = useState<{
    streetName: string;
    streetNumber: string;
  }>({ streetName: '', streetNumber: '' });
  const [submitError, setSubmitError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedDeliveryOption, setSelectedDeliveryOption] =
    useState<TDeliveryType>('pickup_point');

  const needsStreetAddress = returnOption?.labelProvider === 'sendcloud';
  const hasStreetAddress = streetAddress.streetName.length && streetAddress.streetNumber.length;

  // on first load effect warm up the user session cloud function
  useEffect(() => {
    warmUpFunctions('/CustomerSession/warmup');
  }, []);

  const minCommentCharacters = 10;

  const handleAddComment = () => setIsCommentFieldOpen(true);
  const handleClearComment = () => {
    handleCommentValueChange('');
    setIsCommentFieldOpen(false);
  };

  const handleCommentValueChange = (newValue: string) => {
    setComment(newValue);

    // reset validation and error states
    setSubmitError('');
  };

  const handlePhoneNumberValueChange = (newValue: string) => {
    setPhoneNumber(newValue);

    // reset validation and error states
    setSubmitError('');
  };

  const handleStreetAddressChange = (newValue: { streetName: string; streetNumber: string }) => {
    setStreetAddress(newValue);

    // reset validation and error states
    setSubmitError('');
  };

  const handleCommentValidation = () => {
    switch (true) {
      case !isCommentFieldOpen:
        return false;
      case comment === '' || comment.length <= 0:
        setSubmitError(appTexts.review.comment.commentIsEmpty);
        return true;
      case comment.length > 0 && comment.length < minCommentCharacters:
        setSubmitError(
          interpolateAppText(appTexts.review.comment.commentIsTooShort, {
            count: minCommentCharacters.toString(),
          }),
        );
        return true;
      default:
        return false;
    }
  };

  const handlePhoneNumberValidation = () => {
    if (phoneNumber === undefined) return false;
    if (!phoneNumberRegex.test(phoneNumber)) {
      setSubmitError(appTexts.review.budbee.phoneNumberInvalid);
      return true;
    }
  };

  const handleStreetAddressValidation = () => {
    // Check if validation is needed:
    if (!needsStreetAddress || !streetAddress) {
      // Apparently not, so we continue.
      return false;
    }

    if (streetAddress.streetName.length === 0) {
      setSubmitError(appTexts.review.sendcloud.streetNameInvalid);
      return true;
    }

    if (
      streetAddress.streetNumber.length === 0 ||
      // Somehow we need a number as a first "character".
      isNaN(parseInt(streetAddress.streetNumber.slice(0, 1))) ||
      streetAddress.streetNumber.length > 20
    ) {
      setSubmitError(appTexts.review.sendcloud.streetNumberInvalid);
      return true;
    }

    return false;
  };

  const handleSubmit = async () => {
    if (handleCommentValidation()) return;
    if (handlePhoneNumberValidation()) return;
    if (handleStreetAddressValidation()) return;

    setSubmitError('');
    setIsSubmitting(true);

    let result;

    try {
      if (!returnOption) {
        throw new Error('No returnOption selected');
      }

      result = await submitReturn(
        collectedWizardData,
        comment,
        token ?? '',
        appTexts,
        supportEmail,
        selectedDeliveryOption,
        {
          ...returnOption,
          ...(phoneNumber ? { phoneNumber } : { phoneNumber: shippingAddress.phone || '' }),
          ...(streetAddress ? { streetAddress } : {}),
        },
      );
    } catch (e: any) {
      setSubmitError(e);
      setIsSubmitting(false);
      return;
    }

    await setWizardData({
      isReturnAlreadySubmitted: true,
    });

    history.push({
      pathname: firebase.getPathTo('summary'),
      state: {
        rmaRequestId: result.requestId,
      },
    });
  };

  if (!returnOption) {
    throw new Error('No returnOption selected');
  }

  const actionBarMarkup = (
    <div className="review-action-bar">
      <Notification hide={submitError === ''} status="danger">
        <p>{submitError}</p>
      </Notification>

      <div className="review-action-bar-buttons">
        <button
          className="button is-fullwidth custom-button has-icon"
          onClick={() => history.goBack()}
          disabled={isSubmitting}
        >
          <FontAwesomeIcon icon="chevron-left" />
          <span>{appTexts.generic.goBack}</span>
        </button>
        <button
          className={`button is-primary is-fullwidth custom-button has-icon ${
            isSubmitting ? 'is-loading' : ''
          }`}
          onClick={handleSubmit}
          disabled={
            !(hasExchangeActions || hasActions) ||
            isSubmitting ||
            isReturnAlreadySubmitted ||
            (needsStreetAddress && !hasStreetAddress)
          }
        >
          <span>
            {returnOption.labelProvider === 'monta' && (returnOption.price?.amount ?? 0) > 0
              ? appTexts.review.submitReturnAndPay
              : appTexts.review.submitReturn}
          </span>
          <FontAwesomeIcon icon="chevron-right" />
        </button>
      </div>

      {appTexts.review &&
        appTexts.review.additionalRefundInfo &&
        appTexts.review.additionalRefundInfo.length > 0 && (
          <div className="review-action-bar-info">
            <Notification>
              <p>{appTexts.review.additionalRefundInfo}</p>
            </Notification>
          </div>
        )}
    </div>
  );

  const reviewMarkup = (
    <div className="items-list">
      {!(hasExchangeActions || hasActions) && (
        <Notification status="warning">
          <p>{appTexts.review.noItemsSelected}</p>
        </Notification>
      )}

      {isReturnAlreadySubmitted && <ReturnAlreadySubmittedNotification />}

      <h1 className="items-list-title summary">{appTexts.review.title}</h1>

      {refundTotal > 0 && discounts?.isExchangeOrder && <MultipleRefundsPossibleNotification />}

      <div className="review-block-wrap">
        <ReviewItems currency={currency} locale={language} />
        <ReviewRefund email={email} />

        {hasExchangeActions && deliveryOptions && deliveryOptions.options.length > 0 && (
          <ReviewDelivery
            selectedDeliveryOption={selectedDeliveryOption}
            handleChooseDelivery={setSelectedDeliveryOption}
          />
        )}
        {hasExchangeActions &&
          deliveryOptions &&
          shippingAddress &&
          deliveryOptions.options.length === 0 && <ReviewAddress address={shippingAddress} />}

        {selectedDeliveryOption !== 'pickup_point' && (
          <ReviewComment
            isCommentFieldOpen={isCommentFieldOpen}
            handleAddComment={handleAddComment}
            handleClearComment={handleClearComment}
            handleCommentValueChange={handleCommentValueChange}
            minCommentCharacters={minCommentCharacters}
            infoText={
              hasExchangeActions
                ? appTexts.review.comment.infoExchange
                : appTexts.review.comment.infoRefund
            }
            addCommentText={
              hasExchangeActions
                ? appTexts.review.comment.addAddress
                : appTexts.review.comment.addComment
            }
            clearCommentText={
              hasExchangeActions
                ? appTexts.review.comment.clearAddress
                : appTexts.review.comment.clearComment
            }
          />
        )}

        <ReviewReturn
          language={language}
          returnOption={returnOption}
          handlePhoneNumberValueChange={handlePhoneNumberValueChange}
          handleStreetAddressChange={handleStreetAddressChange}
        />

        {actionBarMarkup}
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {/** @ts-ignore see https://github.com/staylor/react-helmet-async/issues/182 */}
      <Helmet>
        <title>{`${appTitle} - ${appTexts.review.pageTitle}`}</title>
      </Helmet>
      {reviewMarkup}
    </React.Fragment>
  );
};

export default Review;
