export const formatCurrency = (
  value: number | null | undefined,
  currency: string | undefined,
  locale: string,
): string | null => {
  return typeof value === "number" && !isNaN(value) // We still have these checks for js-files who call this function
    ? new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
      }).format(value / 100)
    : null;
};
