import { useEffect, useState } from 'react';
import { TProductData } from '../../../../../../functions/src/shared';
import { appConfig } from '../../../config';

export const useProductsByTag = (
  tag: string,
  userToken: string,
): [TProductData[] | null, boolean, Error | null] => {
  const [productData, setProductData] = useState<TProductData[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchProductData = async () => {
      const url = `${
        appConfig.cloudFunctionsUri
      }/CustomerSession/products-by-collection?tag=${encodeURIComponent(tag)}`;
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-shopify-rma-auth-token': userToken,
        },
      };

      setIsLoading(true);
      try {
        const response = await fetch(url, options);

        if (response.status !== 200) return {};

        const responseData = await response.json();

        setProductData(responseData);
      } catch (error) {
        console.error('Error fetching from /CustomerSession/products-by-collection: ', error);
        if (error instanceof Error) {
          setError(error);
        }
      }
      setIsLoading(false);
    };

    fetchProductData();
  }, [userToken, tag]);

  return [productData, isLoading, error];
};
