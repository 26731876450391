import { useAppTexts } from '../../hooks/useAppTexts';
import React, { FC, useEffect, useState } from 'react';

export const ReviewSendcloudReturn: FC<{
  handleStreetAddressChange: (data: { streetName: string; streetNumber: string }) => void;
}> = ({ handleStreetAddressChange }) => {
  const appTexts = useAppTexts();
  const [streetName, setStreetName] = useState('');
  const [streetNumber, setStreetNumber] = useState('');

  useEffect(() => {
    handleStreetAddressChange({
      streetName,
      streetNumber,
    });
  }, [streetName, streetNumber]); // eslint-disable-line  react-hooks/exhaustive-deps

  return (
    <div className="review-block">
      <div className="box">
        <div className="review-sendcloud">
          <h1>{appTexts.review.sendcloud.header}</h1>
        </div>
        {appTexts.review.sendcloud.explanation}
        <div className="field">
          <div className="control">
            <input
              className={`input review-sendcloud-streetName`}
              placeholder={appTexts.review.sendcloud.streetNamePlaceholder}
              onChange={(event) => {
                setStreetName(event.target.value);
              }}
            />
            <input
              className={`input review-sendcloud-streetNumber`}
              placeholder={appTexts.review.sendcloud.streetNumberPlaceholder}
              onChange={(event) => {
                setStreetNumber(event.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
