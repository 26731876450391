import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import './BottomBar.scss';

export const BottomBar = ({
  leftChildren,
  middleChildren,
  nextButton,
}: {
  leftChildren?: ReactNode;
  middleChildren?: ReactNode;
  nextButton: {
    disabled: boolean;
    label: string;
    onClick: () => void;
  };
}) => {
  return (
    <div className="bottom-bar">
      <div className="bottom-bar__item left">{leftChildren}</div>
      <div className="bottom-bar__item center">{middleChildren}</div>
      <div className="bottom-bar__item right">
        <button
          className="button is-primary custom-button has-icon"
          onClick={nextButton.onClick}
          disabled={nextButton.disabled}
        >
          <span>{nextButton.label}</span>
          <FontAwesomeIcon icon="chevron-right" />
        </button>
      </div>
    </div>
  );
};
