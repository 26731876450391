// Building a library to reference icons throughout the app more conveniently
// https://github.com/FortAwesome/react-fontawesome

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCommentDots,
  faCommentSlash,
  faDownload,
  faExclamationCircle,
  faExclamationTriangle,
  faImage,
  faInfoCircle,
  faQuestionCircle,
  faSyncAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCommentDots,
  faCommentSlash,
  faDownload,
  faExclamationCircle,
  faExclamationTriangle,
  faImage,
  faInfoCircle,
  faQuestionCircle,
  faSyncAlt,
  faTimes,
);

export default library;
