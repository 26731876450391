export const agradiDiscountSettings = {
  // Line item property keys that hold values on historic discount values
  /**
   * Formatted as '<key>|<discount>,<key>|<discount>,...'
   *
   * @example 'agradi_partner|0.05,knhs_fnrs_partner|0.1,knhs_member|0.25,knhs_member_agradi|0.1,knhs_member_clothing|0.2,knhs_member_sportaccommodation|0.5,kwpn_member|0.1'
   */
  discountDefinitionsKey: "_pmdd" as const,
  /**
   * Formatted as '<variant_id>|<discount>,<variant_id>|<discount>,...'
   *
   * @example '_discount_bundle : 45524176175411|0,45523668959539|1'
   */
  bundleDiscountKey: "_discount_bundle" as const,
  /**
   * Holds the set identifier and discount value
   * Format: '<set-id>|<discount>'
   *
   * @example { name: "_discount_set", value:"some-set|0.1" }
   */
  setDiscountKey: "_discount_set" as const,
  /**
   * Prefix for showing what groups were active on line item. You can cross-reference this with the discountDefinitionsKey
   *
   * @example '_discount_group:agradi_partner : true'
   */
  discountGroupKey: "_discount_group" as const,
  /**
   * Shows all active discounts and percentages on the line item
   * ! Note that the percentage here is base-100 not base-1
   * Format: '<percent_whole>|<discount_key>,<percent_whole>|<discount_key>,...'
   *
   * @example '_badges:10|set, 10|kwpn_member, 10|knhs_fnrs_partner'
   */
  badgesKey: "_badges" as const,
  /**
   * Prefix of properties name to identify applied discounts
   * 
   * @example ```{
      "name": "_applied_discounts__knhs_member",
      "value": "0.25"
    }```
   */
  appliedDiscountPrefix: "_applied_discounts__" as const,

  /**
   * Holds the price AFTER discounts were applied.
   *
   * Example: set discounts was 10%, and shop original price was €400, then this value would be '360'
   */
  originalPriceKey: "_applied_discounts__original_price" as const,

  /**
   * Holds the total discounts in whole % (base-100)
   *
   * @example '10' // Equals 10%
   */
  totalDiscountKey: "_applied_discounts__total_discount" as const,

  memberPartnerGroups: [
    "agradi_partner",
    "knhs_fnrs_partner",
    "knhs_member",
    "knhs_member_agradi",
    "knhs_member_clothing",
    "knhs_member_sportaccommodation",
    "kwpn_member",
  ] as const,

  badgeSetKeyword: "set" as const,
};
