import {
  AppTexts,
  GlobalConfig,
  SfyOrderType,
  TProductData,
  deriveOrder,
  deriveRootOrder,
  getExchangeValues,
} from '../../../../../../functions/src/shared';
import {
  CollectedWizardData,
  ExchangeAction,
  ExchangeWizardItem,
  FullWizardItem,
  mapWizardActionToPayloadAction,
} from '../../../contexts/WizardContext/WizardContext';
import { formatProductOptions } from '../../../helpers/formatProductOptions';
import { getCurrencyFormat } from '../../../helpers/getCurrencyFormat';
import { createRmaItems } from '../../Review/submitReturn';
import { WizardSelectorOption } from './WizardSelector';
import { getInventoryQuantityByLocationId } from '../../../helpers/getInventoryByLocation';

export const createVariantOptions = (
  itemData: FullWizardItem,
  currentRma: CollectedWizardData,
  product: TProductData,
  globalConfig: GlobalConfig,
  appTexts: AppTexts,
  order: SfyOrderType,
  language: string,
) => {
  const { allowAdditionalPayment, inventoryThreshold, inventoryThresholdHideBelow } = globalConfig;

  return product.variants.flatMap(
    (variant): WizardSelectorOption<ExchangeAction['exchangeFor']>[] => {
      // check if item is same variant as original so we can add additional text
      const isCurrentVariant = variant.id === itemData.variant.id;

      // define selected step data
      const exchangeFor: ExchangeWizardItem = {
        product,
        variant,
      };

      const newAction: ExchangeAction = {
        action: 'exchange',
        initialItem: itemData,
        exchangeFor,
      };

      const draftNewRma: CollectedWizardData = {
        ...currentRma,
        [itemData.selectionId]: newAction,
      };

      const rmaItems = createRmaItems(draftNewRma);
      const currentState = deriveRootOrder(order);
      const nextState = deriveOrder(currentState, rmaItems);

      const exchangePrice = Number(variant.price);

      const { exchangeDiscountedPrice, originalExchangeValue: exchangeValue } = getExchangeValues(
        currentState,
        nextState,
        mapWizardActionToPayloadAction(newAction),
        {
          originalProductId: itemData.product.id,
          originalVariantId: itemData.variant.id,
          originalPrice: itemData.originalPrice,
          originalVariantCurrentPrice: itemData.currentVariantPrice,
          originalProductTags: itemData.product.tags,
          originalDiscountedPrice: itemData.discountedPrice ?? itemData.originalPrice,
          exchangeProductId: product.id,
          exchangeVariantId: variant.id,
          exchangeProductTags: product.tags,
          exchangeVariantCurrentPrice: exchangePrice,
        },
        globalConfig,
      );
      exchangeFor.discountedPrice = exchangeDiscountedPrice;

      const isPriceTooHigh = exchangeDiscountedPrice > itemData.originalPrice;
      const priceDifference = isPriceTooHigh ? exchangeDiscountedPrice - exchangeValue : 0;

      // check if available and inventory quantity is equal or higher than threshold
      let isAvailable = false;
      if (globalConfig.useOverallInventory || globalConfig.useOverallInventory === undefined) {
        isAvailable =
          variant.inventory_quantity >= (inventoryThreshold ?? 0) ||
          variant.inventory_policy === 'continue';
      } else {
        isAvailable =
          getInventoryQuantityByLocationId(
            (globalConfig.selectedInventoryLocations || []).map((location) => location.id),
            variant.inventory_at_location || {},
          ) > (inventoryThreshold ?? 0) || variant.inventory_policy === 'continue';
      }

      const isHidden = !isAvailable && inventoryThresholdHideBelow;

      if (isHidden) return [];

      return [
        {
          label: `${formatProductOptions(product.options)}${variant.title}`,
          value: exchangeFor,
          disabled: (!allowAdditionalPayment && isPriceTooHigh) || !isAvailable,
          tagLeft: isCurrentVariant && (
            <span className="tag is-warning">{appTexts.itemWizard.isCurrentVariant}</span>
          ),
          tagRight: allowAdditionalPayment && priceDifference > 0 && (
            <span className="tag is-primary item-wizard-option-add-to-price has-parentheses">
              + {getCurrencyFormat(priceDifference, order.currency, language)}
            </span>
          ),
          formattedPrice: getCurrencyFormat(exchangeDiscountedPrice, order.currency, language),
          chevronOverride: !isAvailable && (
            <span className="tag is-danger">{appTexts.itemWizard.outOfStock}</span>
          ),
        },
      ];
    },
  );
};
