import { DerivedOrderState, TSubmitRequestPayloadAction, calculateLineItemPrice } from ".";
import { GlobalConfig, shouldRespectPriceIncreaseOnExchange } from "./configs/GlobalConfig";
import { lineItemDiscountsProviders } from "./discounts/LineItemDiscountsProvider";
import { bound } from "./utils";

export const getExchangeValues = (
  currentOrderState: DerivedOrderState,
  nextOrderState: DerivedOrderState,
  rmaItem: TSubmitRequestPayloadAction,
  {
    originalProductId,
    originalVariantId,
    originalVariantCurrentPrice,
    originalProductTags,
    originalPrice,
    originalDiscountedPrice,
    exchangeProductId,
    exchangeVariantId,
    exchangeVariantCurrentPrice,
    exchangeProductTags,
  }: {
    originalProductId: number;
    originalVariantId: number;
    originalVariantCurrentPrice: number;
    originalProductTags: string[];
    originalPrice: number;
    /** The amount paid during order time */
    originalDiscountedPrice: number;
    exchangeProductId: number;
    exchangeVariantId: number;
    exchangeVariantCurrentPrice: number;
    exchangeProductTags: string[];
  },
  globalConfig: Partial<GlobalConfig> | undefined,
) => {
  const shouldRespectPriceIncrease = shouldRespectPriceIncreaseOnExchange(
    globalConfig?.respectPriceIncreaseOnExchangeStrategy,
    {
      productId: rmaItem.original.product_id,
      variantId: rmaItem.original.variant_id,
      productTags: originalProductTags,
    },
    {
      productId: exchangeProductId,
      variantId: exchangeVariantId,
      productTags: exchangeProductTags,
    },
  );

  const originalRefundValue = shouldRespectPriceIncrease
    ? bound(exchangeVariantCurrentPrice, originalDiscountedPrice, originalVariantCurrentPrice)
    : originalDiscountedPrice;

  const originalPreDiscountValue = shouldRespectPriceIncrease
    ? bound(exchangeVariantCurrentPrice, originalPrice, originalVariantCurrentPrice)
    : originalPrice;

  // We store the values as whole cents to make sure we have no rounding or float division
  // issues in upcoming operations.
  if (exchangeVariantCurrentPrice < 0)
    throw Error(`Can not find a valid price for variant with ID '${exchangeVariantId}'.`);

  const lineItemAdjustmentProvider = globalConfig?.lineItemDiscountsProvider;

  const originalLineItem = currentOrderState.lineItems.find(
    (li) => li.productId === originalProductId && li.variantId === originalVariantId,
  );
  const exchangeLineItem = nextOrderState.lineItems.find(
    (li) => li.productId === exchangeProductId && li.variantId === exchangeVariantId,
  );

  if (!originalLineItem) {
    throw new Error("Original line item not found");
  }

  const provider =
    lineItemAdjustmentProvider && lineItemDiscountsProviders[lineItemAdjustmentProvider];

  const updatedDiscounts = provider && provider(originalLineItem, exchangeLineItem, nextOrderState);

  const exchangeDiscountedPrice = updatedDiscounts
    ? calculateLineItemPrice(
        exchangeVariantCurrentPrice,
        updatedDiscounts,
        lineItemAdjustmentProvider,
      )
    : exchangeVariantCurrentPrice;
  const originalExchangeValue = lineItemAdjustmentProvider
    ? originalDiscountedPrice
    : originalPreDiscountValue; // Old style of transferring discounts;

  const originalValue = bound(exchangeDiscountedPrice, originalRefundValue, originalExchangeValue);

  return {
    exchangeDiscountedPrice,
    originalExchangeValue,
    originalRefundValue,
    originalValue,
  };
};
