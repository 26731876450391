import { AppTexts } from '../../../../../../functions/src/shared';

export const de: AppTexts = {
  generic: {
    logo: 'Logo',
    signOut: 'Klicke hier, um alle Ihre Retourpräferenzen zu entfernen (starte von vorne).',
    goBack: 'Zurück',
    exchange: 'Umtausch',
    return: 'Retouren',
    returnAlreadySubmitted: 'Es scheint, dass Sie diese Rücksendung bereits eingereicht haben.',
    multipleRefundsPossible:
      'Sie beantragen eine Rückerstattung für eine Umtauschbestellung. Wenn Sie diese bearbeiten, erhalten Sie mehr als eine Rückerstattungsbenachrichtigung, da Ihr Geld (teilweise) in der ersten von Ihnen getätigten Bestellung enthalten ist.',
    submitAnotherReturn: 'Senden Sie eine weitere Rücksendung',
    free: 'Kostenlos',
    next: 'Weiter',
    error: {
      problemLoading: 'Beim Laden dieser Seite ist ein Problem aufgetreten',
      technicalProblem: 'Aufgrund eines technischen Problems kann die Seite nicht geladen werden.',
      comeBack: 'Komme zurück in wenigen MInuten und porbiere es erneut',
      contactSupport: 'Kontaktiere den Kundenservice',
      serverError: 'Serverfehler',
      networkError: 'Netzwerkfehler',
      timeout:
        'Der Server ist momentan sehr langsam. Dies kann auf eine schlechte Verbindung oder einem Serverfehler zurückzuführen sein. Bitte versuche es später noch einmal.',
      other:
        'Etwas ist schiefgelaufen. Versuche es später noch einmal oder kontaktieren Sie uns {{supportEmail}}',
    },
    form: {
      fieldRequired: 'Dieses Feld ist erforderlich',
      invalidEmail: 'Die e-mail Adresse ist ungültig',
    },
  },
  footer: {
    default: 'Brauchst du Hilfe bei der Retoure? Kontaktiere uns unter {{supportEmail}}',
    privacyPolicy: 'Datenschutz-Bestimmungen',
  },
  orderLookup: {
    title: 'Umtausch und Retouren',
    pageTitle: 'Starte einen Umtausch oder eine Retoure',
    orderNumber: 'Bestellnummer',
    postalcode: 'Postleitzahl',
    emailaddress: 'E-Mail-Addresse',
    any: 'E-Mail-Adresse oder Postleitzahl',
    submit: 'Starte',
    error: {
      notFound: 'Bestellung nicht gefunden. Überprüfe deine Eingaben und versuche es erneut.',
      currencyNotSupported:
        'This platform can only handle returns in {{supportedCurrency}}. Please contact {{supportEmail}} to handle your return.',
    },
  },
  itemsList: {
    title: 'Wähle ein Produkt zum Umtausch oder zur Rückgabe aus',
    pageTitle: 'Bestellnummer',
    ineligibleSubtitle: 'Nicht alle Artikel dieser Bestellung können zurückgegeben werden',
    returnableUntil:
      'Die Bestellung {{orderName}} kann zurückgesendet werden bis {{returnableUntilDate}}',
    noItemsEligible: 'Momentan können keine Produkte zurückgesendet werden.',
    noReturnCountry:
      'Derzeit können keine Produkte zurückgegeben werden, da wir keine Rückgaberichtlinie für Ihr Land haben. Bitte wenden Sie sich an den Support, um Ihre Rücksendung auszuführen.',
    returnWindowClosed:
      'Die Rückgabefrist ist abgelaufen. \n Produkte können nicht mehr zurückgegeben werden.',
    warrantyReturnPossible: 'Diese Produkte sind noch unter Garantie.',
    submitWarrantyRequest: 'Garantieanfrage einreichen.',
    returnAlreadySubmitted: 'Die Bestellung {{orderName}} wurde bereits zurückgegeben',
    productsWithTechnicalErrors:
      'Ein oder mehrere Produkte aus Ihrer Bestellung können aufgrund eines technischen Problems nicht zurückgegeben oder umgetauscht werden. Bitte teilen Sie uns diese mit, indem Sie uns unter {{supportEmail}} kontaktieren',
    lastUpdate: 'Letzte Aktualisierung',
    selectedItems: '{{count}} Produkt(e) ausgewählt',
    continueWithReturn: 'Weiter mit der Retoure',
    cannotContinueWithReturn:
      'Die ausgewählten Artikel überschreiten den Wert des verfügbaren Rücksendeguthabens. Bitte passen Sie Ihre Auswahl so an, dass der Wert der angeforderten Artikel größer oder gleich dem Wert der Artikel ist, die Sie zurückgeben/umtauschen möchten.',
    expectedArrivalDate: 'Voraussichtliches Ankunftsdatum',
    paymentPending: {
      title: 'Zahlung ausstehend',
      message:
        'Wir warten auf Ihre Zahlung. Bitte überprüfen Sie Ihre E-Mail oder kontaktieren Sie uns unter {{email}}',
    },
    adjustmentsInCalculation: 'Die Berechnung enthält Anpassungen',
  },
  shippingLabel: {
    isPaidLabel:
      'You will have to pay {{amount}} in order to receive your {{carrier}} return label.',
  },
  item: {
    exchangeInfoDiscount:
      'Der Umtauschwert für dieses Produkt beträgt {{exchangeValue}}.\nWenn Sie sich für die Rückgabe des Artikels entscheiden, werden {{refundValue}} erstattet.',
    exchangeInfoPriceIncrease: {
      always:
        'Aufgrund einer kürzlichen Preiserhöhung\nist der Umtauschwert für dieses Produkt {{exchangeValue}}.\nWenn Sie sich für die Rückgabe des Artikels entscheiden, werden {{refundValue}} erstattet.',
      'both-match':
        'Aufgrund einer kürzlichen Preiserhöhung\nist der Umtauschwert für dieses Produkt {{exchangeValue}} bei ausgewählten Produkten.\nWenn Sie sich für die Rückgabe des Artikels entscheiden, werden {{refundValue}} erstattet.',
      'same-product':
        'Aufgrund einer kürzlichen Preiserhöhung\nist der Umtauschwert für dieses Produkt {{exchangeValue}} bei Umtausch gegen eine andere Variante.\nWenn Sie sich für die Rückgabe des Artikels entscheiden, werden {{refundValue}} erstattet.',
      'same-variant':
        'Es gab eine kürzliche Preiserhöhung, aber bei einem Umtausch gegen genau dasselbe Produkt müssen Sie nichts bezahlen.\nWenn Sie sich für die Rückgabe des Artikels entscheiden, werden {{refundValue}} erstattet.',
      none: '',
    },
    adjustmentDecrease:
      'Aufgrund der Rückgabe eines anderen Produkts ist ein größerer Rabatt anwendbar.',
    adjustmentIncrease:
      'Aufgrund der Rückgabe eines anderen Produkts ist ein geringerer Rabatt anwendbar.',
  },
  tracking: {
    status: {
      droppedOff: 'Abgegeben',
      inTransit: 'Unterwegs',
      submitted: 'Angemeldet',
      delivered: 'Empfangen',
    },
  },
  itemWizard: {
    isCurrentVariant: 'Aktuelles',
    chooseVariant: 'Wähle eine Variante',
    outOfStock: 'Nicht vorrätig',
    reasonsInfoBottom: '',
    otherCollectionInfoTop: '',
    otherVariantInfoTop: '',
    reasonsWhyExchange: 'Warum möchtest du umtauschen?',
    reasonsWhyReturn: 'Warum möchtest du retournieren?',
    typeCommentHereAndCount: 'Schreibe deinen Kommentar hier (min. {{count}} Zeichen)...',
    typeCommentHere: 'Schreibe deinen Kommentar hier...',
    commentIsEmpty: 'Dieses Feld darf nicht leer sein.',
    commentIsTooShort: 'Der Kommentar muss mindestens {{count}} Zeichen haben.',
    submitComment: 'Kommentar abschicken',
    noProductFound: 'Es wurde kein verwandtes Produkt gefunden',
    from: 'Ab',
    howMakeItRight: 'Wie können wir es besser machen?',
    sendSameProduct: 'Sende mir das gleiche Produkt',
    showMoreOptions: 'Zeige mehr Optionen',
  },
  shipping: {
    returnMethod: 'Versandart',
    pleaseSelectMethod: 'Wie möchtest du das Produkt zurücksenden?',
  },
  review: {
    title: 'Überprüfe deine Angaben',
    pageTitle: 'Überprüfe deine Angaben',
    itemsToReceive: 'Zu erhaltendes Produkt',
    itemsToSendBack: 'Zu versendendes Produkt',
    shippingAddressTitle: 'Das Produkt(e) wird versandt an',
    deliveryOptionTitle: 'Wohin möchten Sie Ihren Austausch schicken?',
    deliveryOption: {
      billingAddress: 'Meine Heimatadresse (Rechnungsadresse) oder eine Adresse hinzufügen',
      pickupPoint: 'In vorheriger Bestellung gewählter Abholpunkt',
    },
    additionalRefundInfo:
      'Wir erstatten auf das Zahlungskonto (oder die Geschenkkarte), das für die ursprüngliche Transaktion verwendet wurde.',
    noItemsSelected:
      'Du hast noch keine Produkte ausgewählt. Gehe zurück und wähle mindestens ein Produkt zum Umtausch/ zur Rückgabe aus.',
    submitReturn: 'Retour bestätigen',
    submitReturnAndPay: 'Pay for return label and confirm return',
    returnShippingCosts: 'Versandkosten für die Rücksendung',
    removedDiscount: 'Zusätzliche Zahlung für {{itemTitle}}',
    extraDiscount: 'Zusätzlicher Rabatt für {{itemTitle}}',
    refund: {
      title: 'Übersicht',
      calculationPriceIncreasement: 'Anpassungen Preiserhöhungen',
      valueOfReturnedProducts: 'Wert zurückzugebender Produkte',
      valueOfExchangeProducts: 'Wert auszutauschender Produkte',
      appliedDiscountToOrder: 'Rabatt auf ursprüngliche Bestellung',
      totalRefundAmount: 'Gesamter Rückerstattungsbetrag',
      totalAdditionalPayment: 'Gesamtzuzahlung',
      additionalPaymentInfo:
        'Es bestehen ausstehende Zahlungen. Sobald Ihr Antrag genehmigt wurde, senden wir einen Link zur Zahlung des ausstehenden Betrags an: {{email}}',
      shippingCostRefundInfo:
        'Wenn Sie die Versandkosten bezahlt haben, erstatten wir Ihnen diese ebenfalls. Diese sind nicht Teil des oben genannten Gesamtrückerstattungsbetrags und werden hinzugefügt, wenn wir Ihre Bestellung zurückerstatten.',
    },
    budbee: {
      typePhoneNumberHere: 'Geben Sie hier Ihre Handynummer ein',
      hasBillingPhoneNumber:
        'Wir verwenden {{phoneNumber}} für Ihre Budbee-Abholung. Wenn Sie eine andere Telefonnummer verwenden möchten, geben Sie diese unten ein:',
      hasNoBillingPhoneNumber:
        'Wir haben keine Telefonnummer von Ihnen. Budbee benötigt diese, um Sie über die Abholung auf dem Laufenden zu halten. Bitte füllen Sie sie unten aus:',
      phoneNumberInvalid:
        'Ihre Telefonnummer ist ungültig, bitte geben Sie die Landesvorwahl und ein "+" an, z. "+49987654321".',
    },
    sendcloud: {
      header: 'Wir benötigen weitere Informationen!',
      explanation:
        'Um die richtigen Informationen an das Versandunternehmen weitergeben zu können, benötigen wir Ihre Straßenadresse und Hausnummer. Bitte füllen Sie diese unten aus, damit wir eine Rücksendung für Sie erstellen können.',
      streetNamePlaceholder: 'Straßenname',
      streetNumberPlaceholder: 'Hausnummer',
      streetNameInvalid: 'Kein gültiger Straßenname, bitte füllen Sie etwas aus.',
      streetNumberInvalid:
        "Keine gültige Hausnummer. Sie muss mit einer Zahl beginnen, z.B. '42B', und darf nicht länger als 20 Zeichen sein.",
    },
    comment: {
      infoExchange:
        'Möchtest du die obige Adresse ändern oder eine Anmerkung hinzufügen? Dann schreibe einen Kommentar hierzu.',
      infoRefund:
        'Wenn du eine zusätzliche Anmerkung anbringen möchtest, dann füge dieser Retour einen Kommentar hinzu.',
      addComment: 'Kommentar hinzufügen',
      clearComment: 'Kommentar löschen',
      addAddress: 'Adresse ändern',
      clearAddress: 'Adresse löschen',
      commentIsEmpty: 'Dieses Feld darf nicht leer sein.',
      commentIsTooShort: 'Der Kommentar muss mindestens {{count}} Zeichen haben.',
    },
    error: {
      notAccepted: 'Diese Bestellung wurde bereits für die Rückgabe erfasst. ',
    },
  },
  summary: {
    title: 'Deine Retourenübersicht',
    pageTitle: 'Retourübersicht',
    generatingLabel: 'Rücksendeetikettanforderung wird bearbeitet',
    submittingPickUp: 'Ihre Budbee-Abholanfrage wird übermittelt',
    thanksSubmittingBudbee:
      'Die voraussichtliche Abholzeit für Ihr Paket ist am {{day}} zwischen {{start}} und {{stop}}. Wenn Sie die Abholung verfolgen oder ändern möchten, verwenden Sie bitte die Budbee-App mit der Telefonnummer, die Sie mit dieser Rücksendung angegeben haben: {{phoneNumber}}.',
    labelReady: 'Deine Versandetikette ist bereit zum Drucken',
    paymentLinkReady: 'Drücken Sie die Schaltfläche unten, wenn Sie nicht weitergeleitet werden',
    thanksSubmitting: 'Vielen Dank für das Einreichen Ihrer Rücksendung',
    noLabel: 'Kein Versandetikette verfügbar. Bitte kontaktiere uns unter {{ supportEmail }}.',
    labelNotPaid:
      'Das Rücksendeetikett scheint nicht bezahlt worden zu sein. Bitte verwenden Sie die Schaltfläche unten, um den Zahlungsvorgang erneut zu starten. Falls Sie bereits bezahlt haben und dieser Fehler angezeigt wird, kontaktieren Sie uns bitte unter {{ supportEmail }}',
    downloadLabel: 'Lade Versandetikette herunter',
    redirectToPayment: 'Weiter zur Zahlung',
    restartPayment: 'Bezahlvorgang neu starten',
    mailWillBeSentTo: 'Versandetikette & Anweisungen werden auch per E-Mail geschickt an',
    mailWillBeSentToOnlyInstructions: 'Anweisungen werden auch per E-Mail geschickt an',
    mailWillBeSentToPaperless: 'Anweisungen werden per E-Mail geschickt an',
    mailWillBeSentToPaperlessSub:
      'Diese E-Mail enthält auch einen Barcode, den Sie bei der Abgabe Ihres Pakets benötigen.',
    howToTitle: 'Schritte für die Rückgabe deiner/s Produkte/s',
    itemsToSendBack: 'Zurückzugebendes Produkt(e)',
    itemsToReceive: 'Zu erhaltendes Produkt',
    estimatedProcessingTime:
      'Die Bearbeitung Ihrer Rücksendung dauert ca. {{count}} Werktag, nachdem Sie Ihr Paket abgegeben haben.',
    commercialInvoiceReady: 'Customs Invoice Ready',
    downloadCommercialInvoice: 'Download customs invoice',
    noCommercialInvoice:
      'It seems that something went wrong while generating the customs invoice.  Contact us at {{ supportEmail }} and we will send you the correct invoice to attach to your return.',
  },
  rma: {
    option: {
      genericExchange: 'Produkt umtauschen',
      genericReturn: 'Produkt zurückgeben',
    },
    warning: {
      alreadyReturned: 'Produkt wurde bereits zurückgegeben',
      rmaInProcess: 'Produkt wurde bereits angemeldet',
      unfulfilledLineItem: 'Produkt wurde noch nicht versandt',
      noReturnGiftCards: 'Geschenkkarten können nicht zurückgegeben werden',
      noPhysicalProduct: 'Digitale Produkte können nicht zurückgegeben werden',
      returnWindowExpired: 'Rückgabefrist abgelaufen',
      countryNotReturnable: 'Leider können wir diesen Artikel aus Ihrem Land nicht annehmen',
      notEligibleForReturn:
        'Dieses Produkt kann nicht zurückgegeben werden. Wenden Sie sich bei Fragen bitte an den Kundendienst',
    },
  },
  pageNotFound: {
    title: '404',
    body: 'Seite nicht gefunden',
  },
  shopNotFound: {
    title: '403',
    body: 'Zugriff nicht erlaubt',
  },
  noApp: {
    title: 'Browser wird nicht unterstützt',
    notPossible: 'Umtausch/Rückgabe ist leider nicht über Internet Explorer möglich.',
    useDifferentBrowser: 'Bitte benutzen einen anderen Browser',
    orVisitWithPhone: 'oder besuchen diese Seite über dein Handy',
  },
  delivery: {
    other: 'Versand an eine andere Adresse',
    otherAddress: 'Verwenden Sie das Kommentarfeld, um eine neue Adresse einzugeben',
  },
};
