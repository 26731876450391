import { AppTexts } from '../../../../../../functions/src/shared';

export const it: AppTexts = {
  generic: {
    logo: 'Logo',
    signOut: 'Fai clic qui per rimuovere tutte le preferenze di reso (ricomincia da capo).',
    goBack: 'Torna indietro',
    exchange: 'Cambia',
    return: 'Restituisci',
    returnAlreadySubmitted: 'Sembra che tu abbia già inviato questa richiesta di reso.',
    multipleRefundsPossible:
      'Stai richiedendo un rimborso per un ordine di sostituzione. In questo caso riceverai più di una notifica di rimborso perché i tuoi fondi si trovano (parzialmente) nel primo ordine che hai effettuato.',
    submitAnotherReturn: "Invia un'altra richiesta di reso",
    free: 'Gratis',
    next: 'Avanti',
    error: {
      problemLoading: "C'è un problema nel caricamento di questa pagina",
      technicalProblem: 'Un problema tecnico impedisce il corretto caricamento della pagina.',
      comeBack: 'Torna tra qualche minuto e riprova',
      contactSupport: "Contatta l'assistenza",
      serverError: 'Errore del server',
      networkError: 'Errore di rete',
      timeout:
        'Sembra che il server impieghi troppo tempo a rispondere. Questo può essere causato da una scarsa connettività o da un errore dei nostri server. Riprova più tardi.',
      other:
        "Sembra che qualcosa sia andato storto. Riprova più tardi o contattaci all'indirizzo e-mail {{supportEmail}}",
    },
    form: {
      fieldRequired: 'Questo campo è obbligatorio',
      invalidEmail: "L'indirizzo e-mail non è valido",
    },
  },
  footer: {
    default:
      "Ti serve aiuto per restituire i tuoi prodotti? Contattaci all'indirizzo {{supportEmail}}",
    privacyPolicy: 'Politica sulla riservatezza',
  },
  orderLookup: {
    title: 'Resi e cambi',
    pageTitle: 'Avvia una nuova restituzione o un nuovo cambio',
    orderNumber: "Numero d'ordine",
    postalcode: 'Codice postale',
    emailaddress: 'Indirizzo e-mail',
    any: 'Indirizzo e-mail o codice postale',
    submit: 'Inizia',
    error: {
      notFound: 'Ordine non trovato. Per favore, ricontrolla le informazioni inserite e riprova.',
      currencyNotSupported:
        'This platform can only handle returns in {{supportedCurrency}}. Please contact {{supportEmail}} to handle your return.',
    },
  },
  itemsList: {
    title: 'Scegli un prodotto da restituire o sostituire',
    pageTitle: 'Ordine',
    ineligibleSubtitle: 'Alcuni articoli di questo ordine non possono essere restituiti',
    returnableUntil: 'L’ordine {{orderName}} è restituibile fino al {{returnableUntilDate}}',
    noItemsEligible: 'In questo momento non è possibile restituire alcun prodotto.',
    noReturnCountry:
      "Nessun prodotto può essere restituito in questo momento, perché non abbiamo una politica di restituzione per il tuo paese. Si prega di contattare l'assistenza per completare il reso.",
    returnWindowClosed:
      'Il periodo di restituzione è scaduto. \n I prodotti non possono più essere restituiti.',
    warrantyReturnPossible: 'Questi prodotti sono ancora in garanzia.',
    submitWarrantyRequest: 'Invia una richiesta di garanzia.',
    returnAlreadySubmitted: 'Una richiesta di reso per l’ordine {{orderName}} è stata già inviata.',
    productsWithTechnicalErrors:
      "Uno o più prodotti del tuo ordine non possono essere restituiti o sostituiti a causa di un problema tecnico. Ti preghiamo di comunicarcelo contattandoci all'indirizzo e-mail {{supportEmail}}",
    lastUpdate: 'Ultimo aggiornamento',
    selectedItems: '{{count}} prodotto/i selezionati',
    continueWithReturn: 'Continua con il reso',
    cannotContinueWithReturn:
      'Gli articoli selezionati superano il valore del credito di reso disponibile. Si prega di modificare la selezione in modo che il valore degli articoli richiesti sia maggiore o uguale al valore degli articoli che si desidera restituire/scambiare.',
    expectedArrivalDate: 'Data di arrivo prevista',
    paymentPending: {
      title: 'In attesa di pagamento',
      message:
        "Siamo in attesa del tuo pagamento. Si prega di controllare la propria e-mail o di contattarci all'indirizzo {{email}}",
    },
    adjustmentsInCalculation: 'Il calcolo include costi aggiuntivi',
  },
  shippingLabel: {
    isPaidLabel:
      'You will have to pay {{amount}} in order to receive your {{carrier}} return label.',
  },
  item: {
    exchangeInfoDiscount:
      "Il valore di restituzione per questo prodotto è {{exchangeValue}}.\nQuando si sceglie di restituire l'articolo, ti verranno rimborsati {{refundValue}}.",
    exchangeInfoPriceIncrease: {
      always:
        "A causa di un recente aumento dei prezzi\nil valore di cambio per questo prodotto è {{exchangeValue}}.\nQuando si sceglie di restituire l'articolo {{refundValue}} viene rimborsato.",
      'both-match':
        "A causa di un recente aumento dei prezzi\nil valore di cambio per questo prodotto è {{exchangeValue}} per i prodotti selezionati.\nQuando si sceglie di restituire l'articolo {{refundValue}} viene rimborsato.",
      'same-product':
        "A causa di un recente aumento dei prezzi\nil valore di cambio per questo prodotto è {{exchangeValue}} quando si scambia per un'altra variante.\nQuando si sceglie di restituire l'articolo {{refundValue}} viene rimborsato.",
      'same-variant':
        "A causa di un recente aumento dei prezzi\nil valore di cambio per questo prodotto è {{exchangeValue}} quando si scambia per lo stesso prodotto.\nQuando si sceglie di restituire l'articolo {{refundValue}} viene rimborsato.",
      none: '',
    },
    adjustmentDecrease:
      'A causa della restituzione di un altro prodotto, è applicabile uno sconto maggiore.',
    adjustmentIncrease:
      'A causa della restituzione di un altro prodotto, è applicabile uno sconto minore.',
  },
  tracking: {
    status: {
      droppedOff: 'consegnato al centro di spedizione',
      inTransit: 'in transito',
      submitted: 'inviato',
      delivered: 'consegnato',
    },
  },
  itemWizard: {
    isCurrentVariant: 'Attuale',
    chooseVariant: 'Scegli una variante',
    outOfStock: 'Esaurito',
    reasonsInfoBottom: '',
    otherCollectionInfoTop: '',
    otherVariantInfoTop: '',
    reasonsWhyExchange: 'Perché stai richiedendo una sostituzione?',
    reasonsWhyReturn: 'Perché stai richiedendo una restituzione?',
    typeCommentHereAndCount: 'Scrivi qui il tuo commento (min. {{count}} characters)...',
    typeCommentHere: 'Scrivi qui il tuo commento...',
    commentIsEmpty: 'Il campo dei commenti non può essere vuoto.',
    commentIsTooShort: 'Il commento deve contenere almeno {{count}} caratteri.',
    submitComment: 'Invia commento',
    noProductFound: 'Non è stato trovato alcun prodotto correlato',
    from: 'Da',
    howMakeItRight: 'Come possiamo risolvere il problema?',
    sendSameProduct: 'Inviami lo stesso prodotto',
    showMoreOptions: 'Mostra altre opzioni',
  },
  shipping: {
    returnMethod: 'Metodo di restituzione',
    pleaseSelectMethod: 'Seleziona un metodo di restituzione',
  },
  review: {
    title: 'Rivedi il tuo reso',
    pageTitle: 'Rivedi il tuo reso',
    itemsToReceive: 'Prodotto/i che riceverai',
    itemsToSendBack: 'Prodotto/i da restituire',
    shippingAddressTitle: "L'ordine di sostituzione sarà inviato a",
    deliveryOptionTitle: 'Dove dobbiamo spedire il tuo cambio?',
    returnShippingCosts: 'Costi di spedizione per la restituzione',
    deliveryOption: {
      billingAddress:
        'Al mio indirizzo di casa (indirizzo di fatturazione) oppure a un altro indirizzo',
      pickupPoint: 'Al punto di prelievo scelto nell’ordine precedente',
    },
    noItemsSelected:
      'Non hai ancora selezionato alcun prodotto. Torna indietro e seleziona i prodotti che vuoi restituire o cambiare.',
    submitReturn: 'Invia richiesta di reso',
    submitReturnAndPay: 'Pay for return label and confirm return',
    removedDiscount: 'Pagamento aggiuntivo per {{itemTitle}}',
    extraDiscount: 'Sconto extra per {{itemTitle}}',
    refund: {
      title: 'Riassunto',
      calculationPriceIncreasement: 'Adeguamenti aumenti dei prezzi',
      valueOfReturnedProducts: 'Valore dei prodotti restituiti',
      valueOfExchangeProducts: 'Valore dei prodotti restituiti',
      appliedDiscountToOrder: "Sconto sull'ordine originale",
      totalRefundAmount: 'Importo totale del rimborso',
      totalAdditionalPayment: 'Totale pagamento aggiuntivo',
      additionalPaymentInfo:
        "Ci sono pagamenti in sospeso. Una volta approvata la richiesta, invieremo un link per il pagamento dell'importo in sospeso all’indirizzo e-mail: {{email}}",
      shippingCostRefundInfo:
        "Se hai pagato le spese di spedizione, ti rimborseremo anche queste. Queste non fanno parte dell'importo totale del rimborso di cui sopra e saranno aggiunte al momento del rimborso dell'ordine.",
    },
    budbee: {
      typePhoneNumberHere: 'Digita qui il tuo numero di cellulare',
      hasBillingPhoneNumber:
        'Utilizzeremo {{phoneNumber}} per il tuo ritiro Budbee. Se desideri utilizzare un altro numero di telefono, compilalo di seguito:',
      hasNoBillingPhoneNumber:
        'Non abbiamo un tuo numero di telefono. Budbee ha bisogno di questo per aggiornarti sul ritiro. Per favore compilalo di seguito:',
      phoneNumberInvalid:
        'Il tuo numero di telefono non è valido, includi il prefisso internazionale e un "+", ad es. "+39987654321".',
    },
    sendcloud: {
      header: 'Abbiamo bisogno di ulteriori informazioni!',
      explanation:
        "Per fornire le informazioni corrette all'azienda di spedizioni, abbiamo bisogno del tuo indirizzo e numero civico. Per favore, compilali qui sotto in modo da poter creare una restituzione per te.",
      streetNamePlaceholder: 'Nome della via',
      streetNumberPlaceholder: 'Numero civico',
      streetNameInvalid: 'Nome della via non valido, per favore compila qualcosa.',
      streetNumberInvalid:
        "Numero civico non valido. Dovrebbe iniziare con un numero, ad es. '42B' e la sua lunghezza non può superare i 20 caratteri.",
    },
    comment: {
      infoExchange:
        "Se vuoi cambiare l'indirizzo, inserisci un nuovo indirizzo nel campo dei commenti qui sotto.",
      infoRefund:
        'Se si desidera aggiungere ulteriori osservazioni, si prega di aggiungere un commento a questa restituzione..',
      addComment: 'Aggiungi commento',
      clearComment: 'Cancella commento',
      addAddress: 'Aggiungi nuovo indirizzo',
      clearAddress: 'Cancella nuovo indirizzo',
      commentIsEmpty: 'Il campo dei commenti non può essere vuoto.',
      commentIsTooShort: 'Il commento deve avere almeno {{count}} caratteri.',
    },
    error: {
      notAccepted: 'Questo reso è già stato richiesto.',
    },
    additionalRefundInfo:
      'Il rimborso verrà effettuato sul conto di pagamento (o sulla carta regalo) utilizzato per la transazione originale..',
  },
  summary: {
    title: 'Riepilogo del tuo reso',
    pageTitle: 'Riepilogo del tuo reso',
    generatingLabel: 'Elaborazione richiesta etichetta di reso',
    submittingPickUp: 'La tua richiesta di ritiro Budbee è in fase di invio',
    thanksSubmittingBudbee:
      "Il tempo di ritiro stimato per il tuo pacco è il {{day}} tra {{start}} e {{stop}}. Se desideri monitorare o modificare il ritiro, utilizza l'app Budbee con il numero di telefono che hai inviato con questo reso: {{phoneNumber}}.",
    labelReady: "L'etichetta è pronta per essere stampata",
    paymentLinkReady: 'Premi il pulsante in basso quando non sei reindirizzato',
    thanksSubmitting: 'Grazie per aver inviato il tuo reso',
    noLabel:
      "Sembra che qualcosa sia andato storto durante l'ottenimento dell'etichetta. Contattaci all'indirizzo {{ supportEmail }} e ti invieremo l'etichetta corretta in pochissimo tempo.",
    labelNotPaid:
      "Sembra che l'etichetta di reso non sia stata pagata. Si prega di utilizzare il pulsante in basso per riavviare il processo di pagamento. Se hai già pagato e visualizzi questo errore, contattaci all'indirizzo {{ supportEmail }}",
    downloadLabel: "Scarica l'etichetta di spedizione",
    redirectToPayment: 'Procedi al pagamento',
    restartPayment: 'Riavvia il processo di pagamento',
    mailWillBeSentTo:
      "L'etichetta e le istruzioni per la spedizione vengono inviate via e-mail anche a",
    mailWillBeSentToOnlyInstructions: 'Le istruzioni vengono inviate anche via e-mail a',
    mailWillBeSentToPaperless: 'Le istruzioni vengono inviate via e-mail a',
    mailWillBeSentToPaperlessSub:
      'Questa e-mail contiene anche un codice a barre che ti servirà per consegnare il tuo pacco.',
    howToTitle: 'Come restituire gli articoli',
    itemsToSendBack: 'Prodotto/i da restituire',
    itemsToReceive: 'Prodotto/i che riceverai',
    estimatedProcessingTime:
      'Ci vogliono circa {{count}} giorni lavorativi per elaborare il tuo reso dopo aver consegnato il tuo pacco.',
    commercialInvoiceReady: 'Customs Invoice Ready',
    downloadCommercialInvoice: 'Download customs invoice',
    noCommercialInvoice:
      'It seems that something went wrong while generating the customs invoice.  Contact us at {{ supportEmail }} and we will send you the correct invoice to attach to your return.',
  },
  rma: {
    option: {
      genericExchange: 'Sostituisci con un altro prodotto',
      genericReturn: 'Restituisci prodotto',
    },
    warning: {
      alreadyReturned: 'Il prodotto è già stato restituito',
      rmaInProcess: 'Il prodotto è già stato inviato',
      unfulfilledLineItem: 'Prodotto non ricevuto',
      noReturnGiftCards: 'Le carte regalo non sono idonee per i resi',
      noPhysicalProduct: 'I prodotti digitali non sono idonei per la restituzione',
      returnWindowExpired: 'Il periodo per il reso è scaduto',
      countryNotReturnable: 'Purtroppo non possiamo accettare questo articolo dal tuo paese',
      notEligibleForReturn:
        'Questo prodotto non può essere restituito, per domande si prega di contattare il servizio clienti',
    },
  },
  pageNotFound: {
    title: '404',
    body: 'Pagina non trovata',
  },
  shopNotFound: {
    title: '403',
    body: 'Accesso non consentito',
  },
  noApp: {
    title: 'Browser non supportato',
    notPossible: 'Il cambio/ritorno non è purtroppo possibile con Internet Explorer.',
    useDifferentBrowser: 'Si prega di utilizzare un altro browser',
    orVisitWithPhone: 'o visita questa pagina con il tuo smartphone',
  },
  delivery: {
    other: 'Invia a un altro indirizzo',
    otherAddress: 'Utilizza il campo dei commenti per inserire un nuovo indirizzo',
  },
};
