import { TInventoryLevel } from '../../../../functions/src/shared';

export const getInventoryQuantityByLocationId = (
  locationIds: number[],
  inventoryByLocation: Record<number, TInventoryLevel>,
) => {
  return Object.keys(inventoryByLocation).reduce((sum: number, key: string) => {
    if (locationIds.includes(parseInt(key))) {
      return sum + (inventoryByLocation[parseInt(key)].available || 0);
    }

    return sum;
  }, 0);
};
