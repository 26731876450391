import React, { Fragment, useEffect } from 'react';
import { useAppTexts } from '../../hooks/useAppTexts';
import { useSessionData } from '../../firebase/hooks';

export const ReviewDelivery = ({ selectedDeliveryOption, handleChooseDelivery }) => {
  const { deliveryOptions } = useSessionData();
  const options = deliveryOptions.options || [];

  const appTexts = useAppTexts();

  const selectedDeliveryAddress =
    options.find((option) => option.type === (selectedDeliveryOption || 'pickup_point')) ||
    options[0];

  // defaults to pickup point
  useEffect(() => {
    if (selectedDeliveryOption === undefined) {
      handleChooseDelivery('pickup_point');
    }
  }, [selectedDeliveryOption, handleChooseDelivery]);

  const actionMarkup =
    options.length &&
    options.map((option) => {
      return (
        <div className="card item-card is-delivery-card" key={option.type}>
          <div className="control">
            <label className="radio">
              <input
                className="block radio"
                type="radio"
                key={option.type}
                id={option.type}
                onChange={() => handleChooseDelivery(option.type)}
                checked={selectedDeliveryAddress?.type === option.type}
              />
              <span>
                {
                  appTexts.review.deliveryOption[
                    option.type.replace(/([-_]\w)/g, (g) => g[1].toUpperCase())
                  ]
                }
              </span>
            </label>
          </div>
        </div>
      );
    });

  const addressMarkup = (
    <div className="review-block">
      <h2 className="review-block-title">{appTexts.review.shippingAddressTitle}</h2>
      <div className="review-address">
        <div className="review-address-block">
          <p>{selectedDeliveryAddress?.address}</p>
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className="review-block">
        <h2 className="review-block-title">{appTexts.review.deliveryOptionTitle}</h2>
        {/* <Notification hide={isCommentFieldOpen}>
          <p>{infoText}</p>
        </Notification> */}

        {/* {commentFieldMarkup} */}
        {actionMarkup}
      </div>
      {addressMarkup}
    </Fragment>
  );
};
