import { useMemo } from 'react';
import {
  BaseWizardData,
  mapWizardActionToPayloadAction,
} from '../contexts/WizardContext/WizardContext';
import { LoadedSessionData } from '../firebase/firebase';
import {
  AppliedAdjustmentsData,
  BaseRmaRequest,
  TRMAItem,
  calculateAdjustments,
  createRmaItem,
} from '../../../../functions/src/shared';

export const useAdjustments = (
  wizard: BaseWizardData,
  sessionData: LoadedSessionData | undefined,
): AppliedAdjustmentsData => {
  return useMemo(() => {
    if (!sessionData) {
      return {
        adjustments: [],
        totalAdjustments: 0,
      };
    }

    const draftRma: BaseRmaRequest = {
      rmaName: 'draft',
      rmaItems: Object.values(wizard.collectedWizardData)
        .filter((item): item is NonNullable<typeof item> => Boolean(item))
        .map(
          (item): TRMAItem =>
            createRmaItem(mapWizardActionToPayloadAction(item), sessionData.config),
        ),
    };

    return calculateAdjustments(
      sessionData.order,
      draftRma,
      sessionData.config.adjustmentsV2 ?? [],
    );
  }, [sessionData, wizard]);
};
