import React, { useEffect, useMemo } from 'react';
import app from 'firebase';

import { Notification } from '../../Notification';
import { TrackingTimeline } from '../../TrackingTimeline/TrackingTimeline';
import moment, { Moment } from 'moment';
import { getEstimatedProcessingTime } from '../../../helpers/getEstimatedProcessingTime';
import { addBusinessDays } from '../../../helpers/addBusinessDays';
import { localeMapping } from '../../../contexts/AppTextsContext/localeMapping';
import {
  BaseRmaRequest,
  TrackingCheckpoint,
  TrackingStatus,
} from '../../../../../../functions/src/shared';
import { interpolateAppText, useAppTexts } from '../../../hooks/useAppTexts';
import { useLoadedFirebaseData } from '../../../firebase/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCommercialInvoice } from '../../../hooks/useCommercialInvoice';
import { useShippingLabel } from '../../../hooks/useShippingLabel';
import { useReturnOption } from '../../../hooks/useReturnOption';

interface IProps {
  rmaRequest: BaseRmaRequest<app.firestore.Timestamp>;
}

const RmaStatusSection = ({ rmaRequest }: IProps) => {
  const firebase = useLoadedFirebaseData();
  const appTexts = useAppTexts();
  const { publicConfig: appThemeData, config } = firebase;
  const { supportEmail } = appThemeData;
  const { tracking, rmaIsPaymentPending, rmaIsCompleted, rmaName, orderShippingCountryCode } =
    rmaRequest;

  const trackingLastUpdate = tracking ? moment(tracking.lastUpdate.toDate()) : null;
  const trackingLastUpdateFormatted = useFormattedDate(trackingLastUpdate, appThemeData.language);
  const trackingStatus = tracking ? tracking.status : null;

  const expectedProcessingTime = getEstimatedProcessingTime(firebase);
  const checkpointsSorted = useCheckpointsSorted(tracking?.checkpoints);
  const expectedArrivalDate = useExpectedArrivalMoment(checkpointsSorted, expectedProcessingTime);
  const expectedArrivalDateFormatted = useFormattedDate(expectedArrivalDate, appThemeData.language);

  const {
    fetchCommercialInvoiceUrl,
    commercialInvoiceUrl,
    commercialInvoiceError,
    isCommercialInvoiceRequired,
    isFetchingCommercialInvoice,
  } = useCommercialInvoice(rmaName);

  const {
    paperless,
    isFetchingLabel,
    labelError,
    fetchShippingLabel,
    shippingLabelUrl,
    checkoutUrl,
  } = useShippingLabel(rmaName);

  const { returnOption } = useReturnOption(rmaName);

  useEffect(() => {
    if (!commercialInvoiceUrl) fetchCommercialInvoiceUrl();
  }, [fetchCommercialInvoiceUrl, commercialInvoiceUrl]);

  useEffect(() => {
    if (
      returnOption &&
      returnOption.labelProvider !== 'budbee' &&
      !paperless && // this does not provide an url, so we need to check this explicit
      !shippingLabelUrl
    )
      fetchShippingLabel();
  }, [fetchShippingLabel, returnOption, shippingLabelUrl, paperless]);

  const handleDownloadCommercialInvoice = () => {
    if (!commercialInvoiceUrl) return;

    const anchor = window.document.createElement('a');
    anchor.href = commercialInvoiceUrl;
    anchor.target = '_blank';
    anchor.click();
  };
  const { provideCommercialInvoice = false } = config.countrySettings?.['default'] || {};

  const showCommercialInvoice =
    provideCommercialInvoice && isCommercialInvoiceRequired && !isFetchingCommercialInvoice;

  const canDownloadCommercialInvoice = commercialInvoiceUrl && !commercialInvoiceError;

  const commercialInvoice = (
    <>
      {showCommercialInvoice && (
        <>
          {canDownloadCommercialInvoice && (
            <div style={{ marginTop: '2rem' }}>
              <button
                className="button is-primary custom-button has-icon"
                onClick={handleDownloadCommercialInvoice}
              >
                <span>{appTexts.summary.downloadCommercialInvoice}</span>
                <FontAwesomeIcon icon="download" />
              </button>
            </div>
          )}
        </>
      )}
    </>
  );

  const handleDownloadLabel = () => {
    if (!shippingLabelUrl) return;

    const anchor = window.document.createElement('a');
    anchor.href = shippingLabelUrl;
    anchor.target = '_blank';
    anchor.click();
  };

  const canDownloadShippingLabel = shippingLabelUrl && !checkoutUrl && !labelError;
  const shippingLabel = (
    <>
      {canDownloadShippingLabel && (
        <>
          <button
            className="button is-primary custom-button has-icon"
            onClick={handleDownloadLabel}
          >
            <span>{appTexts.summary.downloadLabel}</span>
            <FontAwesomeIcon icon="download" />
          </button>
        </>
      )}
    </>
  );

  const errorNoShippingLabel = (
    <>
      <h2 className="summary-action-bar-title">
        <Notification size="small" status="warning">
          {interpolateAppText(appTexts.summary.noLabel, {
            supportEmail: supportEmail,
          })}
        </Notification>
      </h2>
    </>
  );

  const errorNoCommercialInvoiceLabel = (
    <>
      <h2 className="summary-action-bar-title">
        <Notification size="small" status="warning">
          {interpolateAppText(appTexts.summary.noCommercialInvoice, {
            supportEmail: supportEmail,
          })}
        </Notification>
      </h2>
    </>
  );

  return (
    <div>
      <Notification
        status="info"
        message={interpolateAppText(appTexts.itemsList.returnAlreadySubmitted, {
          orderName: rmaName,
        })}
      >
        <br />

        <TrackingTimeline
          rmaIsCompleted={rmaIsCompleted}
          currentStatus={trackingStatus}
          hasAftershipTracking={Boolean(tracking)}
        />

        {tracking && (
          <>
            <br />
            {expectedArrivalDateFormatted && (
              <p>
                <span>{appTexts.itemsList.expectedArrivalDate}: </span>
                <span className="rma-info-data">{expectedArrivalDateFormatted}</span>
              </p>
            )}
            <p>
              <span>{appTexts.itemsList.lastUpdate}: </span>
              <span className="rma-info-data">{trackingLastUpdateFormatted}</span>
            </p>
          </>
        )}
      </Notification>

      <>
        {!checkoutUrl &&
          returnOption &&
          returnOption.labelProvider !== 'budbee' &&
          (isFetchingLabel ||
          (provideCommercialInvoice &&
            isCommercialInvoiceRequired &&
            isFetchingCommercialInvoice) ? (
            <>
              <h2 className="summary-action-bar-title">{appTexts.summary.generatingLabel}</h2>
              <progress className="progress is-small is-primary" max="100"></progress>
            </>
          ) : (
            !paperless && (
              <>
                <div className="download-label-invoice">
                  {shippingLabel}
                  {commercialInvoice}
                </div>
                {!canDownloadShippingLabel && errorNoShippingLabel}
                {!canDownloadCommercialInvoice &&
                  orderShippingCountryCode &&
                  (orderShippingCountryCode === 'GB' || orderShippingCountryCode === 'US') &&
                  errorNoCommercialInvoiceLabel}
              </>
            )
          ))}
      </>

      {rmaIsPaymentPending && (
        <Notification
          status="warning"
          message={
            <>
              <p className="has-text-weight-semibold">{appTexts.itemsList.paymentPending.title}</p>
              <p>
                {interpolateAppText(appTexts.itemsList.paymentPending.message, {
                  email: appThemeData.supportEmail,
                })}
              </p>
            </>
          }
        />
      )}
    </div>
  );
};

/**
 * Gets the checkpoints of the last webhook call sorted ascending by timestamp
 */
const useCheckpointsSorted = (checkpoints: TrackingCheckpoint[] | undefined) => {
  return useMemo(() => {
    if (!checkpoints) {
      return null;
    }

    const checkpointsSorted = [...checkpoints].sort((checkpoint1, checkpoint2) => {
      const date1 = new Date(checkpoint1.checkpoint_time);
      const date2 = new Date(checkpoint2.checkpoint_time);

      return date1.getTime() - date2.getTime();
    });

    return checkpointsSorted;
  }, [checkpoints]);
};

const useExpectedArrivalMoment = (
  checkpointsSorted: TrackingCheckpoint[] | null,
  expectedProcessingTimeBusinessDays?: number,
) => {
  return useMemo(() => {
    if (!checkpointsSorted || expectedProcessingTimeBusinessDays === undefined) {
      return null;
    }

    const firstCheckpointDroppedOff = checkpointsSorted.find((checkpoint) =>
      [TrackingStatus.DroppedOff, TrackingStatus.InTransit].includes(checkpoint.tag),
    );

    if (firstCheckpointDroppedOff === undefined) {
      return null;
    }

    const checkpointDate = new Date(firstCheckpointDroppedOff.checkpoint_time);
    const expectedProcessedDate = addBusinessDays(
      checkpointDate,
      expectedProcessingTimeBusinessDays,
    );

    return moment(expectedProcessedDate);
  }, [checkpointsSorted, expectedProcessingTimeBusinessDays]);
};

const useFormattedDate = (momentOrNull: Moment | null, locale: string) => {
  if (momentOrNull === null) {
    return null;
  }

  return momentOrNull.locale(localeMapping(locale)).format('LL');
};

export default RmaStatusSection;
