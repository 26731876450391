import { useMemo } from 'react';
import {
  AppTexts,
  LineItemFilter,
  SfyLineItem,
  SfyOrderType,
  TProductData,
  calculateIfReturnWindowIsOpen,
  TVariantData,
  deriveLineItemProperties,
  evaluateCondition,
  getMaxDateMillis,
} from '../../../../functions/src/shared';
import { FullWizardItem } from '../contexts/WizardContext/WizardContext';
import { LoadedSessionData, OrderData, RmaLineItem } from '../firebase/firebase';
import { getCurrencyFormat } from '../helpers/getCurrencyFormat';
import moment from '../helpers/localizedMoment';
import { useAppTexts } from './useAppTexts';
import { useLoadedFirebaseData } from '../firebase/hooks';

/** @deprecated The OrderData type is deprecated. This hook helps recreate the type from the SfyOrderType */
export const useOrderData = (): {
  order: OrderData;
} => {
  const firebase = useLoadedFirebaseData();

  const deprecatedOrderFormat = useMemo(() => toDeprecatedFormat(firebase), [firebase]);

  return {
    order: deprecatedOrderFormat,
  };
};

const toDeprecatedFormat = (sessionData: LoadedSessionData) => {
  const { order, rmaSummary, returnOptions, config } = sessionData;

  if (!order) {
    throw new Error('Order data is not available');
  }

  const isReturnableCountry = returnOptions !== null;
  const maximumAgeInDays = config?.maximumAgeInDays ?? Number.MAX_SAFE_INTEGER;

  const deprecatedOrderFormat: OrderData = {
    createdAt: order.createdAt,
    currency: order.currency,
    lineItems: order.lineItems,
    lineItemsRmaData: rmaSummary?.lineItems ?? [],
    orderId: order.id,
    orderName: order.name,
    returnableUntil:
      maximumAgeInDays === Number.MAX_SAFE_INTEGER
        ? null
        : moment(getMaxDateMillis(order, maximumAgeInDays)).format('DD/MM/YYYY'),
    isReturnable: calculateIfReturnWindowIsOpen(order, config),
    isReturnableCountry,
    rmaSubmitted: rmaSummary?.rmaSubmitted === true,
    email: order.email,
    countryCode: order.shippingAddress.countryCode,
    fulfillments: order.fulfillments,
  };

  return deprecatedOrderFormat;
};

export const useEnrichedLineItemData = () => {
  const {
    products,
    order,
    discounts,
    rmaSummary,
    publicConfig: { language },
    config,
  } = useLoadedFirebaseData();
  const appTexts = useAppTexts();

  return useMemo<{
    lineItems: FullWizardItem[];
    allItemsAlreadyReturned: boolean;
  }>(() => {
    if (!products || !order) {
      return {
        lineItems: [],
        allItemsAlreadyReturned: false,
      };
    }

    // we need to count already returned items as a workaround for returns without summary records (submitted manually)
    let nrOfAlreadyReturnedItems = 0;

    const lineItems = order.lineItems
      .map((lineItem) => {
        const currentProduct = products.filter((product) => product?.id === lineItem.productId)[0];

        const variants = currentProduct.variants;
        let currentVariant = variants.find(
          (variant) => variant.id === lineItem.variantId,
        ) as TVariantData;

        if (!currentVariant && lineItem.variantId) {
          currentVariant = {
            id: lineItem.variantId,
            image: '',
            title: lineItem.variantTitle,
            requires_shipping: lineItem.requiresShipping,
            gift_card: lineItem.giftCard,
            price: lineItem.price,
            inventory_policy: 'deny',
            sku: lineItem.sku,
            weight_grams: lineItem.grams,
            inventory_quantity: 0,
            options: {},
          };
        }

        const originalPrice = lineItem.price;
        const currentVariantPrice = parseFloat(currentVariant.price);

        const priceBand: [number, number] = [
          parseFloat(originalPrice),
          Math.max(parseFloat(originalPrice), currentVariantPrice),
        ];
        let discountedPrice: null | number = null;
        let discountPercent = 0;
        let discountPercentAll = 0;

        // loop through line item quantity and determine which items are returnable
        let perQuantity: FullWizardItem[] = [];

        const lineItemRmaData = rmaSummary?.lineItems.find(
          ({ lineItemId }) => lineItemId === lineItem.id,
        );

        for (let i = 0; i < lineItem.quantity; i++) {
          // set warning messages if item is not returnable
          let warning = getWarning(
            lineItem,
            lineItemRmaData,
            i,
            currentProduct,
            currentVariant,
            appTexts,
            config?.lineItemsBlocking,
          );

          if (warning?.warningCode === 'alreadyReturned') {
            nrOfAlreadyReturnedItems += 1;
          }

          if (discounts?.discountsPerItem && discounts.discountsPerItem.length > 0) {
            const foundDiscountLine = discounts.discountsPerItem.find(
              (item) => item.lineItemId === lineItem.id,
            );

            if (foundDiscountLine) {
              discountedPrice = foundDiscountLine.discountedPrice;
              discountPercent = foundDiscountLine.discountPercent;
              discountPercentAll = foundDiscountLine.discountPercentAll;
            }
          }

          perQuantity.push({
            lineItemId: lineItem.id,
            product: currentProduct,
            variant: currentVariant,
            selectionId: lineItem.id + '-' + i, // adding the quantity index to line item id to make it unique for selection and wizard collection purposes
            discountPercent,
            discountPercentAll,
            originalPrice: Number(originalPrice),
            displayOriginalPrice: getCurrencyFormat(
              originalPrice,
              order.currency,
              language,
            ) as string,
            discountedPrice,
            displayDiscountedPrice: getCurrencyFormat(discountedPrice, order.currency, language),
            warning: warning && warning.warningMessage,
            currentVariantPrice,
            displayCurrentVariantPrice: getCurrencyFormat(
              currentVariantPrice,
              order.currency,
              language,
            ),
            priceBand,
          });
        }

        return perQuantity;

        // end by flatting the array
        // we have to use an alternative to .flat() because of IE support
        // using reduce + concat as solution for IE/Edge
      })
      .filter((items): items is FullWizardItem[] => {
        const firstItem = items?.[0];

        return Boolean(
          firstItem &&
            hideLineItem('product' in firstItem ? firstItem.product.tags : undefined) === false,
        );
      })
      .reduce<FullWizardItem[]>((accumulator, itemsArr) => accumulator.concat(itemsArr), []);

    return {
      lineItems,
      allItemsAlreadyReturned: lineItems?.length === nrOfAlreadyReturnedItems,
    };
  }, [products, order, language, discounts, appTexts, rmaSummary, config?.lineItemsBlocking]);
};

const hideLineItem = (tags: string[] | undefined = []) =>
  tags.findIndex((tag) => {
    const lowercase = tag.toLowerCase();
    return lowercase.includes('rma') && lowercase.includes('hide') && lowercase.includes('product');
  }) > -1;

const getWarningCode = (
  lineItem: SfyLineItem,
  rmaLineItem: RmaLineItem | undefined,
  quantityIndex: number,
  product: TProductData,
  variant: TVariantData,
) => {
  switch (true) {
    case rmaLineItem &&
      rmaLineItem?.refundedAt?.length > 0 &&
      rmaLineItem?.refundedAt[quantityIndex] !== null:
      return 'alreadyReturned';
    case rmaLineItem &&
      rmaLineItem?.rmaSubmittedAt?.length > 0 &&
      rmaLineItem?.rmaSubmittedAt[quantityIndex] !== null:
      return 'rmaInProcess';
    case Boolean(!rmaLineItem?.fulfilledAt[quantityIndex]):
      return 'unfulfilledLineItem';
    case product.tags.includes('RMA:exclude'):
      return 'notEligibleForReturn';
    case variant.gift_card === true && variant.requires_shipping === false:
      return 'noReturnGiftCards';
    case variant.gift_card === false && variant.requires_shipping === false:
      return 'noPhysicalProduct';
    case lineItem.productId === null || lineItem.variantId === null:
      return 'unknownProduct';
    default:
      return null;
  }
};

const getWarning = (
  lineItem: SfyLineItem,
  lineItemRmaInfo: RmaLineItem | undefined,
  quantityIndex: number,
  product: TProductData,
  variant: TVariantData,
  appTexts: AppTexts,
  filter: LineItemFilter[] | undefined,
): {
  warningMessage: string;
  warningCode?: string;
} | null => {
  const warningCode = getWarningCode(lineItem, lineItemRmaInfo, quantityIndex, product, variant);

  if (warningCode) {
    return {
      warningMessage: appTexts.rma.warning[warningCode],
      warningCode,
    };
  }
  if (!filter) return null;

  const lineItemProperties = deriveLineItemProperties(lineItem, product);

  const filterMatch = filter.find((filter) => {
    return evaluateCondition(filter.condition, { line_item: lineItemProperties });
  });

  return filterMatch
    ? {
        warningMessage: filterMatch.userMessage,
      }
    : null;
};
