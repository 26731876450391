import { useCallback } from 'react';
import { getCurrencyFormat } from '../helpers/getCurrencyFormat';
import { usePublicConfig, useSessionData } from '../firebase/hooks';

export const useCurrencyFormatter = () => {
  const { order } = useSessionData();
  const { language } = usePublicConfig();

  return useCallback(
    (amount: number) => {
      return getCurrencyFormat(amount, order?.currency, language);
    },
    [language, order?.currency],
  );
};
