import React from 'react';

import { useFirebase } from './firebase/context';
import Head from './components/Head/Head';
import { Logo } from './components/Logo';
import chromeImg from './images/browsers/chrome.svg';
import firefoxImg from './images/browsers/firefox.svg';
import edgeImg from './images/browsers/edge.svg';
import safariImg from './images/browsers/safari-ios.svg';
import { interpolateAppText, useAppTexts } from './hooks/useAppTexts';

export const NoApp = () => {
  const appTexts = useAppTexts();
  const { publicConfig } = useFirebase();
  const { logo, supportEmail } = publicConfig ?? {};

  const initializedMarkup = (
    <React.Fragment>
      <Logo src={logo} />

      <div className="box no-app-box">
        <h1 className="no-app-title">{appTexts.noApp.title}</h1>

        <p className="no-app-text">{appTexts.noApp.notPossible}</p>
        <p className="no-app-text">
          {appTexts.noApp.useDifferentBrowser} (
          <a href="http://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
            Chrome
          </a>
          ,{' '}
          <a href="http://www.mozilla.org/firefox/new/" target="_blank" rel="noopener noreferrer">
            Firefox
          </a>
          ,{' '}
          <a href="http://www.microsoft.com/edge/" target="_blank" rel="noopener noreferrer">
            Edge
          </a>
          ,{' '}
          <a
            href="http://support.apple.com/downloads/safari"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari
          </a>
          ) {appTexts.noApp.orVisitWithPhone}.
        </p>

        <div className="no-app-browsers">
          <a
            href="http://www.google.com/chrome/"
            target="_blank"
            rel="noopener noreferrer"
            className="no-app-browsers-link"
          >
            <img src={chromeImg} alt="Chrome" />
          </a>
          <a
            href="http://www.mozilla.org/firefox/new/"
            target="_blank"
            rel="noopener noreferrer"
            className="no-app-browsers-link"
          >
            <img src={firefoxImg} alt="Firefox" />
          </a>
          <a
            href="http://www.microsoft.com/edge/"
            target="_blank"
            rel="noopener noreferrer"
            className="no-app-browsers-link"
          >
            <img src={edgeImg} alt="Edge" />
          </a>
          <a
            href="http://support.apple.com/downloads/safari"
            target="_blank"
            rel="noopener noreferrer"
            className="no-app-browsers-link"
          >
            <img src={safariImg} alt="Safari" />
          </a>
        </div>
      </div>

      <div className="footer">
        {interpolateAppText(appTexts.footer.default, { supportEmail: supportEmail })}
      </div>
    </React.Fragment>
  );

  return (
    <div className="app-container">
      <Head />
      {initializedMarkup}
    </div>
  );
};
