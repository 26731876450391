import { AppTexts } from '../../../../../../functions/src/shared';

export const dk: AppTexts = {
  generic: {
    logo: 'Logo',
    signOut: 'Klik her for at fjerne alle returpræferencer (start forfra).',
    goBack: 'Gå tilbage',
    exchange: 'Byt',
    return: 'Returnér',
    returnAlreadySubmitted: 'Det lader til, at du allerede har indsendt denne returnering.',
    multipleRefundsPossible:
      'Du anmoder om en refusion på en byttet ordre. Når dette håndteres, modtager du mere end én notifikation om refusion, da dine penge (delvist) er i den første ordre, du lavede.',
    submitAnotherReturn: 'Indsend en anden returnering',
    free: 'Gratis',
    next: 'Næste',
    error: {
      problemLoading: 'Der opstod et problem med at indlæse denne side',
      technicalProblem: 'Et teknisk problem forhindrer siden i at indlæses korrekt.',
      comeBack: 'Kom tilbage om et par minutter og prøv igen',
      contactSupport: 'Kontakt support',
      serverError: 'Serverfejl',
      networkError: 'Netværksfejl',
      timeout:
        'Det ser ud til, at serveren er for længe om at svare. Dette kan enten skyldes dårlig forbindelse eller en fejl med vores servere. Forsøg venligst igen senere.',
      other:
        'Det ser ud til, at noget gik galt. Prøv igen senere eller kontakt os på {{supportEmail}}',
    },
    form: {
      fieldRequired: 'Dette felt er påkrævet',
      invalidEmail: 'E-mailadressen er ugyldig',
    },
  },
  footer: {
    default:
      'Har du brug for hjælp til at returnere din varer? Kontakt os venligst på {{supportEmail}}',
    privacyPolicy: 'Fortrolighedspolitik',
  },
  orderLookup: {
    title: 'Returneringer og ombytninger',
    pageTitle: 'Opret en ny returnering eller ombytning',
    orderNumber: 'Ordrenummer',
    postalcode: 'Postnummer',
    emailaddress: 'E-mailadresse',
    any: 'E-mailadresse eller postnummer',
    submit: 'Start',
    error: {
      notFound: 'Ordre ikke fundet. Kontrollér venligst dine oplysninger og prøv igen.',
      currencyNotSupported:
        'This platform can only handle returns in {{supportedCurrency}}. Please contact {{supportEmail}} to handle your return.',
    },
  },
  itemsList: {
    title: 'Vælg et produkt du ønsker at returnere eller bytte',
    pageTitle: 'Ordre',
    ineligibleSubtitle: 'Ikke alle varer i denne ordre kan returneres',
    returnableUntil: 'Ordre {{orderName}} kan returneres indtil {{returnableUntilDate}}',
    noItemsEligible: 'På nuværende tidspunkt er ingen produkter berettiget refundering.',
    noReturnCountry:
      'Ingen produkter er berettiget til returnering på nuværende tidspunkt, fordi vi ikke har en returpolitik for dit land. Kontakt venligst support for at opfylde din returnering.',
    returnWindowClosed: 'Returretten er overskredet. \n Produkter kan ikke længere refunderes.',
    warrantyReturnPossible: 'Disse produkter er stadig under garanti.',
    submitWarrantyRequest: 'Indsend en garanti anmodning.',
    returnAlreadySubmitted: 'En returnering for ordre {{orderName}} er allerede indsendt.',
    productsWithTechnicalErrors:
      'Et eller flere produkter fra din ordre kan ikke returneres eller byttes på grund af et teknisk problem. Kontakt os venligst vedrørende dette på {{supportEmail}}',
    lastUpdate: 'Seneste opdatering',
    selectedItems: '{{count}} produkt(er) valgt',
    continueWithReturn: 'Fortsæt med returneringen',
    cannotContinueWithReturn:
      'De valgte varer overstiger værdien af det tilgængelige returbeløb. Justér venligst dit valg, så værdien af de ønskede varer er større end eller lig med værdien af de varer, du ønsker at returnere/bytte.',
    expectedArrivalDate: 'Forventet ankomstdato',
    paymentPending: {
      title: 'Betaling afventer',
      message: 'Vi afventer din betaling. Tjek venligst din e-mail eller kontakt os på {{email}}',
    },
    adjustmentsInCalculation: 'Beregningen inkluderer ekstra omkostninger',
  },
  shippingLabel: {
    isPaidLabel:
      'You will have to pay {{amount}} in order to receive your {{carrier}} return label.',
  },
  item: {
    exchangeInfoDiscount:
      'Bytteværdien for denne vare er {{exchangeValue}}.\nNår du vælger at returnere varen, refunderes {{refundValue}}.',
    exchangeInfoPriceIncrease: {
      always:
        'På grund af en nylig prisstigning\ner bytteværdien for denne vare {{exchangeValue}}.\nNår du vælger at returnere varen, refunderes {{refundValue}}.',
      'both-match':
        'På grund af en nylig prisstigning\ner bytteværdien for denne vare {{exchangeValue}} for udvalgte produkter.\nNår du vælger at returnere varen, refunderes {{refundValue}}.',
      'same-product':
        'På grund af en nylig prisstigning\ner bytteværdien for denne vare {{exchangeValue}} når du bytter til en anden variant.\nNår du vælger at returnere varen, refunderes {{refundValue}}.',
      'same-variant':
        'På grund af en nylig prisstigning\ner bytteværdien for denne vare {{exchangeValue}} når du bytter til det samme produkt.\nNår du vælger at returnere varen, refunderes {{refundValue}}.',
      none: '',
    },
    adjustmentDecrease:
      'På grund af returneringen af et andet produkt er der mere rabat tilgængelig.',
    adjustmentIncrease:
      'På grund af returneringen af et andet produkt er der mindre rabat tilgængelig.',
  },
  tracking: {
    status: {
      droppedOff: 'afsendt',
      inTransit: 'på vej',
      submitted: 'tilføjet',
      delivered: 'leveret',
    },
  },
  itemWizard: {
    isCurrentVariant: 'Nuværende',
    chooseVariant: 'Vælg en variant',
    outOfStock: 'Ikke på lager',
    reasonsInfoBottom: '',
    otherCollectionInfoTop: '',
    otherVariantInfoTop: '',
    reasonsWhyExchange: 'Hvorfor ønsker du at bytte?',
    reasonsWhyReturn: 'Hvorfor ønsker du at returnere?',
    typeCommentHereAndCount: 'Skriv din kommentar her (min. {{count}} characters)...',
    typeCommentHere: 'Skriv din kommentar her...',
    commentIsEmpty: 'Kommentarfeltet må ikke være tomt.',
    commentIsTooShort: 'Kommentaren skal bestå af mindst {{count}} karakterer.',
    submitComment: 'Tilføj kommentar',
    noProductFound: 'Ingen relaterede produkter fundet',
    from: 'Fra',
    howMakeItRight: 'Hvordan vil du have det rettet?',
    sendSameProduct: 'Send mig det samme produkt igen',
    showMoreOptions: 'Vis flere muligheder',
  },
  shipping: {
    returnMethod: 'Returneringsmetode',
    pleaseSelectMethod: 'Hvordan vil du returnere varen?',
  },
  review: {
    title: 'Gennemgå din returnering',
    pageTitle: 'Gennemgå din returnering',
    itemsToReceive: 'Produkt(er) du modtager',
    itemsToSendBack: 'Produkt(er) du skal sende tilbage',
    shippingAddressTitle: 'Din bytteordre sendes til',
    deliveryOptionTitle: 'Hvor skal vi sende din ombytning til?',
    returnShippingCosts: 'Returfragt',
    deliveryOption: {
      billingAddress: 'Min hjemmeadresse (faktureringsadresse) eller tilføj en anden adresse',
      pickupPoint: 'Pakkeudleveringssted valgt i tidligere ordre',
    },
    noItemsSelected:
      'Du har ikke valgt nogen produkter. Gå tilbage og vælg de produkter du ønsker at returnere eller bytte.',
    submitReturn: 'Indsend returnering',
    submitReturnAndPay: 'Pay for return label and confirm return',
    removedDiscount: 'Ekstra betaling for {{itemTitle}}',
    extraDiscount: 'Ekstra rabat for {{itemTitle}}',
    refund: {
      title: 'Oversigt',
      calculationPriceIncreasement: 'Justering af prisstigninger',
      valueOfReturnedProducts: 'Værdien af returnerede produkter',
      valueOfExchangeProducts: 'Værdien af byttede produkter',
      appliedDiscountToOrder: 'Rabat på oprindelig ordre',
      totalRefundAmount: 'Samlet refusionsbeløb',
      totalAdditionalPayment: 'Samlet ekstra betaling',
      additionalPaymentInfo:
        'Der mangler betaling. Når din anmodning er blevet godkendt, sender vi et link til {{email}}, hvori du kan betale det manglende beløb',
      shippingCostRefundInfo:
        'Hvis du har betalt for forsendelsesomkostningerne, refunderer vi ligeledes disse. De er ikke en del af det samlede refusionsbeløb ovenfor og vil derfor blive tilføjet, når vi refunderer din ordre.',
    },
    budbee: {
      typePhoneNumberHere: 'Skriv dit mobiltelefonnummer her',
      hasBillingPhoneNumber:
        'Vi bruger {{phoneNumber}} til din Budbee-afhentning. Hvis du vil bruge et andet telefonnummer, skal du udfylde det nedenfor:',
      hasNoBillingPhoneNumber:
        'Vi har ikke et telefonnummer fra dig. Budbee har brug for dette for at opdatere dig om afhentningen. Udfyld venligst det nedenfor:',
      phoneNumberInvalid:
        'Dit telefonnummer er ugyldigt. Inkluder venligst landekoden og et "+", f.eks. "+45987654321".',
    },
    sendcloud: {
      header: 'Vi har brug for mere information!',
      explanation:
        'For at kunne give den rette information til fragtfirmaet, har vi brug for din gadeadresse og husnummer. Udfyld dem venligst nedenfor, så vi kan oprette en retur for dig.',
      streetNamePlaceholder: 'Gadenavn',
      streetNumberPlaceholder: 'Husnummer',
      streetNameInvalid: 'Ikke et gyldigt gadenavn, udfyld venligst noget.',
      streetNumberInvalid:
        "Ikke et gyldigt vejnummer. Det skal starte med et tal, f.eks. '42B' og dens længde må ikke overstige 20 tegn.",
    },
    comment: {
      infoExchange:
        'Hvis du ønsker at ændre adressen, bedes du tilføje den nye adresse i kommentarfeltet nedenfor.',
      infoRefund:
        'Hvis du ønsker at tilføje yderligere bemærkninger, bedes du venligst tilføje en kommentar til denne returnering.',
      addComment: 'Tilføj kommentar',
      clearComment: 'Ryd kommentar',
      addAddress: 'Tilføj ny adresse',
      clearAddress: 'Fjern ny adresse',
      commentIsEmpty: 'Kommentarfeltet må ikke være tomt.',
      commentIsTooShort: 'Kommentaren skal bestå af mindst {{count}} karakterer.',
    },
    error: {
      notAccepted: 'Denne returnering er allerede oprettet.',
    },
    additionalRefundInfo:
      'Vi refunderer til den betalingskonto (eller gavekort), der blev brugt til den oprindelige transaktion.',
  },
  summary: {
    title: 'Din returoversigt',
    pageTitle: 'Returoversigt',
    generatingLabel: 'Behandler anmodning om returlabel',
    submittingPickUp: 'Din Budbee-afhentningsanmodning indsendes',
    thanksSubmittingBudbee:
      'Den estimerede afhentningstid for din pakke er den {{day}} mellem {{start}} og {{stop}}. Hvis du ønsker at spore eller ændre afhentningen, skal du bruge Budbee-appen med det telefonnummer, du har indsendt med denne returnering: {{phoneNumber}}.',
    labelReady: 'Din label er klar til udskrivning',
    paymentLinkReady: 'Tryk på knappen nedenfor, når du ikke bliver omdirigeret',
    thanksSubmitting: 'Tak for at indsende din returnering',
    noLabel:
      'Det ser ud til, at noget gik galt, da du skulle modtage din label. Kontakt os på {{ supportEmail }} så sender vi den korrekte label til dig på ingen tid.',
    labelNotPaid:
      'Det ser ud til, at returmærket ikke er betalt. Brug venligst knappen nedenfor for at genstarte betalingsprocessen. Hvis du allerede har betalt, og du ser denne fejl, bedes du kontakte os på {{ supportEmail }}',
    downloadLabel: 'Download returlabel',
    redirectToPayment: 'Fortsæt med betaling',
    restartPayment: 'Genstart betalingsprocessen',
    mailWillBeSentTo: 'Returlabel og instruktioner er også mailet til',
    mailWillBeSentToOnlyInstructions: 'Instruktioner er også mailet til',
    mailWillBeSentToPaperless: 'Instruktioner er mailet til',
    mailWillBeSentToPaperlessSub:
      'Denne e-mail indeholder også en stregkode, du skal bruge ved indleveringen af din pakke.',
    howToTitle: 'Sådan returnerer du dine varer',
    itemsToSendBack: 'Produkt(er) du skal sende tilbage',
    itemsToReceive: 'Produkt(er) du modtager',
    estimatedProcessingTime:
      'Det tager omkring {{count}} hverdag(e) at behandle din returnering, efter du har afsendt din pakke.',
    commercialInvoiceReady: 'Customs Invoice Ready',
    downloadCommercialInvoice: 'Download customs invoice',
    noCommercialInvoice:
      'It seems that something went wrong while generating the customs invoice.  Contact us at {{ supportEmail }} and we will send you the correct invoice to attach to your return.',
  },
  rma: {
    option: {
      genericExchange: 'Byt til et andet produkt',
      genericReturn: 'Returnér produkt',
    },
    warning: {
      alreadyReturned: 'Produktet er allerede returneret',
      rmaInProcess: 'Produktet er allerede indsendt',
      unfulfilledLineItem: 'Produktet er ikke modtaget',
      noReturnGiftCards: 'Gavekort er ikke berettiget til returnering',
      noPhysicalProduct: 'Digitale produkter er ikke berettiget til returnering',
      returnWindowExpired: 'Returretten er udløbet',
      countryNotReturnable: 'Vi kan desværre ikke acceptere denne vare fra dit land',
      notEligibleForReturn:
        'Dette produkt er ikke berettiget til returnering, for spørgsmål kontakt venligst kundeservice',
    },
  },
  pageNotFound: {
    title: '404',
    body: 'Side ikke fundet',
  },
  shopNotFound: {
    title: '403',
    body: 'Adgang nægtet',
  },
  noApp: {
    title: 'Browseren understøttes ikke',
    notPossible: 'Ombytning/returnering er desværre ikke muligt gennem Internet Explorer.',
    useDifferentBrowser: 'Brug venligst en anden browser',
    orVisitWithPhone: 'eller besøg denne side via din mobil',
  },
  delivery: {
    other: 'Få leveret til en anden adresse',
    otherAddress: 'Skriv den nye adresse i kommentarfeltet',
  },
};
