import React from 'react';
import { useAppTexts } from '../hooks/useAppTexts';

export const PageNotFound = ({ history }) => {
  const appTexts = useAppTexts();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="box page-not-found-box">
      <h1 className="title is-1">{appTexts.pageNotFound.title}</h1>
      <p className="page-not-found-body">{appTexts.pageNotFound.body}</p>
      <button className="button" onClick={handleGoBack}>
        {appTexts.generic.goBack}
      </button>
    </div>
  );
};
