import React from 'react';

export const TruckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" x="0px" y="0px">
    <title>In transit</title>
    <path d="M88.37988,30.39,71.34009,27.2v6.12L87.27,36.3A8.24179,8.24179,0,0,1,94,44.4V74.23h-.5a12.86943,12.86943,0,0,0-25.03,0H67.34009V21.57A11.69806,11.69806,0,0,0,55.66992,9.89H0v6H55.66992a5.68047,5.68047,0,0,1,5.67017,5.68V74.23H36.37988a12.86927,12.86927,0,0,0-25.02978,0H6V60.67H0V80.23H11.3501a12.86624,12.86624,0,0,0,25.02978,0H68.47a12.86639,12.86639,0,0,0,25.03,0H100V44.4A14.26335,14.26335,0,0,0,88.37988,30.39ZM30.05005,80.23a6.875,6.875,0,0,1-13.06006-3,6.69087,6.69087,0,0,1,.7-3,6.86152,6.86152,0,0,1,12.3501,0,6.69087,6.69087,0,0,1,.69995,3A6.85154,6.85154,0,0,1,30.05005,80.23Zm57.11987,0a6.87513,6.87513,0,0,1-13.06006-3,6.68773,6.68773,0,0,1,.7002-3,6.86132,6.86132,0,0,1,12.34985,0,6.69087,6.69087,0,0,1,.7,3A6.85154,6.85154,0,0,1,87.16992,80.23Z" />
    <rect y="28.01501" width="32.58333" height="6" />
    <rect y="44.18168" width="18.66667" height="6" />
  </svg>
);
