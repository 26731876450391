import React from 'react';

import { Notification } from './Notification';
import { getCurrencyFormat } from '../helpers/getCurrencyFormat';
import { interpolateAppText, useAppTexts } from '../hooks/useAppTexts';
import { SelectedMontaOption } from '../../../../functions/src/shared';

export const MontaPaymentRequired = ({
  language,
  returnOption,
  inline = false,
}: {
  language: string;
  returnOption: SelectedMontaOption;
  inline?: boolean;
}) => {
  const appTexts = useAppTexts();

  if (!returnOption.price || returnOption.price.amount === 0) return null;

  const montaLabelPrice = getCurrencyFormat(
    returnOption.price.amount / 100,
    returnOption.price.currency,
    language,
  );
  const montaCarrier = returnOption.label;

  return (
    <div className={inline ? '' : 'shipping-label-paid'}>
      <Notification status="info">
        {interpolateAppText(appTexts.shippingLabel.isPaidLabel, {
          amount: montaLabelPrice,
          carrier: montaCarrier,
        })}
      </Notification>
    </div>
  );
};
