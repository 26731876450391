import React from 'react';
import { NoImageFound } from '../NoImageFound';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullWizardItem, WizardAction } from '../../contexts/WizardContext/WizardContext';
import { interpolateAppText, useAppTexts } from '../../hooks/useAppTexts';
import { formatProductOptions } from '../../helpers/formatProductOptions';
import { useLoadedFirebaseData } from '../../firebase/hooks';
import { resizeImage } from '../../helpers/resizeImage';
import {
  LineItemAdjustment,
  shouldRespectPriceIncreaseOnExchange,
} from '../../../../../functions/src/shared';
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat';

interface IProps {
  itemData: FullWizardItem;
  adjustments: LineItemAdjustment[];
  warning?: string | null;
  selected?: WizardAction | null;
  deselect?: (selectionId: string) => void;
  onClick?: () => void;
}

const ItemCard = ({ itemData, selected, deselect, onClick, warning, adjustments }: IProps) => {
  const appTexts = useAppTexts();
  const {
    config: { respectPriceIncreaseOnExchangeStrategy },
    order: { currency },
    publicConfig: { language },
  } = useLoadedFirebaseData();

  const {
    selectionId,
    product,
    variant,
    discountedPrice,
    originalPrice,
    displayOriginalPrice,
    currentVariantPrice,
    displayCurrentVariantPrice,
    displayDiscountedPrice,
  } = itemData;

  const hasDiscount = (originalPrice ?? 0) > (discountedPrice ?? 0);

  const totalAdjustment = adjustments.reduce((acc, adjustment) => acc + adjustment.amount, 0);
  const adjustmentHelpText =
    totalAdjustment > 0 ? appTexts.item.adjustmentIncrease : appTexts.item.adjustmentDecrease;

  // check if the line has an increased price AND it is allowed to
  // allow the higher price to be the exchange value
  const showHasIncreasedPrice =
    shouldRespectPriceIncreaseOnExchange(
      respectPriceIncreaseOnExchangeStrategy,
      {
        productId: itemData.product.id,
        variantId: itemData.variant.id,
        productTags: itemData.product.tags,
      },
      {
        productId: itemData.product.id,
        variantId: itemData.variant.id,
        productTags: itemData.product.tags,
      },
    ) && (currentVariantPrice ?? 0) > (originalPrice ?? 0);

  const variantOption = formatProductOptions(product.options);

  // set from price, both the same when having discount or an allowed price increasement
  const fromPrice = showHasIncreasedPrice ? displayCurrentVariantPrice : displayOriginalPrice;
  const priceHelpText = showHasIncreasedPrice
    ? interpolateAppText(
        typeof appTexts.item.exchangeInfoPriceIncrease === 'string' // Backwards compatibility for app text overrides in store config
          ? appTexts.item.exchangeInfoPriceIncrease
          : appTexts.item.exchangeInfoPriceIncrease[
              respectPriceIncreaseOnExchangeStrategy?.type ?? 'always' // fallback never happens
            ],
        {
          exchangeValue: displayCurrentVariantPrice,
          refundValue: displayDiscountedPrice,
        },
      )
    : interpolateAppText(appTexts.item.exchangeInfoDiscount, {
        exchangeValue: displayOriginalPrice,
        refundValue: displayDiscountedPrice,
      });

  const priceInfoMarkup =
    hasDiscount || showHasIncreasedPrice ? (
      <span data-tooltip={priceHelpText}>
        <FontAwesomeIcon icon="info-circle" className="item-card-higher-price-icon" />
      </span>
    ) : null;

  const image = resizeImage(variant.image, 1200, 1200);

  return (
    <div
      className={`card item-card ${warning ? 'is-disabled' : 'is-selectable'} ${
        selected ? 'is-selected' : ''
      }`}
      onClick={onClick}
    >
      {selected && (
        <div className="item-card-selected">
          <FontAwesomeIcon icon="check" className="item-card-selected-icon" />
        </div>
      )}
      <div className="item-card-image">
        {image ? <img src={image} alt={product.title} /> : <NoImageFound />}
      </div>
      <div className="item-card-content">
        <p className="item-card-title">{product.title}</p>
        {Array.isArray(product.variants) && product.variants.length > 1 && (
          <p className="item-card-variant">
            <span>
              {variantOption}
              {variant.title}
            </span>
          </p>
        )}
        {warning ? (
          <p className="item-card-warning">{warning}</p>
        ) : (
          <p className="item-card-price">
            {hasDiscount || showHasIncreasedPrice ? (
              <span>
                <span className="item-card-price-discount">{fromPrice}</span>&nbsp;
                <span>{displayDiscountedPrice}</span>
                {priceInfoMarkup}
              </span>
            ) : (
              <span>
                <span>{displayDiscountedPrice}</span>
                {priceInfoMarkup}
              </span>
            )}
          </p>
        )}
        {totalAdjustment !== 0 && (
          <p className="tag is-warning item-card-adjustment-warning">
            <span>{getCurrencyFormat(totalAdjustment, currency, language)}</span>
            <span
              data-tooltip={adjustmentHelpText}
              className="item-card-adjustment-warning-tooltip"
            >
              <FontAwesomeIcon icon="info-circle" />
            </span>
          </p>
        )}
        {selected && <span className="tag is-secondary">{appTexts.generic[selected.action]}</span>}
      </div>
      {selected && deselect && selectionId && (
        <div className="item-card-deselect" onClick={() => deselect(selectionId)}>
          <FontAwesomeIcon icon="times" />
        </div>
      )}
    </div>
  );
};

export default ItemCard;
