import React from 'react';
import { ExchangeWizardItem, FullWizardItem } from '../../contexts/WizardContext/WizardContext';
import { formatProductOptions } from '../../helpers/formatProductOptions';
import { NoImageFound } from '../NoImageFound';
import { resizeImage } from '../../helpers/resizeImage';

export const ReviewItemCard = ({
  itemData,
}: {
  itemData: ExchangeWizardItem &
    Partial<
      Pick<
        FullWizardItem,
        'discountedPrice' | 'displayDiscountedPrice' | 'displayOriginalPrice' | 'originalPrice'
      >
    >;
}) => {
  const {
    displayOriginalPrice,
    originalPrice,
    discountedPrice,
    displayDiscountedPrice,
    product,
    variant,
  } = itemData;
  const image = resizeImage(variant.image, 1200, 1200);

  // check if there were discounts
  const hasDiscount = (originalPrice ?? 0) > Number(discountedPrice);
  const variantOption = formatProductOptions(product.options);

  return (
    <div className="card item-card is-review-card">
      <div className="item-card-image item-card-image-review">
        {image ? <img src={image} alt={product.title} /> : <NoImageFound />}
      </div>
      <div className="item-card-content">
        <p className="item-card-title">{product.title}</p>
        {Array.isArray(product.variants) && product.variants.length > 1 && (
          <p className="item-card-variant">
            <span>
              {variantOption}
              {variant.title}
            </span>
          </p>
        )}
        {/* <p className="item-card-variant">
          <span>{variantTitle}</span>
        </p> */}
      </div>
      <p className="item-card-price-review">
        <span className={hasDiscount ? 'item-card-price-discount' : ''}>
          {displayOriginalPrice}
        </span>
        {hasDiscount && <span>{displayDiscountedPrice}</span>}
      </p>
    </div>
  );
};
