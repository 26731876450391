Object.defineProperty(exports, "__esModule", { value: true });
exports.chunk = void 0;
/**
 * Groups folowing items based on if they are random or not.
 */
function chunk(input) {
    let index = 0;
    const parts = input.reduce((carry, current, i, array) => {
        const previous = array[i - 1];
        if (previous && current.randomize !== previous.randomize)
            index++;
        if (carry[index] === undefined)
            carry[index] = [current];
        else
            carry[index].push(current);
        return carry;
    }, []);
    return parts;
}
exports.chunk = chunk;
