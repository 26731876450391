import { useMemo } from 'react';
import { SfyOrderType, formatCurrency } from '../../../../../functions/src/shared';

export const useWarrantyMailtoUrl = (
  order: SfyOrderType,
  customerSupportEmail: string,
  locale: string,
) => {
  return useMemo(() => {
    const subject = `Warranty request for order: ${order.name}`;

    const body = `I would like to submit a warranty request for the following order:

---
Order: ${order.name} (${order.id})
Placed on: ${order.createdAt.toDate().toLocaleDateString()}
By: ${order.billingAddress.name}

Items:
${order.lineItems.map((item) => `- ${item.title} (${item.variantTitle}) (SKU: ${item.sku})\n`)}

Subtotal: ${formatCurrency(Number(order.totalLineItemsPrice) * 100, order.currency, locale)}
${
  order.totalShippingPrice !== undefined
    ? `Shipping: ${formatCurrency(Number(order.totalShippingPrice) * 100, order.currency, locale)}`
    : ''
}
Total : ${formatCurrency(Number(order.totalPrice) * 100, order.currency, locale)}
---

There is an issue with the following items:

[DESCRIBE WHICH ITEM]

[ATTACH PICTURE OF ITEMS WHICH HAVE ISSUES]

Kind regards
${order.billingAddress.name}
`;

    return `mailto:${customerSupportEmail}?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(body)}`;
  }, [order, locale, customerSupportEmail]);
};
