import React from 'react';
import { usePreviousRmas } from './usePreviousRmas';
import { useLoadedFirebaseData } from '../../../firebase/hooks';
import RmaStatusSection from './RmaStatusSection';
import './PreviousRmasStatuses.css';

export const PreviousRmasStatuses = () => {
  const { order } = useLoadedFirebaseData();
  const [previousRmas, previousRmasLoading] = usePreviousRmas(order.id);

  if (previousRmasLoading) {
    return <div className="custom-loader is-loading"></div>;
  }

  return (
    <div className="rma-statuses-list">
      {previousRmas?.map((rma) => (
        <RmaStatusSection rmaRequest={rma} key={rma.rmaName} />
      ))}
    </div>
  );
};
