import { DerivedLineItem, DerivedOrderState } from "../orders/derived_order";
import { fetchAgradiLineItemDiscounts } from "./agradi/fetchAgradiLineItemDiscounts";
import { SfyDiscountApplicationType } from "../sfy_order_types";

export type LineItemDiscountProviderFunc = (
  originalLineItem: DerivedLineItem,
  // Defined when the line item is an exchange
  nextLineItem: DerivedLineItem | undefined | null,
  nextOrderState: DerivedOrderState,
) => SfyDiscountApplicationType[];

export type LineItemDiscountProvider = "agradi";

export const lineItemDiscountsProviders: Record<
  LineItemDiscountProvider,
  LineItemDiscountProviderFunc
> = {
  agradi: fetchAgradiLineItemDiscounts,
};
