import React from 'react';
import { useLoadedFirebaseData } from '../../../firebase/hooks';
import { Notification } from '../../Notification';
import { useAppTexts } from '../../../hooks/useAppTexts';
import {
  ExchangeAction,
  FullWizardItem,
  WizardContext,
} from '../../../contexts/WizardContext/WizardContext';
import WizardSelector from './WizardSelector';
import { createVariantOptions } from './createVariantOptions';
import { useNonNullableContext } from '../../../hooks/useNonNullableContext';

interface ExchangeVariantProps {
  itemData: FullWizardItem;
  onSubmit: (data: ExchangeAction['exchangeFor']) => void;
}

const ExchangeVariant = ({ itemData, onSubmit }: ExchangeVariantProps) => {
  const appTexts = useAppTexts();
  const firebase = useLoadedFirebaseData();
  const { collectedWizardData } = useNonNullableContext(WizardContext);

  const { config, publicConfig: appThemeData } = firebase;

  const options = createVariantOptions(
    itemData,
    collectedWizardData,
    itemData.product,
    config,
    appTexts,
    firebase.order,
    appThemeData?.language,
  );

  const stepInfoMarkup = appTexts.itemWizard && appTexts.itemWizard.otherVariantInfoTop && (
    <Notification size="small">
      <div dangerouslySetInnerHTML={{ __html: appTexts.itemWizard.otherVariantInfoTop }}></div>
    </Notification>
  );

  return (
    <div className="item-wizard-content-body">
      <WizardSelector options={options} onSelect={onSubmit} />
      {stepInfoMarkup}
    </div>
  );
};

export default ExchangeVariant;
