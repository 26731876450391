import { AppTexts } from '../../../../../functions/src/shared';
import { appConfig } from '../../config';
import { interpolateAppText } from '../../hooks/useAppTexts';

export const submitOrder = async (
  orderNr: string,
  secret: string,
  shop: string,
  appTexts: AppTexts,
  supportEmail: string,
) => {
  const url = `${appConfig.cloudFunctionsUri}/CustomerSession/login`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      shop: shop,
      order_name: orderNr,
      secret,
    }),
  };

  let response;

  try {
    response = await fetch(url, options);
  } catch (error) {
    console.error('Error fetching from /CustomerSession/login: ', error);
    throw interpolateAppText(appTexts.generic.error.other, { supportEmail: supportEmail });
  }

  switch (response.status) {
    case 200:
      const responseData = await response.json();
      return responseData.token;
    case 403:
    case 404:
      throw appTexts.orderLookup.error.notFound;
    case 408:
      throw appTexts.generic.error.timeout;
    default:
      throw interpolateAppText(appTexts.generic.error.other, { supportEmail: supportEmail });
  }
};
