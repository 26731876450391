import { createContext } from 'react';
import {
  QuickSelectNeighbourOption,
  TProductData,
  TVariantData,
  SelectedCarrier,
  TRMAItem,
  TSubmitRequestPayloadAction,
  BaseRmaRequest,
} from '../../../../../functions/src/shared';

interface BaseWizardItem {
  isStorefrontNullProduct?: boolean;
}

export type FullWizardItem = BaseWizardItem & {
  lineItemId: number;
  product: TProductData;
  variant: TVariantData;
  selectionId: string;
  quantity?: number;
  discountPercent: number;
  discountPercentAll: number;
  originalPrice: number;
  displayOriginalPrice: string;
  discountedPrice?: number | null;
  displayDiscountedPrice?: string;
  warning: string | null;
  currentVariantPrice: number;
  displayCurrentVariantPrice?: string;
  priceBand: [number, number];
};

export type WizardItem = FullWizardItem | BaseWizardItem;

export type ExchangeWizardItem = Pick<FullWizardItem, 'product' | 'variant' | 'discountedPrice'>;

export const makeExchangeWizardItem = (
  product: TProductData | FullWizardItem,
  variant: TVariantData,
): ExchangeWizardItem => ({
  product: 'product' in product ? product.product : product,
  variant,
});

type BaseAction = {
  action: 'exchange' | 'return';
  initialItem: FullWizardItem;
  exchangeFor?: ExchangeWizardItem | null;
} & Pick<TRMAItem, 'reasonIds' | 'additionalReason' | 'additionalComment'>;

export interface ExchangeAction extends BaseAction {
  action: 'exchange';
  exchangeFor: ExchangeWizardItem;
}

interface ReturnAction extends BaseAction {
  action: 'return';
}

export type WizardAction = ExchangeAction | ReturnAction;

export interface WizardDataAdjustments {
  totalAdjustments: number;
  adjustments: {
    amount: number;
    description: string;
  }[];
}

export type CollectedWizardData = Partial<Record<string, WizardAction | null>>;

export interface BaseWizardData {
  /** the collected data of the wizard */
  collectedWizardData: CollectedWizardData;
  returnOption?: SelectedCarrier;
  isReturnAlreadySubmitted: boolean;
  currency?: string;
}

export type DerivedWizardData = {
  /** the number of selected items for return/exchange */
  nrOfSelectedItems: number;
  allActions: WizardAction[];
  exchangeActions: ExchangeAction[];
  hasActions: boolean;
  hasExchangeActions: boolean;
  orderDiscount: number;
  amountOwedExchanges: number;
  amountOwedKeptItemAdjustments: number;
  amountOwed: number;
  refundTotal: number;
  refundTotalBeforeDiscount: number;
  formattedOrderDiscount: string;
  formattedRefundTotal?: string;
  formattedOrderPriceCompensations?: string;
  formattedRefundTotalBeforeDiscount?: string;
  returnCredit: number;
  orderPriceCompensations: number;
  returnCreditBeforeDiscounts: number;
  adjustmentLines?: {
    amount: number;
    description: string;
    formattedAmount?: string;
  }[];
} & Required<Pick<BaseRmaRequest, 'keptItemsAdjustments'>>;

export type WizardData = BaseWizardData & DerivedWizardData;

interface ContextFunctions {
  setWizardData: (data: Partial<BaseWizardData>) => void;
  resetWizard: () => void;
}

type ContextData = WizardData & ContextFunctions;

export const WizardContext = createContext<ContextData | null>(null);

interface NeighbourSuggestion {
  variant: TVariantData;
  copy: string;
}

export const findPossibleNeighboursSorted = (
  item: FullWizardItem,
  quickSelect: QuickSelectNeighbourOption,
): NeighbourSuggestion[] => {
  const currentOption = item.variant.options[quickSelect.optionName];
  const variantOption = item.product.options?.find(
    (option) => option.name === quickSelect.optionName,
  );

  if (!currentOption || !variantOption) {
    return [];
  }

  const currentOptionIndex = variantOption.values.indexOf(currentOption);

  if (currentOptionIndex === -1) {
    return [];
  }

  const suggestions: NeighbourSuggestion[] = [];

  if (quickSelect.suggestedChoices === 'lower' || quickSelect.suggestedChoices === 'both') {
    const lowerOptions = variantOption.values
      .filter((_, i) => i < currentOptionIndex)
      .reverse() // Because we want to show the closest options first
      .slice(0, quickSelect.suggestionDepth);

    const lowerVariants = lowerOptions.flatMap((optionValue, i) => {
      const matchedVariant = item.product.variants.find((variant) =>
        doesVariantMatchOptions(variant, {
          ...item.variant.options,
          [quickSelect.optionName]: optionValue,
        }),
      );

      return matchedVariant
        ? [
            {
              variant: matchedVariant,
              copy: quickSelect.lowerOptionTexts[i] ?? matchedVariant.title,
            },
          ]
        : [];
    });

    suggestions.push(...lowerVariants);
  }

  if (quickSelect.suggestedChoices === 'higher' || quickSelect.suggestedChoices === 'both') {
    const higherOptions = variantOption.values
      .filter((_, i) => i > currentOptionIndex)
      .slice(0, quickSelect.suggestionDepth);

    const higherVariants = higherOptions.flatMap((optionValue, i) => {
      const matchedVariant = item.product.variants.find((variant) =>
        doesVariantMatchOptions(variant, {
          ...item.variant.options,
          [quickSelect.optionName]: optionValue,
        }),
      );

      return matchedVariant
        ? [
            {
              variant: matchedVariant,
              copy: quickSelect.higherOptionTexts[i] ?? matchedVariant.title,
            },
          ]
        : [];
    });

    suggestions.push(...higherVariants);
  }

  return suggestions;
};

const doesVariantMatchOptions = (
  variant: TVariantData,
  options: Partial<Record<string, string>>,
): boolean => {
  const variantOptions = Object.entries(variant.options);

  const matchingOptions = variantOptions.filter(([key, value]) => {
    return value === options[key];
  });

  return variantOptions.length === matchingOptions.length;
};

export const mapWizardActionToPayloadAction = (item: WizardAction): TSubmitRequestPayloadAction => {
  // map original item object
  const orig = item.initialItem;
  const line_item_id = orig.lineItemId;
  const variantIdDecoded = orig.variant.id;

  const original = {
    price: Math.floor(Number(orig.variant.price) * 100),
    product_id: orig.product.id ?? 0,
    product_title: orig.product.title,
    sku: orig.variant.sku,
    variant_id: variantIdDecoded,
    variant_title: orig.variant.title,
  };

  const exchange =
    item.action === 'exchange'
      ? {
          price: Math.floor(Number(item.exchangeFor.variant.price) * 100),
          product_id: item.exchangeFor.product.id,
          product_title: item.exchangeFor.product.title,
          sku: item.exchangeFor.variant.sku,
          variant_id: item.exchangeFor.variant.id,
          variant_title: item.exchangeFor.variant.title,
        }
      : null;

  const type = item.action === 'exchange' ? 'Exchange' : 'Return';

  return {
    ...(exchange === null ? null : { exchange }), // add the exchange object only if it's not null (type ==== Exchange)
    line_item_id,
    original,
    reasonIds: item.reasonIds,
    additionalReason: item.additionalReason,
    additionalComment: item.additionalComment,
    type,
  };
};
