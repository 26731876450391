import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { withFirebase } from '../../firebase/context';
import { Helmet } from 'react-helmet-async';
import { SummaryInstructions } from '../Summary/SummaryInstructions';
import { SummaryItemCard } from '../Summary/SummaryItemCard';
import { Notification } from '../Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useShippingLabel } from '../../hooks/useShippingLabel';
import { useRmaRequest } from '../../hooks/useRmaRequest';
import { getEstimatedProcessingTime } from '../../helpers/getEstimatedProcessingTime';
import { MultipleRefundsPossibleNotification } from '../MultipleRefundsPossibleNotification';
import moment from '../../helpers/localizedMoment';
import { localeMapping } from '../../contexts/AppTextsContext/localeMapping';
import { resizeImage } from '../../helpers/resizeImage';
import { useLocation } from 'react-router-dom';
import { interpolateAppText, useAppTexts } from '../../hooks/useAppTexts';
import { useLoadedFirebaseData } from '../../firebase/hooks';
import { appConfig } from '../../config';
import { TMakeLabelPaymentRequest } from '../../../../../functions/src/shared';

const DownloadLabel = () => {
  const [isLoading, setIsLoading] = useState(false);
  const appTexts = useAppTexts();
  const firebase = useLoadedFirebaseData();
  const { order, config, publicConfig: appThemeData, products, userData } = firebase;
  const { appTitle, supportEmail, language } = appThemeData;
  const location = useLocation();

  // Set moment locale based on domain language
  moment.locale(localeMapping(language));

  // get the params from URL
  const params = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    [location.search],
  );
  const { request: rmaRequestId } = params;

  const { rmaRequest, fetchRmaRequest } = useRmaRequest(rmaRequestId);
  // request the return label
  // within this call the validity of the signature will be checked
  const {
    paperless,
    isFetchingLabel,
    labelError,
    paidError,
    downloadLabel,
    fetchShippingLabel,
    shippingLabelUrl,
  } = useShippingLabel(rmaRequestId, true);

  const email = order?.email;
  const countryCode = order?.shippingAddress?.countryCode;
  const { carriers } =
    (config?.countrySettings?.[countryCode || 'default'] || config?.countrySettings?.['default']) ??
    {}; // 'default' for backwards compatibility
  const shippingLabelProvider = carriers?.[0].labelProvider;
  const estimatedProcessingTimeBusinessDays = getEstimatedProcessingTime(firebase);

  useEffect(() => {
    // get RMA Request
    if (!rmaRequest) {
      fetchRmaRequest();
    }
  }, [fetchRmaRequest, rmaRequest]);

  useEffect(() => {
    if (
      shippingLabelProvider &&
      rmaRequest &&
      !shippingLabelUrl &&
      !labelError &&
      !paidError &&
      !isFetchingLabel
    ) {
      fetchShippingLabel();
      downloadLabel({
        order: params.order,
        signature: params.signature,
        secret: params.secret,
        request: params.request,
      });
    }
  }, [
    fetchShippingLabel,
    isFetchingLabel,
    downloadLabel,
    shippingLabelProvider,
    rmaRequest,
    labelError,
    paidError,
    shippingLabelUrl,
    params.order,
    params.signature,
    params.secret,
    params.request,
  ]);

  const handleDownloadLabel = () => {
    const anchor = window.document.createElement('a');

    if (shippingLabelUrl === null) {
      throw new Error('shippingLabelUrl is null');
    }

    anchor.href = shippingLabelUrl;
    anchor.target = '_blank';
    anchor.click();
  };

  const handlePaymentRestart = async () => {
    setIsLoading(true);

    const payload: TMakeLabelPaymentRequest = {
      requestId: rmaRequestId,
    };

    try {
      // init loading
      const url = `${appConfig.cloudFunctionsUri}/CustomerSession/create-label-payment`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-shopify-rma-auth-token': userData.token ?? '',
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(url, options);
      if (response.status !== 200)
        throw new Error('Something went wrong while trying to restart the payment');

      const responseData = await response.json();

      // redirect
      window.location.replace(responseData.url);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // guard
  if (!rmaRequest) {
    return (
      <React.Fragment>
        {/** @ts-ignore see https://github.com/staylor/react-helmet-async/issues/182 */}
        <Helmet>
          <title>{`${appTitle} - ${appTexts.summary.pageTitle}`}</title>
        </Helmet>
        <div className="custom-loader is-loading"></div>
      </React.Fragment>
    );
  }

  // Items to send back
  const itemsToSendBack = rmaRequest.rmaItems?.map((item, i) => {
    const product = products?.find((product) => product.id === item.originalProductId);
    const variant = product ? product.variants[0] : null;
    const image = variant ? resizeImage(variant.image, 1200, 1200) : null;

    return (
      <SummaryItemCard
        key={`item-to-send-back-${i}`}
        productTitle={item.originalProductTitle ?? ''}
        options={product ? product.options : []}
        variantTitle={item.originalVariantTitle ?? ''}
        image={image}
        variants={product ? product.variants : []}
      />
    );
  });

  // Items to return
  const itemsToReceive =
    rmaRequest.rmaType !== 'return'
      ? rmaRequest.rmaItems
          ?.filter((item) => item.type === 'exchange')
          .map((item, i) => {
            const product = products?.find((product) => product.id === item.exchangeProductId);
            const variant = product ? product.variants[0] : null;
            const image = variant ? resizeImage(variant.image, 1200, 1200) : null;

            return (
              <SummaryItemCard
                key={`item-to-receive-${i}`}
                productTitle={item.exchangeProductTitle ?? ''}
                options={product ? product.options : []}
                variantTitle={item.exchangeVariantTitle ?? ''}
                image={image}
                variants={product ? product.variants : []}
              />
            );
          })
      : null;

  // set some values based on the RMA Request
  const isExchangeOrder = rmaRequest.exchangeOrderId !== null;
  const isThereRefund = (rmaRequest.rmaTotalProductReturnWorth ?? 0) > 0;

  const summaryMarkup = (
    <Fragment>
      {isThereRefund && isExchangeOrder && <MultipleRefundsPossibleNotification />}
      <div className="summary-block-wrap">
        <div className="summary-action-bar">
          {isFetchingLabel ? (
            <Fragment>
              <h2 className="summary-action-bar-title">{appTexts.summary.generatingLabel}</h2>
              <progress className="progress is-small is-primary" max="100"></progress>
            </Fragment>
          ) : (
            !paperless && (
              <Fragment>
                {shippingLabelUrl && !labelError && !paidError ? (
                  <Fragment>
                    <h2 className="summary-action-bar-title">{appTexts.summary.labelReady}</h2>
                    <button
                      className="button is-primary custom-button has-icon"
                      onClick={handleDownloadLabel}
                    >
                      <span>{appTexts.summary.downloadLabel}</span>
                      <FontAwesomeIcon icon="download" />
                    </button>
                  </Fragment>
                ) : (
                  <h2 className="summary-action-bar-title">
                    <Notification size="small" status="warning">
                      {paidError
                        ? interpolateAppText(appTexts.summary.labelNotPaid, {
                            supportEmail: supportEmail,
                          })
                        : interpolateAppText(appTexts.summary.noLabel, {
                            supportEmail: supportEmail,
                          })}
                    </Notification>
                    {paidError && (
                      <button
                        className={`button is-primary custom-button ${isLoading && 'is-loading'}`}
                        onClick={handlePaymentRestart}
                      >
                        <span>{appTexts.summary.restartPayment}</span>
                      </button>
                    )}
                  </h2>
                )}
              </Fragment>
            )
          )}
          {email && !labelError && !paidError && (
            <p className="summary-email-to">
              {paperless ? (
                <Fragment>
                  {appTexts.summary.mailWillBeSentToPaperless}
                  <span> {email}</span>
                  <br />
                  {appTexts.summary.mailWillBeSentToPaperlessSub}
                </Fragment>
              ) : shippingLabelUrl ? (
                <Fragment>
                  {appTexts.summary.mailWillBeSentTo}
                  <span> {email}</span>
                </Fragment>
              ) : (
                <Fragment>
                  {appTexts.summary.mailWillBeSentToOnlyInstructions}
                  <span> {email}</span>
                </Fragment>
              )}
            </p>
          )}
        </div>
        <div className="summary-howto">
          {estimatedProcessingTimeBusinessDays && (
            <div className="summary-howto-returntime">
              <span className="summary-howto-returntime-icon">
                <FontAwesomeIcon icon="info-circle" />
              </span>
              <p className="summary-howto-returntime-text">
                {interpolateAppText(appTexts.summary.estimatedProcessingTime, {
                  count: estimatedProcessingTimeBusinessDays.toString(),
                })}
              </p>
            </div>
          )}

          <h2 className="summary-howto-title">{appTexts.summary.howToTitle}</h2>
          <SummaryInstructions>
            {rmaRequest.return?.returnInstructionsHtml ?? ''}
          </SummaryInstructions>
        </div>

        {itemsToSendBack && (
          <div className="review-block">
            <h2 className="review-block-title">{appTexts.summary.itemsToSendBack}</h2>
            <div>{itemsToSendBack}</div>
          </div>
        )}

        {itemsToReceive && (
          <div className="review-block">
            <h2 className="review-block-title">{appTexts.summary.itemsToReceive}</h2>
            <div>{itemsToReceive}</div>
          </div>
        )}
      </div>
    </Fragment>
  );

  return (
    <React.Fragment>
      {/** @ts-ignore see https://github.com/staylor/react-helmet-async/issues/182 */}
      <Helmet>
        <title>{`${appTitle} - ${appTexts.summary.pageTitle}`}</title>
      </Helmet>
      <div className="items-list">
        <h1 className="items-list-title summary">{appTexts.summary.title}</h1>
        {summaryMarkup}
      </div>
    </React.Fragment>
  );
};

export default withFirebase(DownloadLabel);
