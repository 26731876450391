import React from 'react';

export const TimelineElement = ({ isFirst, isActive, IconComponent, title }) => {
  return (
    <div
      className={`timeline-element timeline-element--${isActive ? 'active' : 'inactive'} ${
        isFirst ? 'timeline-element--first' : ''
      }`}
    >
      <div className="timeline-element_icon">
        <IconComponent />
      </div>
      <p>{title}</p>
    </div>
  );
};
