import { LabelProvider } from "./TLabelProviders";

/** Option to present user to select carrier */
export type CarrierOption = TDefaultReturnOption | TBudbeeOption | TCarrieredOption;
export type TReturnOptionsResponse = {
  /** We for now prefill all info, but customer will do that later */
  options: SelectedCarrier[];
};

export type CarrierOptionCondition = "all" | "exchange" | "return";

type TCarrierOptionBase = {
  labelProvider: LabelProvider;
  condition: CarrierOptionCondition;
  /** null means no price is known */
  price: {
    amount: number;
    currency: string;
  } | null;
  label: string;
  descriptionHtml?: string;
  returnInstructionsHtml: string;
};

export type TDefaultSelectedReturnOption = TCarrierOptionBase & {
  labelProvider: "activeants" | "locallabel" | "transsmart" | "dpd";
};

// These options require extra info from the user after selection
export type TDefaultPreSelectedReturnOption = TCarrierOptionBase & {
  labelProvider: "monta";
};

export type TDefaultReturnOption = TDefaultSelectedReturnOption | TDefaultPreSelectedReturnOption;

const methodBasedLabelProviders = ["budbee"] as const;

export const isMethodBasedReturnOption = <T extends { labelProvider: string }>(
  option: T,
): option is Extract<T, { method: string }> =>
  methodBasedLabelProviders.includes(option.labelProvider as any);

// Budbee return option
export type TBudbeePickupOption = TCarrierOptionBase & {
  labelProvider: "budbee";
  method: "budbee-pickup";
};

export type TBudbeeDropoffOption = TCarrierOptionBase & {
  labelProvider: "budbee";
  method: "budbee-dropoff";
};

export type TBudbeeOption = TBudbeePickupOption | TBudbeeDropoffOption;

const carrierBasedLabelProviders = ["monta", "sendcloud"] as const;

export const isCarrierSelectableInAdminUI = <T extends { labelProvider: string }>(
  option: T,
): option is Extract<T, { carrierId: string }> =>
  carrierBasedLabelProviders.filter((c) => c !== "monta").includes(option.labelProvider as any);

export const isCarrierBasedReturnOption = <T extends { labelProvider: string }>(
  option: T,
): option is Extract<T, { carrierId: string }> => {
  return carrierBasedLabelProviders.includes(option.labelProvider as any);
};

// Monta return option
export type TCarrieredOption = TCarrierOptionBase & {
  labelProvider: "sendcloud";
  carrierId: string;
};

export type SelectedBudbeePickup = TBudbeePickupOption & {
  phoneNumber?: string;
  estimatedPickupTime?: { start: string; stop: string };
  fossilFree: boolean;
};

type SelectedBudbeeOption = SelectedBudbeePickup | TBudbeeDropoffOption;

/** Holds all data needed to make a label provider request */
export type SelectedCarrier = (
  | TDefaultSelectedReturnOption
  | SelectedBudbeeOption
  | SelectedMontaOption
  | SelectedSendcloudOption
) & {
  address: string;
};

export type SelectedMontaOption = TCarrierOptionBase & {
  labelProvider: "monta";
  carrierId: number;
  carrierCode: string;
  isPaperless: boolean;
};

export type SelectedSendcloudOption = TCarrierOptionBase & {
  labelProvider: "sendcloud";
  carrierId: string;
  addressObject: {
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    zipCode: string;
    country: string;
  };
  streetAddress?: {
    streetName: string;
    streetNumber: string;
  };
  properties?: {
    min_weight: number;
    max_weight: number;
  };
};

export const getCarrierOptionKey = (option: CarrierOption): string => {
  const labelProviderUniqueness = getShippingMethod(option) ?? "";

  return `${option.labelProvider}-${labelProviderUniqueness}`;
};

/** Returns a string unique to the labelProvider or null in case there is only 1 option for the labelprovider */
export const getShippingMethod = (option: CarrierOption): string | null => {
  if (isMethodBasedReturnOption(option)) {
    return option.method;
  }

  if (isCarrierBasedReturnOption(option)) {
    return option.carrierId;
  }

  return null;
};
