import React, { useMemo } from 'react';
import { Reason } from '../../../../../../functions/src/shared';
import { pseudo } from '../../../helpers/pseudo';
import WizardSelector from './WizardSelector';

interface Props {
  reasons: Reason[];
  onSelect: (data: Reason) => void;
  isRandomized?: boolean;
}

const PickReason = ({ reasons, onSelect, isRandomized }: Props) => {
  const options = useMemo(
    () => (isRandomized ? pseudo(reasons) : reasons),
    [reasons, isRandomized],
  );

  return (
    <div className="item-wizard-content-body">
      <WizardSelector
        options={options.map((option, i) => {
          return {
            label: option.label,
            value: option,
          };
        })}
        onSelect={onSelect}
      />
    </div>
  );
};

export default PickReason;
