import React from 'react';
import { QuickSelect, wildcardToRegex } from '../../../../../../functions/src/shared';
import {
  ExchangeWizardItem,
  findPossibleNeighboursSorted,
  FullWizardItem,
  makeExchangeWizardItem,
} from '../../../contexts/WizardContext/WizardContext';
import { useLoadedFirebaseData } from '../../../firebase/hooks';
import { useAppTexts } from '../../../hooks/useAppTexts';
import PickByTag from './PickByTag';
import WizardSelector from './WizardSelector';
import { getInventoryQuantityByLocationId } from '../../../helpers/getInventoryByLocation';

interface Props {
  itemData: FullWizardItem;
  quickSelects: QuickSelect[];
  onSelect: (data: ExchangeWizardItem) => void;
  /** If something non-critical fails we call this function to let parent know Quick Select unavailable. Might be called multiple times. */
  onMoreOptions: () => void;
}

export const QuickSelectPicker = ({ itemData, quickSelects, onSelect, onMoreOptions }: Props) => {
  const appTexts = useAppTexts();
  const {
    config: { inventoryThreshold, useOverallInventory, selectedInventoryLocations },
  } = useLoadedFirebaseData();

  return (
    <div className="item-wizard-content-body">
      {quickSelects.map((quickSelect, index) => {
        return (
          <SingleQuickSelectOptions
            key={index}
            {...{
              quickSelect,
              itemData,
              onSelect,
              inventoryThreshold,
              useOverallInventory: useOverallInventory !== false,
              selectedInventoryLocations: selectedInventoryLocations || [],
            }}
          />
        );
      })}
      <button className="button more-options-button" onClick={onMoreOptions}>
        {appTexts.itemWizard.showMoreOptions}
      </button>
    </div>
  );
};

const SingleQuickSelectOptions = ({
  quickSelect,
  itemData,
  onSelect,
  inventoryThreshold,
  useOverallInventory,
  selectedInventoryLocations,
}: {
  quickSelect: QuickSelect;
  itemData: FullWizardItem;
  onSelect: (data: ExchangeWizardItem) => void;
  inventoryThreshold: number | undefined;
  useOverallInventory: boolean;
  selectedInventoryLocations: {
    id: number;
    name: string;
  }[];
}) => {
  const appTexts = useAppTexts();

  if (quickSelect.type === 'same-product') {
    return (
      <>
        {quickSelect.headerCopy?.length ? (
          <h2 className="quickselect-header">{quickSelect.headerCopy}</h2>
        ) : (
          <></>
        )}
        <WizardSelector
          options={[
            {
              label: appTexts.itemWizard.sendSameProduct,
              subText: `${itemData.product.title} - ${itemData.variant.title}`,
              value: itemData,
            },
          ]}
          onSelect={onSelect}
        />
      </>
    );
  } else if (quickSelect.type === 'neighboring-option') {
    const alternatives = findPossibleNeighboursSorted(itemData, quickSelect);

    return (
      <>
        {quickSelect.headerCopy?.length ? (
          <h2 className="quickselect-header">{quickSelect.headerCopy}</h2>
        ) : (
          <></>
        )}
        <WizardSelector
          options={alternatives.map(({ variant, copy }) => {
            // check if available and inventory quantity is equal or higher than threshold
            let isAvailable = false;
            if (useOverallInventory || useOverallInventory === undefined) {
              isAvailable =
                variant.inventory_quantity >= (inventoryThreshold ?? 0) ||
                variant.inventory_policy === 'continue';
            } else {
              isAvailable =
                getInventoryQuantityByLocationId(
                  (selectedInventoryLocations || []).map((location) => location.id),
                  variant.inventory_at_location || {},
                ) > (inventoryThreshold ?? 0) || variant.inventory_policy === 'continue';
            }

            return {
              label: copy,
              subText: `${itemData.product.title} - ${variant.title}`,
              value: makeExchangeWizardItem(itemData, variant),
              disabled: !isAvailable,
              chevronOverride: !isAvailable && (
                <span className="tag is-danger">{appTexts.itemWizard.outOfStock}</span>
              ),
            };
          })}
          onSelect={onSelect}
        />
      </>
    );
  } else if (quickSelect.type === 'same-tag') {
    const regexp = wildcardToRegex(quickSelect.tagWithWildcard);
    const relevantTag = itemData.product.tags.find((tag) => tag.match(regexp));

    if (!relevantTag) {
      throw new Error(
        `Failed to find tag ${quickSelect.tagWithWildcard}. Only render this component if tag exists.`,
      );
    }

    return (
      <div>
        {quickSelect.headerCopy?.length ? (
          <h2 className="quickselect-header">{quickSelect.headerCopy}</h2>
        ) : (
          <></>
        )}
        <PickByTag
          mode="pick-variant"
          frozenOptions={quickSelect.frozenOptions ?? []}
          itemData={itemData}
          tag={relevantTag}
          onSubmit={(data) => {
            onSelect(data);
          }}
        />
      </div>
    );
  }

  // Shouldn't happen but we don't want to crash the app
  return null;
};
