import React, { useState } from 'react';
import { Notification } from '../../Notification';
import { NoImageFound } from '../../NoImageFound';
import { getCurrencyFormat } from '../../../helpers/getCurrencyFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCollapse from 'react-collapsed';
import { resizeImage } from '../../../helpers/resizeImage';
import { useAppTexts } from '../../../hooks/useAppTexts';
import { useLoadedFirebaseData } from '../../../firebase/hooks';
import { useOrderData } from '../../../hooks/useOrderData';
import {
  CollectedWizardData,
  ExchangeAction,
  ExchangeWizardItem,
  FullWizardItem,
  WizardContext,
  mapWizardActionToPayloadAction,
} from '../../../contexts/WizardContext/WizardContext';
import {
  GlobalConfig,
  TProductData,
  TextFilter,
  deriveOrder,
  deriveRootOrder,
  getExchangeValues,
} from '../../../../../../functions/src/shared';
import { useProductsByTag } from './useProductsByTag';
import { createRmaItems } from '../../Review/submitReturn';
import { useNonNullableContext } from '../../../hooks/useNonNullableContext';
import { getInventoryQuantityByLocationId } from '../../../helpers/getInventoryByLocation';

interface Props {
  itemData: FullWizardItem;
  collections: TextFilter[];
  onSelect: (item: TProductData) => void;
}

const ExchangeCollection = ({ itemData, collections, onSelect }: Props) => {
  const appTexts = useAppTexts();
  const firebase = useLoadedFirebaseData();

  const { config, publicConfig: appThemeData } = firebase;
  const {
    order: { currency },
  } = useOrderData();
  const { language } = appThemeData;

  const collectionsMarkup = collections.map((collection, i) => {
    return (
      <Collection
        key={i}
        collection={collection}
        itemData={itemData}
        onSelect={onSelect}
        currency={currency}
        globalConfig={config}
        language={language}
        userToken={firebase.userData.token ?? ''}
      />
    );
  });

  const stepInfoMarkup = appTexts.itemWizard && appTexts.itemWizard.otherCollectionInfoTop && (
    <Notification size="small">
      <div dangerouslySetInnerHTML={{ __html: appTexts.itemWizard.otherCollectionInfoTop }}></div>
    </Notification>
  );

  return (
    <div className="item-wizard-content-body">
      {stepInfoMarkup}
      {collectionsMarkup}
    </div>
  );
};

interface NewItemData {
  isSelectable: boolean;
  exchangeFor: TProductData;
  variantImage: string | null;
  productTitle: string;
  isOutOfStock: boolean;
  isPriceTooHigh: boolean;
  parsedVariantPrice: number;
  priceDifference: number;
}

const Collection = ({
  collection,
  itemData,
  onSelect,
  currency,
  globalConfig,
  language,
  userToken,
}: {
  collection: TextFilter;
  itemData: FullWizardItem;
  onSelect: (itemData: TProductData) => void;
  currency: string;
  globalConfig: GlobalConfig;
  language: string;
  userToken: string;
}) => {
  const appTexts = useAppTexts();

  const [isOpen, setOpen] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded: isOpen });
  const { order } = useLoadedFirebaseData();
  const { collectedWizardData } = useNonNullableContext(WizardContext);

  const [productData, isLoading] = useProductsByTag(collection.filterValue, userToken);
  const { allowAdditionalPayment, inventoryThreshold, inventoryThresholdHideBelow } = globalConfig;

  // let's reduce the results so we can filter and map in one go
  const optionsData =
    productData &&
    productData.reduce<NewItemData[]>((accumulator, product): NewItemData[] => {
      const productTitle = product.title;
      const { variants } = product;

      if (variants.length === 0) {
        return accumulator;
      }

      // include only if the variant is equal to that of the starting item
      const firstVariant = variants[0];

      // define selected step data
      const exchangeFor: ExchangeWizardItem = {
        product,
        variant: firstVariant,
      };

      const newAction: ExchangeAction = {
        action: 'exchange',
        initialItem: itemData,
        exchangeFor,
      };

      const draftNewRma: CollectedWizardData = {
        ...collectedWizardData,
        [itemData.selectionId]: newAction,
      };

      const rmaItems = createRmaItems(draftNewRma);
      const currentState = deriveRootOrder(order);
      const nextState = deriveOrder(currentState, rmaItems);

      const variantImage = resizeImage(firstVariant.image, 1200, 1200);

      const { originalExchangeValue, exchangeDiscountedPrice } = getExchangeValues(
        currentState,
        nextState,
        mapWizardActionToPayloadAction(newAction),
        {
          exchangeProductId: product.id,
          exchangeProductTags: product.tags,
          exchangeVariantCurrentPrice: Number(firstVariant.price),
          exchangeVariantId: firstVariant.id,
          originalDiscountedPrice: itemData.discountedPrice ?? itemData.originalPrice,
          originalPrice: itemData.originalPrice,
          originalProductId: itemData.product.id,
          originalProductTags: itemData.product.tags,
          originalVariantCurrentPrice: itemData.currentVariantPrice,
          originalVariantId: itemData.variant.id,
        },
        globalConfig,
      );

      // check if we're out of stock; considering also the inventoryThreshold value
      const isOutOfStock = !variants.find((variant) => {
        if (globalConfig.useOverallInventory || globalConfig.useOverallInventory === undefined) {
          return (
            variant.inventory_quantity >= (inventoryThreshold ?? 0) ||
            variant.inventory_policy === 'continue'
          );
        } else {
          return (
            getInventoryQuantityByLocationId(
              (globalConfig.selectedInventoryLocations || []).map((location) => location.id),
              variant.inventory_at_location || {},
            ) > (inventoryThreshold ?? 0) || variant.inventory_policy === 'continue'
          );
        }
      });

      const parsedVariantPrice = Math.floor(exchangeDiscountedPrice * 100);
      const parsedInitPrice = Math.floor(originalExchangeValue * 100);
      const isPriceTooHigh = parsedVariantPrice > parsedInitPrice;
      const priceDifference = isPriceTooHigh ? parsedVariantPrice - parsedInitPrice : 0;
      const isSelectable = !((!allowAdditionalPayment && isPriceTooHigh) || isOutOfStock);

      const newItemData: NewItemData = {
        isSelectable,
        exchangeFor: product,
        variantImage,
        productTitle,
        isOutOfStock,
        isPriceTooHigh,
        parsedVariantPrice,
        priceDifference,
      };

      return [...accumulator, newItemData];
    }, []); // empty array as 'initialValue' of the reducer

  const uniqueDiffs =
    optionsData
      ?.map(({ priceDifference }) => priceDifference)
      .filter((val, index, self) => {
        return self.indexOf(val) === index;
      }) ?? [];

  const collectionPriceDifference = {
    value: Math.min(...uniqueDiffs),
    show: uniqueDiffs.length > 0 && uniqueDiffs.indexOf(0) < 0,
    hasMultiple: uniqueDiffs.length > 1,
  };

  const optionsMarkup = optionsData?.map(
    (
      {
        isSelectable,
        variantImage,
        exchangeFor,
        productTitle,
        isOutOfStock,
        isPriceTooHigh,
        parsedVariantPrice,
        priceDifference,
      },
      i,
    ) =>
      isOutOfStock && inventoryThresholdHideBelow ? undefined : (
        <div
          key={`collection-option-${i}`}
          className="column is-half-mobile is-half-tablet is-one-third-desktop"
        >
          <div
            className={`card item-wizard-card ${!isSelectable ? 'is-disabled' : ''}`}
            onClick={
              isSelectable
                ? () => {
                    onSelect(exchangeFor);
                  }
                : undefined
            }
          >
            <div className="item-wizard-card-image">
              {variantImage ? <img src={variantImage} alt={productTitle} /> : <NoImageFound />}
              {isOutOfStock && (
                <div className="item-wizard-card-outofstock">
                  <span className="tag is-danger">{appTexts.itemWizard.outOfStock}</span>
                </div>
              )}
            </div>
            <footer className="item-wizard-card-footer">
              <div className="item-wizard-card-title">{productTitle}</div>
              <div
                className={`item-wizard-card-price ${
                  !allowAdditionalPayment && isPriceTooHigh ? 'is-too-high' : ''
                }`}
              >
                <span>{getCurrencyFormat(parsedVariantPrice / 100, currency, language)}</span>
                {allowAdditionalPayment && priceDifference > 0 && (
                  <span className="tag is-primary">
                    + {getCurrencyFormat(priceDifference / 100, currency, language)}
                  </span>
                )}
              </div>
            </footer>
          </div>
        </div>
      ),
  );

  const loadingMarkup = <div className="custom-loader is-loading"></div>;

  return (
    <React.Fragment>
      <h2
        className="button is-fullwidth item-wizard-option"
        {...getToggleProps({
          onClick: () => setOpen((oldOpen) => !oldOpen),
        })}
      >
        <div className={isLoading ? 'is-loading' : ''}>{collection.label}</div>
        {collectionPriceDifference?.show && (
          <span className="tag is-primary item-wizard-option-add-to-price">
            {collectionPriceDifference.hasMultiple && appTexts.itemWizard.from + ' '}+{' '}
            {getCurrencyFormat(collectionPriceDifference.value / 100, currency, language)}
          </span>
        )}
        <FontAwesomeIcon
          icon="chevron-down"
          rotation={isOpen ? 180 : undefined}
          className="item-wizard-collapsible-icon"
        />
      </h2>
      <div {...getCollapseProps()}>
        <div className="item-wizard-collapsible-body">
          {isLoading ? (
            loadingMarkup
          ) : (
            <React.Fragment>
              <div className="columns is-mobile is-multiline">{optionsMarkup}</div>
              <Notification size="small" hide={optionsMarkup && optionsMarkup.length > 0}>
                <p>{appTexts.itemWizard.noProductFound}</p>
              </Notification>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExchangeCollection;
