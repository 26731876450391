Object.defineProperty(exports, "__esModule", { value: true });
exports.shuffleArray = exports.randomize = void 0;
function randomize(chunks) {
    const grouped = chunks
        .map((chunk) => {
        const { randomize } = chunk[0];
        if (randomize)
            return shuffleArray(chunk);
        else
            return chunk;
    })
        .reduce((carry, chunk) => {
        const { randomize } = chunk[0];
        if (randomize) {
            const groupedRandomChunk = 
            // Group items within their respective chunks
            shuffleArray(Object.values(chunk.reduce((groups, item) => {
                const { group = makeid(10) } = item;
                if (groups[group] === undefined)
                    groups[group] = [item];
                else
                    groups[group].push(item);
                return groups;
            }, {})))
                // Shuffle within the group
                .map((group) => {
                return shuffleArray(group);
            })
                // Bring the deep array back to a single depth array where
                // all items are in a pseudo random order.
                .flat();
            carry.push(groupedRandomChunk);
        }
        else {
            carry.push(chunk);
        }
        return carry;
    }, [])
        .flat();
    return grouped;
}
exports.randomize = randomize;
/**
 * As per https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array.
 */
function shuffleArray(array, seed = new Date().valueOf().toString()) {
    const hash = cyrb128(seed);
    const rand = sfc32(hash[0], hash[1], hash[2], hash[3]);
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(rand() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
exports.shuffleArray = shuffleArray;
/**
 *
 * @param seed Seed string
 * @returns Good seed to use for PRNGs
 */
function cyrb128(seed) {
    let h1 = 1779033703, h2 = 3144134277, h3 = 1013904242, h4 = 2773480762;
    for (let i = 0, k; i < seed.length; i++) {
        k = seed.charCodeAt(i);
        h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
        h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
        h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
        h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
    }
    h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
    h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
    h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
    h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
    return [
        (h1 ^ h2 ^ h3 ^ h4) >>> 0,
        (h2 ^ h1) >>> 0,
        (h3 ^ h1) >>> 0,
        (h4 ^ h1) >>> 0,
    ];
}
/**
 * Random number generator.
 * @returns A function which can be called repeatedly.
 */
function sfc32(a, b, c, d) {
    return function () {
        a >>>= 0;
        b >>>= 0;
        c >>>= 0;
        d >>>= 0;
        var t = (a + b) | 0;
        a = b ^ (b >>> 9);
        b = (c + (c << 3)) | 0;
        c = (c << 21) | (c >>> 11);
        d = (d + 1) | 0;
        t = (t + d) | 0;
        c = (c + t) | 0;
        return (t >>> 0) / 4294967296;
    };
}
function makeid(length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
