import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useFirebase } from '../../firebase/context';
import { ColorUpdater } from 'bulma-css-vars';
import { bulmaCssVariablesDefs } from '../../styles/bulma-generated/bulma-colors';
import { useUnsafeInjectHtmlIntoHeadEffect } from './unsafeInjectHtmlIntoHead';

const Head = () => {
  const { publicConfig } = useFirebase();
  const { colors, fonts, favicon, background, css, overlayOpacity } = publicConfig?.theme ?? {};

  useUnsafeInjectHtmlIntoHeadEffect(publicConfig?.headInjectedHtml ?? '');

  useEffect(() => {
    // Update color custom css properties
    const colorUpdater = new ColorUpdater(bulmaCssVariablesDefs as any); // typescript didn't seem to like this

    for (const [name, color] of Object.entries(colors ?? {})) {
      const updatedColors = colorUpdater.getUpdatedVars(name, color.value);
      updatedColors.forEach(({ name, value }) => {
        document.documentElement.style.setProperty(name, value);
      });
    }

    // Update font families custom css properties
    for (const [name, font] of Object.entries(fonts ?? {})) {
      document.documentElement.style.setProperty(`--app-font-family--${name}`, font.value);
    }
  }, [colors, fonts]);

  const defaultFontFamily =
    'BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif';

  const themeStyles = `
    :root {
      --app-font-family--primary: ${defaultFontFamily};
      --app-font-family--secondary: ${defaultFontFamily};
    }
    html {
      background-image: url(${background ?? null});
    }
    html::before {
      background-color: rgba(0, 0, 0, ${overlayOpacity ?? 0.5});
    }
    ${css ? css : ''}
  `;

  const faviconIco = favicon?.ico ?? null;
  const faviconPng = favicon?.png ?? null;
  const appTitle = publicConfig?.appTitle ?? '';

  return (
    /** @ts-ignore see https://github.com/staylor/react-helmet-async/issues/182 */
    <Helmet>
      <title>{appTitle}</title>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content={`${appTitle} - Return or exchange your purchased products.`}
      />
      <style type="text/css">{themeStyles}</style>
      {faviconIco && <link rel="shortcut icon" href={faviconIco} />}
      {faviconIco && <link rel="icon" sizes="16x16 32x32 64x64" href={faviconIco} />}
      {faviconPng && <link rel="apple-touch-icon" href={faviconPng} />}
    </Helmet>
  );
};

export default Head;
