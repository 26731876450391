import { SfyDiscountApplicationType } from "../sfy_order_types";
import { LineItemDiscountProvider } from "./LineItemDiscountsProvider";

export const calculateLineItemPrice = (
  /** The price before any discounts */
  basePrice: number,
  discounts: SfyDiscountApplicationType[],
  lineItemDiscountProvider: LineItemDiscountProvider,
) => {
  return discounts.reduce((acc, discount) => {
    switch (discount.value_type) {
      case "fixed_amount":
        return acc - Number(discount.value);
      case "percentage": {
        const discountBase =
          combinationMethod[lineItemDiscountProvider] === "add" ? basePrice : acc;
        // Shopify discount percentages are 100-based
        return acc - discountBase * (Number(discount.value) / 100);
      }
    }
  }, basePrice);
};

/**
 * @example
 * add       -> 10% + 25% = 35%   -> 0.1 + 0.25 = 0.35
 * multiply  -> 10% + 25% = 32.5% -> 1 - (1 - 0.1) * (1 - 0.25) = 0.325
 */
const combinationMethod: Record<LineItemDiscountProvider, "add" | "multiply"> = {
  agradi: "multiply",
};
