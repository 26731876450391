import React from 'react';
import { Helmet } from 'react-helmet-async';
import ItemsListUnsubmitted from './ItemsListUnsubmitted';
import { useAppTexts } from '../../hooks/useAppTexts';
import { useLoadedFirebaseData } from '../../firebase/hooks';

const ItemsList = () => {
  const { publicConfig: appThemeData, order } = useLoadedFirebaseData();
  const appTexts = useAppTexts();

  return (
    <React.Fragment>
      {/** @ts-ignore see https://github.com/staylor/react-helmet-async/issues/182 */}
      <Helmet>
        <title>{`${appThemeData.appTitle} - ${appTexts.itemsList.pageTitle} ${order?.name}`}</title>
      </Helmet>
      <ItemsListUnsubmitted />
    </React.Fragment>
  );
};

export default ItemsList;
