import React from 'react';
import { useFirebase } from '../firebase/context';
import { Route, Redirect, RouteComponentProps, RouteProps } from 'react-router-dom';

type Props = {
  component: React.ComponentType<RouteComponentProps>;
} & RouteProps;

export const ProtectedRoute = ({ component: Component, ...rest }: Props) => {
  const { auth, loadedSessionData } = useFirebase();

  if (!auth.currentUser) {
    return <Redirect to="/" />;
  }

  if (!loadedSessionData) {
    return <div className="custom-loader is-loading is-center-page"></div>;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
