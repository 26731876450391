import { Context, useContext } from 'react';

export const useNonNullableContext = <T extends {}>(ctxt: Context<T | null>) => {
  const value = useContext(ctxt);

  if (!value) {
    throw new Error(`Context value is null! Please initialize the context first.`);
  }

  return value;
};
