/**
 * Someone decided that `dk` is not longer the way to
   refer to the Danish language. Therefore we need this
   util.
 * @returns Moment JS locale.
 */
export const localeMapping = (locale: string) => {
  if (locale === 'dk') return 'da';
  return locale;
};
