import { useCallback, useState } from 'react';
import { BaseRmaRequest, TDownloadLabelUrlRequest } from '../../../../functions/src/shared';
import { useFirebase } from '../firebase/context';
import { useSessionData } from '../firebase/hooks';
import { appConfig } from '../config';

const euCountryCodes = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
];

export const useCommercialInvoice = (rmaRequestId: string) => {
  const [isCommercialInvoiceRequired, setIsCommercialInvoiceRequired] = useState(false);
  const [isFetchingCommercialInvoice, setIsFetchingCommercialInvoice] = useState(false);
  const [commercialInvoiceError, setCommercialInvoiceError] = useState(false);
  const [commercialInvoiceUrl, setCommercialInvoiceUrl] = useState<null | string>(null);
  const { rmaRequestDoc } = useFirebase();
  const { userData } = useSessionData();

  const fetchCommercialInvoiceUrl = useCallback(async () => {
    setIsFetchingCommercialInvoice(true);

    // Start listening to rmaRequestDoc changes
    const unsubscribeDocListener = rmaRequestDoc(rmaRequestId).onSnapshot(
      (doc) => {
        if (!doc.exists) {
          throw new Error(`No rmaRequest document with id ${rmaRequestId} found.`);
        }

        const { rmaCommercialInvoice, orderShippingCountryCode } = doc.data() as BaseRmaRequest;

        if (euCountryCodes.includes(orderShippingCountryCode || '')) {
          setIsCommercialInvoiceRequired(false);
          setIsFetchingCommercialInvoice(false);
          return;
        }

        setIsCommercialInvoiceRequired(true);

        if (
          rmaCommercialInvoice &&
          (!Array.isArray(rmaCommercialInvoice) || rmaCommercialInvoice.length > 0) &&
          commercialInvoiceUrl === null
        ) {
          fetchCommercialInvoiceUrl();
          unsubscribeDocListener();
        }
      },
      (error) => {
        console.log(error);
        setCommercialInvoiceError(true);
        setIsFetchingCommercialInvoice(false);

        // Detach firestore listener
        unsubscribeDocListener();
      },
    );

    const fetchCommercialInvoiceUrl = async () => {
      const payload: TDownloadLabelUrlRequest = {
        requestId: rmaRequestId,
        labelIndex: 0,
      };

      const url = `${appConfig.cloudFunctionsUri}/CustomerSession/download-commercial-invoice-url`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-shopify-rma-auth-token': userData.token ?? '',
        },
        body: JSON.stringify(payload),
      };

      try {
        const response = await fetch(url, options);

        switch (response.status) {
          case 200:
            const responseData = await response.json();
            setCommercialInvoiceUrl(responseData.url);
            break;
          default:
            setCommercialInvoiceError(true);
        }
      } catch (error) {
        console.error(
          'Error fetching from /CustomerSession/download-commercial-invoice-url: ',
          error,
        );
        setCommercialInvoiceError(true);
      } finally {
        setIsFetchingCommercialInvoice(false);
      }
    };
  }, [rmaRequestId, commercialInvoiceUrl, userData.token, rmaRequestDoc]);

  return {
    isCommercialInvoiceRequired,
    isFetchingCommercialInvoice,
    setIsFetchingCommercialInvoice,
    setCommercialInvoiceError,
    commercialInvoiceError,
    fetchCommercialInvoiceUrl,
    commercialInvoiceUrl,
  };
};
