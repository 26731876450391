import { useCallback, useEffect, useState } from 'react';
import WebFont from 'webfontloader';
import { Firebase } from '../firebase/firebase';
import { getShopifyDomain } from '../helpers/getShopifyDomain';
import { PublicConfig } from '../../../../functions/src/shared';

const isUsingEmulators = process.env.REACT_APP_FIREBASE_EMULATORS === 'true';
const isDevEnvironment = process.env.REACT_APP_STAGE === 'dev';

const getDomain = () => {
  const isBareLocalhost =
    window.location.host.includes('localhost') &&
    ['/', '/exchange', '/review', '/summary', '/download-label'].includes(window.location.pathname);

  if (isDevEnvironment && isBareLocalhost) {
    return 'code-rma-app-dev.web.app';
  }

  if (isUsingEmulators) {
    return 'demo.returns-exchanges-stage.code.nl';
  }

  return window.location.host;
};

export const usePublicConfig = (firebase: Firebase) => {
  const [publicConfig, setPublicConfig] = useState<PublicConfig>();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchInitialConfig = useCallback(async (): Promise<{
    sfyDomain: string;
    publicConfig: PublicConfig;
    hasCustomDomain: boolean;
  } | null> => {
    // Get domain specific public data first
    const domain = getDomain();
    const domainDoc = await firebase.domain(domain).get();
    const hasCustomDomain = domainDoc.exists;

    const domainData = domainDoc.data();

    // get the Shopify domain
    const sfyDomain = domainData?.sfyDomain ?? getShopifyDomain();

    // get the theme settings
    const publicConfigDoc = await firebase.publicConfigRef(sfyDomain).get();

    // set visual and textual app settings
    const publicConfig = publicConfigDoc.data() as PublicConfig | undefined;

    // only set when shop exists in our system.
    // Based on the shop data a 404 is shown in case it's not a match
    if (!publicConfig) {
      return null;
    }

    // Load necessary fonts if they have been defined in the db
    const { fonts } = publicConfig.theme;
    if (Object.keys(fonts).length > 0) loadRequiredWebFonts(fonts);

    return { sfyDomain, publicConfig, hasCustomDomain };
  }, [firebase]);

  const savePublicConfig = useCallback((data: PublicConfig) => {
    // Prepend the prefix so we get full urls
    if (data.theme?.background)
      data.theme.background = `${process.env.REACT_APP_ASSET_URI}${data.theme.background}`;

    if (data.theme?.logo) data.theme.logo = `${process.env.REACT_APP_ASSET_URI}${data.theme.logo}`;

    if (data.theme?.favicon?.png)
      data.theme.favicon.png = `${process.env.REACT_APP_ASSET_URI}${data.theme.favicon.png}`;

    if (data.theme?.favicon?.ico)
      data.theme.favicon.ico = `${process.env.REACT_APP_ASSET_URI}${data.theme.favicon.ico}`;

    setPublicConfig(data);
  }, []);

  useEffect(() => {
    const fetchConfig = async () => {
      setIsLoading(true);

      try {
        const config = await fetchInitialConfig();

        if (!config) {
          setIsLoading(false);
          return;
        }

        const { sfyDomain, publicConfig, hasCustomDomain } = config;

        firebase._hasCustomDomain = hasCustomDomain;
        firebase.setShopName(sfyDomain);
        savePublicConfig(publicConfig);
      } catch (error) {
        if (!(error instanceof Error)) {
          throw error;
        }

        setError(error);
      }

      setIsLoading(false);
    };

    fetchConfig();
  }, [fetchInitialConfig, firebase, savePublicConfig]);

  return [publicConfig, isLoading, error] as const;
};

const loadRequiredWebFonts = (
  fonts: Record<
    string,
    {
      provider: string;
      value: string;
    }
  >,
) => {
  const webFontsToLoad: Record<string, { families: string[] }> = {};

  for (const [, font] of Object.entries(fonts)) {
    const { provider, value } = font;
    if (!webFontsToLoad[provider]) {
      webFontsToLoad[provider] = { families: [] };
    }

    const carriedFamilies = webFontsToLoad[provider].families;
    webFontsToLoad[provider].families = [...carriedFamilies, value];
  }

  return WebFont.load(webFontsToLoad);
};
