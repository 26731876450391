import { useMemo } from 'react';

import { CheckIcon } from './Icons/CheckIcon';
import { TruckIcon } from './Icons/TruckIcon';
import { StoreIcon } from './Icons/StoreIcon';
import { PlusIcon } from './Icons/PlusIcon';
import { TrackingStatus } from '../../../../../functions/src/shared';
import { useAppTexts } from '../../hooks/useAppTexts';

export const useTimelineElements = (
  trackingStatus: TrackingStatus | null,
  hasAftershipTracking: boolean,
) => {
  const appTexts = useAppTexts();

  return useMemo(() => {
    const submittedElement = {
      IconComponent: PlusIcon,
      title: appTexts.tracking.status.submitted,
      isActive: true, // The user only sees the timeline if they've submitted an RMA
      key: 'submitted-element',
    };

    const droppedOffElement = {
      IconComponent: StoreIcon,
      title: appTexts.tracking.status.droppedOff,
      isActive: isAtOrPastDroppedOff(trackingStatus), // there's no specific state for 'dropped-off' for now, so we should copy inTransitElement
      key: 'dropped-off-element',
    };

    const inTransitElement = {
      IconComponent: TruckIcon,
      title: appTexts.tracking.status.inTransit,
      isActive: isAtOrPastInTransit(trackingStatus),
      key: 'in-transit-element',
    };

    const deliveredElement = {
      IconComponent: CheckIcon,
      title: appTexts.tracking.status.delivered,
      isActive: trackingStatus === TrackingStatus.Delivered,
      key: 'deliveredElement',
    };

    if (hasAftershipTracking) {
      return [submittedElement, droppedOffElement, inTransitElement, deliveredElement];
    } else {
      return [submittedElement, deliveredElement];
    }
  }, [appTexts, trackingStatus, hasAftershipTracking]);
};

const isAtOrPastDroppedOff = (trackingStatus: TrackingStatus | null) =>
  trackingStatus === TrackingStatus.DroppedOff || isAtOrPastInTransit(trackingStatus);

const isAtOrPastInTransit = (trackingStatus: TrackingStatus | null) =>
  trackingStatus === TrackingStatus.InTransit || trackingStatus === TrackingStatus.Delivered;
