import React from 'react';
import { useFirebase } from '../firebase/context';
import { WizardContext } from '../contexts/WizardContext/WizardContext';
import SignOut from './SignOut';
import { interpolateAppText, useAppTexts } from '../hooks/useAppTexts';
import { useNonNullableContext } from '../hooks/useNonNullableContext';

const Footer = () => {
  const firebase = useFirebase();
  const appTexts = useAppTexts();
  const { nrOfSelectedItems } = useNonNullableContext(WizardContext);
  const { supportEmail, privacyPolicyUrl } = firebase.publicConfig ?? {};

  return (
    <div className={`footer ${nrOfSelectedItems > 0 ? 'has-expanded-margin' : ''}`}>
      <div>{interpolateAppText(appTexts.footer.default, { supportEmail: supportEmail })}</div>
      {privacyPolicyUrl && (
        <div className="additional-links">
          <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">
            {appTexts.footer.privacyPolicy}
          </a>
        </div>
      )}
      {firebase.auth.currentUser && <SignOut text={appTexts.generic.signOut} isLink />}
    </div>
  );
};

export default Footer;
