import parse from 'html-react-parser';
import { useEffect } from 'react';

export const useUnsafeInjectHtmlIntoHeadEffect = (html: string) => {
  useEffect(() => {
    // Array to track elements that we need to remove when the component unmounts
    const injectedElements: HTMLElement[] = [];
    parse(html, {
      replace: (domNode) => {
        // html-react-parser sanitizes script & style tags: https://github.com/remarkablemark/html-react-parser#are-script-tags-parsed
        // So we need to do some jumbling ourselves: https://github.com/remarkablemark/html-react-parser/issues/98#issuecomment-476924507
        if (domNode.type !== 'script' && domNode.type !== 'style') {
          // We ignore anything else than script and style tags at the moment
          return null;
        }

        if (!('attribs' in domNode)) {
          return domNode;
        }

        const element = document.createElement(domNode.type);
        const firstChild = domNode.children[0];
        if (firstChild && 'data' in firstChild) {
          element.innerHTML = firstChild.data;
        }
        if (domNode.attribs.src && element instanceof HTMLScriptElement) {
          element.src = domNode.attribs.src;
        }
        document.getElementsByTagName('head')[0].appendChild(element);
        injectedElements.push(element);

        // We handled these types ourselves, so we return null so that html-react-parser doesn't handle it as well
        return null;
      },
    });

    return () => {
      injectedElements.forEach((element) => {
        element.remove();
      });
    };
  }, [html]);
};
