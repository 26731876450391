import { useCallback, useState } from 'react';
import {
  BaseRmaRequest,
  TDownloadLabelRequest,
  TDownloadLabelUrlRequest,
} from '../../../../functions/src/shared';
import { useFirebase } from '../firebase/context';
import { useSessionData } from '../firebase/hooks';
import { appConfig } from '../config';

export const useShippingLabel = (rmaRequestId: string, afterPayment = false) => {
  const [isFetchingLabel, setIsFetchingLabel] = useState(false);
  const [labelError, setLabelError] = useState(false);
  const [paidError, setIsPaidError] = useState(false);
  const [paperless, setPaperless] = useState(false);
  const [shippingLabelUrl, setShippingLabelUrl] = useState<null | string>(null);
  const [checkoutUrl, setCheckoutUrl] = useState<null | string>(null);
  const { rmaRequestDoc } = useFirebase();
  const { userData } = useSessionData();

  const fetchShippingLabel = useCallback(async () => {
    setIsFetchingLabel(true);

    // Start listening to rmaRequestDoc changes
    const unsubscribeDocListener = rmaRequestDoc(rmaRequestId).onSnapshot(
      (doc) => {
        if (!doc.exists) {
          throw new Error(`No rmaRequest document with id ${rmaRequestId} found.`);
        }

        const {
          rmaReturnLabel,
          rmaReturnCheckoutUrl,
          rmaRemoteLabelIsPaid,
          rmaReturnLabelError,
          paperlessMethod,
        } = doc.data() as BaseRmaRequest;

        // check payment stage
        if (rmaReturnCheckoutUrl && rmaRemoteLabelIsPaid === false) {
          setIsPaidError(true);
          setIsFetchingLabel(false);

          unsubscribeDocListener();
        }

        // check for checkout url (in case payment is needed)
        // this call is also used on retry
        if (!afterPayment && rmaReturnCheckoutUrl && typeof rmaReturnCheckoutUrl === 'string') {
          setCheckoutUrl(rmaReturnCheckoutUrl);
          setIsFetchingLabel(false);
          unsubscribeDocListener();
        } else if (paperlessMethod !== null && typeof paperlessMethod === 'object') {
          // We are going paperless, so we shouldn't fetch the label.
          setPaperless(true);
          setIsFetchingLabel(false);
          unsubscribeDocListener();
        } else if (
          rmaReturnLabel &&
          (!Array.isArray(rmaReturnLabel) || rmaReturnLabel.length > 0) &&
          shippingLabelUrl === null
        ) {
          fetchLabelUrl();

          unsubscribeDocListener();
        } else if (rmaReturnLabelError) {
          setLabelError(true);
          setIsFetchingLabel(false);

          unsubscribeDocListener();
        }
      },
      (error) => {
        // console.error('Error fetching rmaRequest document: ', error);
        setLabelError(true);
        setIsFetchingLabel(false);

        // Detach firestore listener
        unsubscribeDocListener();
      },
    );

    const fetchLabelUrl = async () => {
      const payload: TDownloadLabelUrlRequest = {
        requestId: rmaRequestId,
        labelIndex: 0, // on client we just take the first label
      };

      const url = `${appConfig.cloudFunctionsUri}/CustomerSession/download-label-url`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-shopify-rma-auth-token': userData.token ?? '',
        },
        body: JSON.stringify(payload),
      };

      try {
        const response = await fetch(url, options);

        switch (response.status) {
          case 200:
            const responseData = await response.json();
            setShippingLabelUrl(responseData.url);
            break;
          default:
            setLabelError(true);
        }
      } catch (error) {
        console.error('Error fetching from /CustomerSession/download-label-url: ', error);
        setLabelError(true);
      } finally {
        setIsFetchingLabel(false);
      }
    };
  }, [rmaRequestId, afterPayment, shippingLabelUrl, userData.token, rmaRequestDoc]);

  const downloadLabel = async (params: {
    order: string;
    secret: string;
    request: string;
    signature: string;
  }) => {
    const { order, secret, request: rmaRequestId, signature } = params;
    const url = `${appConfig.cloudFunctionsUri}/CustomerSession/handle-label-download`;
    const payload: TDownloadLabelRequest = {
      requestId: rmaRequestId,
      signature,
      order,
      secret,
      sendEmail: true,
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-shopify-rma-auth-token': userData.token ?? '',
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(url, options);

      if (response.status !== 200)
        throw new Error(`Something went wrong while downloading the label`);
    } catch (error) {
      console.error('Error fetching from /CustomerSession/handle-label-download ', error);
      setLabelError(true);
    }
  };

  return {
    paperless,
    isFetchingLabel,
    setIsFetchingLabel,
    setLabelError,
    downloadLabel,
    labelError,
    paidError,
    fetchShippingLabel,
    shippingLabelUrl,
    checkoutUrl,
  };
};
