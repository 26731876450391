Object.defineProperty(exports, "__esModule", { value: true });
exports.pseudo = void 0;
const chunk_1 = require("./chunk");
const randomize_1 = require("./randomize");
function pseudo(input) {
    const chunks = (0, chunk_1.chunk)(input);
    const randomChunks = (0, randomize_1.randomize)(chunks);
    return randomChunks;
}
exports.pseudo = pseudo;
