import React, { useState } from 'react';
import { Container } from '../Container/Container';
import './RadioSelector.scss';

interface Props {
  options: {
    id: string;
    label: string;
    alwaysChildren?: React.ReactNode;
    selectedChildren?: React.ReactNode;
    tag: React.ReactNode;
  }[];
  selected: string | undefined | null;
  onSelect: (id: string) => void;
}

export const RadioSelector = ({ options, onSelect, selected: externalSelected }: Props) => {
  const [internalSelected, setSelected] = useState<string | null>(null);

  const selected = externalSelected ?? internalSelected;

  const createHandleSelect = (id: string) => () => {
    setSelected(id);
    onSelect(id);
  };

  return (
    <Container className="radio-selector__container">
      {options.map(({ id, label, alwaysChildren, selectedChildren, tag }, index) => {
        const handleSelect = createHandleSelect(id);

        return (
          <>
            <div
              key={id}
              className={`selector-item ${selected === id ? 'selected' : ''}`}
              onClick={handleSelect}
            >
              <div className="selector-item__radio">
                <input type="radio" checked={selected === id} onChange={handleSelect} />
              </div>
              <div className="selector-item__body">
                {
                  <>
                    <div className="selector-item__label">{label}</div>
                    <div className="selector-item__content">
                      {alwaysChildren}
                      {selected === id && selectedChildren}
                    </div>
                  </>
                }
              </div>
              <div className="selector-item__tag">{tag}</div>
            </div>
            {index < options.length - 1 && <hr />}
          </>
        );
      })}
    </Container>
  );
};
