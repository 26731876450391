import React from 'react';
import { formatProductOptions } from '../../helpers/formatProductOptions';
import { NoImageFound } from '../NoImageFound';
import { TProductData, TVariantData } from '../../../../../functions/src/shared';

export const SummaryItemCard = ({
  productTitle,
  image,
  options,
  variants,
  variantTitle,
}: {
  productTitle: string;
  image: string | null;
  options: TProductData['options'];
  variants: TVariantData[];
  variantTitle: string;
}) => {
  const variantOption = formatProductOptions(options);

  return (
    <div className="card item-card is-review-card">
      <div className="item-card-image item-card-image-review">
        {image ? <img src={image} alt={productTitle} /> : <NoImageFound />}
      </div>
      <div className="item-card-content">
        <p className="item-card-title">{productTitle}</p>
        {Array.isArray(variants) && variants.length > 1 && (
          <p className="item-card-variant">
            <span>
              {variantOption}
              {variantTitle}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};
