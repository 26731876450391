import { appConfig } from '../config';

export const warmUpFunctions = async (endpoint) => {
  const url = `${appConfig.cloudFunctionsUri}${endpoint}`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const warmUpResponse = await fetch(url, options);
    if (!warmUpResponse.ok) throw new Error(warmUpResponse.status);
  } catch (error) {
    console.error(`Error while warming up at ${url}: `, error);
  }
};
