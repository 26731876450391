import React from 'react';
import { Notification } from '../../Notification';
import { useAppTexts } from '../../../hooks/useAppTexts';
import {
  ExchangeWizardItem,
  FullWizardItem,
  WizardContext,
} from '../../../contexts/WizardContext/WizardContext';
import { TProductData } from '../../../../../../functions/src/shared';
import WizardSelector from './WizardSelector';
import { useLoadedFirebaseData } from '../../../firebase/hooks';
import { createVariantOptions } from './createVariantOptions';
import { useNonNullableContext } from '../../../hooks/useNonNullableContext';

interface ExchangeAdditionalVariantProps {
  itemData: FullWizardItem;
  product: TProductData;
  onSubmit: (payload: ExchangeWizardItem) => void;
}

const ExchangeAdditionalVariant = ({
  itemData,
  product,
  onSubmit,
}: ExchangeAdditionalVariantProps) => {
  const appTexts = useAppTexts();
  const firebase = useLoadedFirebaseData();

  const { collectedWizardData } = useNonNullableContext(WizardContext);

  const { config, publicConfig: appThemeData, order } = firebase;

  const options = createVariantOptions(
    itemData,
    collectedWizardData,
    product,
    config,
    appTexts,
    order,
    appThemeData?.language,
  );

  const stepInfoMarkup = appTexts.itemWizard && appTexts.itemWizard.otherVariantInfoTop && (
    <Notification size="small">
      <div dangerouslySetInnerHTML={{ __html: appTexts.itemWizard.otherVariantInfoTop }}></div>
    </Notification>
  );

  return (
    <div className="item-wizard-content-body">
      <WizardSelector options={options} onSelect={onSubmit} />
      {stepInfoMarkup}
    </div>
  );
};

export default ExchangeAdditionalVariant;
