import React from 'react';
import { WizardContext } from '../../contexts/WizardContext/WizardContext';
import { ReviewItemCard } from './ReviewItemCard';
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat';
import { useAppTexts } from '../../hooks/useAppTexts';
import { useNonNullableContext } from '../../hooks/useNonNullableContext';

interface ReviewItemsProps {
  currency: string;
  locale: string;
}

export const ReviewItems = ({ currency, locale }: ReviewItemsProps) => {
  const appTexts = useAppTexts();
  const { allActions, exchangeActions, hasActions, hasExchangeActions } =
    useNonNullableContext(WizardContext);

  // Items to send back
  const itemsToSendBack = hasActions
    ? allActions.map(({ initialItem }, i) => (
        <ReviewItemCard key={`item-to-send-back-${i}`} itemData={initialItem} />
      ))
    : null;

  // Items to receive
  const itemsToReceive = hasExchangeActions
    ? exchangeActions.map(({ exchangeFor }, i) => {
        const receiveItemPrice = Math.floor(Number(exchangeFor.variant.price) * 100) / 100;
        const discountedPrice = exchangeFor.discountedPrice ?? receiveItemPrice;

        return (
          <ReviewItemCard
            key={`item-to-receive-${i}`}
            itemData={{
              ...exchangeFor,
              originalPrice: receiveItemPrice,
              discountedPrice: discountedPrice,
              displayDiscountedPrice: `-${getCurrencyFormat(discountedPrice, currency, locale)}`,
              displayOriginalPrice: `-${getCurrencyFormat(receiveItemPrice, currency, locale)}`,
            }}
          />
        );
      })
    : null;

  // markups
  const itemsToSendBackMarkup = itemsToSendBack && (
    <div className="review-block">
      <h2 className="review-block-title">{appTexts.review.itemsToSendBack}</h2>
      <div>{itemsToSendBack}</div>
    </div>
  );

  const itemsToReceiveMarkup = itemsToReceive && (
    <div className="review-block">
      <h2 className="review-block-title">{appTexts.review.itemsToReceive}</h2>
      <div>{itemsToReceive}</div>
    </div>
  );

  return (
    <React.Fragment>
      {itemsToSendBackMarkup}
      {itemsToReceiveMarkup}
    </React.Fragment>
  );
};
