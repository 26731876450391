import React, { useMemo } from 'react';
import { useTimelineElements } from './useTimelineElements';
import { Timeline } from './Timeline';
import { determineTrackingStatus } from './determineTrackingStatus';
import { TrackingStatus } from '../../../../../functions/src/shared';

export const TrackingTimeline = ({
  currentStatus,
  rmaIsCompleted,
  hasAftershipTracking,
}: {
  currentStatus: TrackingStatus | null;
  rmaIsCompleted?: boolean;
  hasAftershipTracking: boolean;
}) => {
  const trackingStatus = useMemo(
    () => determineTrackingStatus(currentStatus, rmaIsCompleted),
    [currentStatus, rmaIsCompleted],
  );

  const timelineElements = useTimelineElements(trackingStatus, hasAftershipTracking);

  return <Timeline elements={timelineElements} />;
};
