import { AppTexts } from '../../../../../../functions/src/shared';

export const en: AppTexts = {
  generic: {
    logo: 'Logo',
    signOut: 'Click here to remove all return preferences (start over).',
    goBack: 'Go back',
    exchange: 'Exchange',
    return: 'Return',
    returnAlreadySubmitted: 'It seems that you have already submitted this return.',
    multipleRefundsPossible:
      'You are requesting a refund on an exchange order. When handling this you will receive more than 1 refund notification because your funds are (partially) in the first order you made.',
    submitAnotherReturn: 'Submit another return',
    free: 'Free',
    next: 'Next',
    error: {
      problemLoading: 'There is a problem loading this page',
      technicalProblem: 'A technical problem is preventing the page from loading correctly.',
      comeBack: 'Come back in a few minutes and try again',
      contactSupport: 'Contact support',
      serverError: 'Server error',
      networkError: 'Network error',
      timeout:
        'Looks like the server is taking too long to respond. This can be caused by either poor connectivity or an error with our servers. Please try again later.',
      other: 'Looks like something went wrong. Try again later or contact us at {{supportEmail}}',
    },
    form: {
      fieldRequired: 'This field is required',
      invalidEmail: 'The email address is invalid',
    },
  },
  shipping: {
    returnMethod: 'Return method',
    pleaseSelectMethod: 'Please select how you want to send the parcel back.',
  },
  footer: {
    default: 'Need any help with returning your products? Please contact us at {{supportEmail}}',
    privacyPolicy: 'Privacy Policy',
  },
  orderLookup: {
    title: 'Returns and Exchanges',
    pageTitle: 'Start a new return or exchange',
    orderNumber: 'Order Number',
    postalcode: 'Postal code',
    emailaddress: 'Email address',
    any: 'Email address or postal code',
    submit: 'Start',
    error: {
      notFound: 'Order not found. Please check your data and try again.',
      currencyNotSupported:
        'This platform can only handle returns in {{supportedCurrency}}. Please contact {{supportEmail}} to handle your return.',
    },
  },
  itemsList: {
    title: 'Choose a product to return or exchange',
    pageTitle: 'Order',
    ineligibleSubtitle: 'Not all items in this order can be returned',
    returnableUntil: 'Order {{orderName}} is returnable until {{returnableUntilDate}}',
    noItemsEligible: 'No products are eligible for return at this time.',
    noReturnCountry:
      "No products are eligible for return at this time, because we don't have a return policy for your country. Please contact support to fulfil your return.",
    returnWindowClosed:
      'The return window has expired. \n Products  are no longer eligible for return.',
    warrantyReturnPossible: 'These products are still under warranty.',
    submitWarrantyRequest: 'Submit a warranty request.',
    returnAlreadySubmitted: 'A return for order {{orderName}} has already been submitted.',
    productsWithTechnicalErrors:
      "One or more products from your order can't be returned or exchanged due to a technical issue. Please, let us know about these by contacting us at {{supportEmail}}",
    lastUpdate: 'Last update',
    selectedItems: '{{count}} product(s) selected',
    continueWithReturn: 'Continue with return',
    cannotContinueWithReturn:
      'The selected items exceed the value of the available return credit. Please adjust your selection so that the value of requested items is greater or equal to the value of the items you wish to return/exchange.',
    expectedArrivalDate: 'Expected date of arrival',
    paymentPending: {
      title: 'Payment pending',
      message:
        'We are awaiting for your payment. Please check your email or contact us at {{email}}',
    },
    adjustmentsInCalculation: 'Calculation contains adjustments',
  },
  shippingLabel: {
    isPaidLabel:
      'You will have to pay {{amount}} in order to receive your {{carrier}} return label.',
  },
  item: {
    exchangeInfoDiscount:
      'The exchange value for this product is {{exchangeValue}}.\nWhen you choose to return the item {{refundValue}} is refunded.',
    exchangeInfoPriceIncrease: {
      always:
        'Due to a recent price increase\nthe exchange value for this product is {{exchangeValue}}.\nWhen you choose to return the item {{refundValue}} is refunded.',
      'both-match':
        'Due to a recent price increase\nthe exchange value for this product is {{exchangeValue}} for selected products.\nWhen you choose to return the item {{refundValue}} is refunded.',
      'same-product':
        'Due to a recent price increase\nthe exchange value for this product is {{exchangeValue}} when exchanging for another variant.\nWhen you choose to return the item {{refundValue}} is refunded.',
      'same-variant':
        'Due to a recent price increase\nthe exchange value for this product is {{exchangeValue}} when exchanging for the exact same product.\nWhen you choose to return the item {{refundValue}} is refunded.',
      none: '',
    },
    adjustmentDecrease: 'Due to the return of another product, more discount is applicable.',
    adjustmentIncrease: 'Due to the return of another product, less discount is applicable.',
  },
  tracking: {
    status: {
      droppedOff: 'dropped off',
      inTransit: 'in transit',
      submitted: 'submitted',
      delivered: 'delivered',
    },
  },
  itemWizard: {
    isCurrentVariant: 'Current',
    chooseVariant: 'Choose a variant',
    outOfStock: 'Out of stock',
    reasonsInfoBottom: '',
    otherCollectionInfoTop: '',
    otherVariantInfoTop: '',
    reasonsWhyExchange: 'Why are you exchanging?',
    reasonsWhyReturn: 'Why are you returning?',
    typeCommentHereAndCount: 'Type your comment here (min. {{count}} characters)...',
    typeCommentHere: 'Type your comment here...',
    commentIsEmpty: 'The comment field may not be empty.',
    commentIsTooShort: 'The comment must have at least {{count}} characters.',
    submitComment: 'Submit comment',
    noProductFound: 'No related product has been found',
    from: 'From',
    howMakeItRight: 'How can we make it right?',
    sendSameProduct: 'Send me the same product',
    showMoreOptions: 'Show more options',
  },
  review: {
    title: 'Review your return',
    pageTitle: 'Review your return',
    itemsToReceive: "Product(s) you'll receive",
    itemsToSendBack: 'Product(s) to send back',
    shippingAddressTitle: 'Your exchange order will be sent to',
    deliveryOptionTitle: 'Where do we have to ship your Exchange to?',
    deliveryOption: {
      billingAddress: 'My home address (Billing address) or add an address',
      pickupPoint: 'In previous order chosen Pickup Point',
    },
    noItemsSelected:
      "You haven't selected any products yet. Go back and select any products you want to return or exchange.",
    submitReturn: 'Submit return',
    submitReturnAndPay: 'Pay for return label and confirm return',
    returnShippingCosts: 'Return shipping costs',
    removedDiscount: 'Additional payment for {{itemTitle}}',
    extraDiscount: 'Extra discount for {{itemTitle}}',
    refund: {
      title: 'Summary',
      calculationPriceIncreasement: 'Adjustments price increases',
      valueOfReturnedProducts: 'Value of returned products',
      valueOfExchangeProducts: 'Value of exchange products',
      appliedDiscountToOrder: 'Discount on original order',
      totalRefundAmount: 'Total refund amount',
      totalAdditionalPayment: 'Total additional payment',
      additionalPaymentInfo:
        'There are outstanding payments. Once your request is approved we will send a link to pay the outstanding amount to: {{email}}',
      shippingCostRefundInfo:
        'When you paid for the shipping costs we will refund these as well. These are not part of the total refund amount above and will be added when we refund your order.',
    },
    budbee: {
      typePhoneNumberHere: 'Type your mobile phone number here',
      hasBillingPhoneNumber:
        'We will use {{phoneNumber}} for your Budbee Pick-up. If you want to use another phone number, fill it in below:',
      hasNoBillingPhoneNumber:
        "We don't have a phone number from you. Budbee needs this to update you on the Pick-up. Please fill it in below:",
      phoneNumberInvalid:
        'Your phone number is invalid, please include the country code and a "+", e.g. "+44987654321".',
    },
    sendcloud: {
      header: 'We need some more info!',
      explanation:
        'In order to provide the right information to the shipping company, we need your street address and number. Please fill them in below so we can create a return for you.',
      streetNamePlaceholder: 'Street name',
      streetNumberPlaceholder: 'Street number',
      streetNameInvalid: 'Not a valid street name, please fill in something.',
      streetNumberInvalid:
        "Not a valid street number. It should start with a number, e.g. '42B' and its length cannot exceed 20 characters.",
    },
    comment: {
      infoExchange:
        'If you want to change the address, please enter a new address in the comment field below.',
      infoRefund: 'If you want to add any additional remarks, please add a comment to this return.',
      addComment: 'Add comment',
      clearComment: 'Clear comment',
      addAddress: 'Add new address',
      clearAddress: 'Clear new address',
      commentIsEmpty: 'The comment field may not be empty.',
      commentIsTooShort: 'The comment must have at least {{count}} characters.',
    },
    error: {
      notAccepted: 'This return has already been submitted.',
    },
    additionalRefundInfo:
      'We will refund to the payment account (or gift card) that was used for the original transaction.',
  },
  summary: {
    title: 'Your return summary',
    pageTitle: 'Return summary',
    generatingLabel: 'Processing return label request',
    submittingPickUp: 'Your Budbee Pick-up request is being submitted',
    labelReady: 'Your label is ready to print',
    paymentLinkReady: 'Press the button below when you are not redirected',
    thanksSubmitting: 'Thank you for submitting your return',
    thanksSubmittingBudbee:
      'The estimated pickup time for your package is on {{day}} between {{start}} and {{stop}}. If you wish to track or change the pick-up, please use the Budbee app with the phone number you submitted with this return: {{phoneNumber}}.',
    noLabel:
      'It seems that something went wrong while getting the label. Contact us at {{ supportEmail }} and we will send you the correct label in no time.',
    labelNotPaid:
      'It seems that the return label is not paid. Please use the button below to restart the payment process. In case you paid already and you see this error, please contact us at {{ supportEmail }}',
    downloadLabel: 'Download shipping label',
    redirectToPayment: 'Continue to payment',
    restartPayment: 'Restart payment process',
    mailWillBeSentTo: 'Shipping label & instructions are also emailed to',
    mailWillBeSentToOnlyInstructions: 'Instructions are also emailed to',
    mailWillBeSentToPaperless: 'Instructions are emailed to',
    mailWillBeSentToPaperlessSub:
      'This e-mail also contains a barcode you will need when handing in your package.',
    howToTitle: 'How to return your items',
    itemsToSendBack: 'Product(s) to send back',
    itemsToReceive: "Product(s) you'll receive",
    estimatedProcessingTime:
      'It takes around {{count}} business day(s) to process your return after you hand in your package.',
    commercialInvoiceReady: 'Customs Invoice Ready',
    downloadCommercialInvoice: 'Download customs invoice',
    noCommercialInvoice:
      'It seems that something went wrong while generating the customs invoice.  Contact us at {{ supportEmail }} and we will send you the correct invoice to attach to your return.',
  },
  rma: {
    option: {
      genericExchange: 'Exchange for a different product',
      genericReturn: 'Return product',
    },
    warning: {
      alreadyReturned: 'Product has been already returned',
      rmaInProcess: 'Product already submitted',
      unfulfilledLineItem: 'Product not fulfilled',
      noReturnGiftCards: 'Gift cards are not eligible for returns',
      noPhysicalProduct: 'Digital products are not eligible for returns',
      returnWindowExpired: 'Return window expired',
      countryNotReturnable: 'Unfortunately we cannot accept this item from your country',
      notEligibleForReturn:
        'This product is not eligible for return, for questions please contact customer service.',
    },
  },
  pageNotFound: {
    title: '404',
    body: 'Page not found',
  },
  shopNotFound: {
    title: '403',
    body: 'Access not allowed',
  },
  noApp: {
    title: 'Browser not supported',
    notPossible: 'Exchanging/returning is unfortunately not possible with Internet Explorer.',
    useDifferentBrowser: 'Please use a different browser',
    orVisitWithPhone: 'or visit this page with your phone',
  },
  delivery: {
    other: 'Ship to another address',
    otherAddress: 'Use the comment field to enter a new address',
  },
};
