import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {
  BaseRmaRequest,
  DiscountResponse,
  GlobalConfig,
  SfyFulfillmentType,
  SfyLineItem,
  SfyOrderAddress,
  SfyOrderType,
  TProductData,
  TReturnOptionsResponse,
  RmaSummaryLineItem,
  TReturnOptionsRequest,
} from '../../../../functions/src/shared';
import { appConfig } from '../config';

// Set Firebase config variables
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export interface RmaSummary {
  lineItems: RmaLineItem[];
  rmaSubmitted: boolean;
}

export type RmaLineItem = RmaSummaryLineItem<app.firestore.Timestamp>;

/** @deprecated please use SfyOrderType instead */
export interface OrderData {
  createdAt: app.firestore.Timestamp;
  currency: string;
  lineItems: SfyLineItem[];
  email: string;
  lineItemsRmaData: RmaLineItem[];
  orderId: number;
  orderName: string;
  returnableUntil: string | null;
  isReturnable: boolean;
  isReturnableCountry: boolean;
  rmaSubmitted: boolean;
  countryCode: string;
  tracking?: BaseRmaRequest<app.firestore.Timestamp>['tracking'] | null;
  rmaIsCompleted?: boolean;
  rmaIsPaymentPending?: boolean;
  fulfillments?: SfyFulfillmentType[];
}

export interface ShippingAddress {
  addressLineA: string;
  addressLineB: string;
  country: string;
  countryCode: string;
  phone: string;
}

type UserData = Partial<{
  token: string;
  id: string;
  app: string;
  userId: string;
  email: string;
  name: string;
  shop: string;
  installedAt: Date;
  storefrontApiKey: string;
  storefront: string;
  currency: string;
  productsImported: boolean;
  orderName: string;
  orderId: string;
}>;

export interface LoadedSessionData {
  config: GlobalConfig;
  rmaSummary: RmaSummary;
  rmaRequest: BaseRmaRequest<app.firestore.Timestamp> | null;
  discounts: DiscountResponse | null;
  returnOptions: TReturnOptionsResponse | null;
  deliveryOptions: {
    options: unknown[];
  } | null;
  products: TProductData[] | null;
  order: SfyOrderType;
  userData: UserData;
  shippingAddress: ShippingAddress;
}

export class Firebase {
  auth: app.auth.Auth;
  db: app.firestore.Firestore;
  authPersistence: app.auth.Auth.Persistence;
  shopName: string | null;
  isReturnFinished: boolean;
  _hasCustomDomain: null | boolean;

  constructor() {
    if (!app.apps.length) {
      app.initializeApp(config);
    }

    // Firebase APIs
    this.auth = app.auth();
    this.authPersistence = app.auth.Auth.Persistence.SESSION;
    this.db = app.firestore();

    if (appConfig.isUsingEmulators) {
      this.auth.useEmulator('http://localhost:9099');
      this.db.settings({
        experimentalForceLongPolling: true, // For e2e tests, see https://github.com/cypress-io/cypress/issues/2374
      });
      this.db.useEmulator('localhost', 8080);
    }

    // Is Return Finished
    this.isReturnFinished = false;

    // set sfy shop data
    this.shopName = null;

    // this indicates whether an installation has a custom domain
    // configured or not. Usually this is only for the installations
    // that were made as a custom app. But also Public apps can request
    // for a Custom domain name. This will be listed in the domains overview.
    this._hasCustomDomain = null;
  }

  hasCustomDomain = () => {
    if (this._hasCustomDomain === null) {
      throw new Error(
        'The app has not been properly initialized! Make sure the check to test if the current domain belongs to a public or a private app is done before calling this method',
      );
    }
    return this._hasCustomDomain;
  };

  // Domain API
  domain = (domain: string) => this.db.doc(`domains/${domain}/`);

  // Shop API
  shop = (domain: string) => this.db.doc(`sfyShops/${domain}/`);

  // Orders API
  orderDoc = (id: string) => this.db.doc(`sfyShops/${this.shopName}/sfyOrders/${id}`);
  ordersCollection = () => this.db.collection(`sfyShops/${this.shopName}/sfyOrders`);

  // Returns API
  rmaSummaryDoc = (id: string) => {
    return this.db.doc(`sfyShops/${this.shopName}/rmaSummary/${id}`);
  };
  rmaRequestsCollection = () => this.db.collection(`sfyShops/${this.shopName}/rmaRequests`);
  rmaRequestDoc = (id: string) => this.rmaRequestsCollection().doc(id);

  // Config API
  publicConfigRef = (id: string) => this.db.doc(`sfyShops/${id}/config/public`);
  configDoc = () => this.db.doc(`sfyShops/${this.shopName}/config/global`);

  setShopName = (shopName: string) => {
    this.shopName = shopName;
  };

  fetchGlobalConfig = async (): Promise<GlobalConfig> => {
    const configDoc = await this.configDoc().get();

    // Fetch config data
    if (!configDoc.exists) {
      throw new Error('No config data found');
    } else {
      return configDoc.data() as GlobalConfig;
    }
  };

  fetchRmaSummary = async (orderId: string): Promise<RmaSummary> => {
    const rmaSummaryDoc = await this.rmaSummaryDoc(orderId).get();

    // Fetch rma summary data
    if (!rmaSummaryDoc.exists) {
      throw new Error('The document you are looking for does not exist.');
    } else {
      return rmaSummaryDoc.data() as RmaSummary;
    }
  };

  fetchSfyOrder = async (orderId: string): Promise<SfyOrderType> => {
    const orderDoc = await this.orderDoc(orderId).get();

    // Fetch sfyOrder data
    if (!orderDoc.exists) {
      throw new Error('The document you are looking for does not exist.');
    } else {
      return orderDoc.data() as SfyOrderType;
    }
  };

  fetchDiscounts = async (
    orderId: string,
    userData: UserData,
  ): Promise<DiscountResponse | null> => {
    const url = `${appConfig.cloudFunctionsUri}/CustomerSession/discount`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-shopify-rma-auth-token': userData.token ?? '',
      },
      body: JSON.stringify({
        orderId,
      }),
    };

    try {
      const response = await fetch(url, options);

      if (response.status !== 200) return null;

      const responseData = (await response.json()) as DiscountResponse;

      return responseData;
    } catch (error) {
      console.error('Error fetching from /CustomerSession/discount: ', error);
      return null;
    }
  };

  fetchDeliveryOptions = async (orderId: string, userData: UserData) => {
    const url = `${appConfig.cloudFunctionsUri}/CustomerSession/deliveryOptions`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-shopify-rma-auth-token': userData.token || '',
      },
      body: JSON.stringify({
        orderId,
      }),
    };

    try {
      const response = await fetch(url, options);

      if (response.status !== 200) return {};

      const responseData = await response.json();

      return responseData;
    } catch (error) {
      console.error('Error fetching from /CustomerSession/deliveryOptions: ', error);
      return null;
    }
  };

  fetchReturnOptions = async (orderId: string, userData: UserData, locale: string) => {
    const url = `${appConfig.cloudFunctionsUri}/CustomerSession/returnOptions`;
    const payload: TReturnOptionsRequest = {
      orderId,
      locale,
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-shopify-rma-auth-token': userData.token || '',
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(url, options);

      if (response.status !== 200) return null;

      const responseData = (await response.json()) as TReturnOptionsResponse;

      return responseData;
    } catch (error) {
      console.error('Error fetching from /CustomerSession/returnOptions: ', error);
      return null;
    }
  };

  fetchProductData = async (orderId: string, userData: UserData) => {
    const url = `${appConfig.cloudFunctionsUri}/CustomerSession/order-products?orderId=${orderId}`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-shopify-rma-auth-token': userData.token || '',
      },
    };

    try {
      const response = await fetch(url, options);

      if (response.status !== 200) return [];

      const responseData = (await response.json()) as TProductData[];

      return responseData;
    } catch (error) {
      console.error('Error fetching from /CustomerSession/getProductData: ', error);
      return null;
    }
  };

  getUserData = ({ token, claims }: app.auth.IdTokenResult): UserData => {
    const { shop, storefront, name, user_id } = claims;

    return {
      token,
      shop,
      storefront,
      orderName: name,
      orderId: user_id.split('.')[1],
    };
  };

  getShippingAddress = (address: SfyOrderAddress): ShippingAddress => {
    const { firstName, lastName, address1, address2, city, zip, country, countryCode, phone } =
      address;

    const addressLineA = `${firstName} ${lastName}, ${address1} ${address2 || ''},`;
    const addressLineB = `${zip} ${city}, ${country}`;

    return {
      addressLineA,
      addressLineB,
      country,
      countryCode,
      phone,
    };
  };

  authenticate = async (token: string | null | undefined) => {
    if (token === null || token === undefined) {
      throw new Error('No token was provided.');
    }

    await this.auth.setPersistence(this.authPersistence);

    try {
      const credentials = await this.auth.signInWithCustomToken(token);
      return credentials;
    } catch (error) {
      console.error('Error while authorizing with firebase: ', error);
      throw error;
    }
  };

  // fetch all initial data (in sequence)
  fetchInitialData = async (locale: string): Promise<LoadedSessionData> => {
    if (!this.auth.currentUser) {
      throw new Error('No user is currently signed in.');
    }

    const idToken = await this.auth.currentUser.getIdTokenResult(/* forceRefresh */ true);

    const userData = this.getUserData(idToken);

    const orderId = userData.orderId;

    if (!orderId || !this.shopName) {
      throw new Error('No order id or shop name was found.');
    }

    const [
      config,
      rmaSummary,
      rmaRequest,
      order,
      discounts,
      deliveryOptions,
      returnOptions,
      products,
    ] = await Promise.all([
      this.fetchGlobalConfig(),
      this.fetchRmaSummary(orderId),
      this.fetchOrderRmaRequest(Number(orderId)),
      this.fetchSfyOrder(orderId),
      this.fetchDiscounts(orderId, userData),
      this.fetchDeliveryOptions(orderId, userData),
      this.fetchReturnOptions(orderId, userData, locale),
      this.fetchProductData(orderId, userData),
    ]);

    const shippingAddress = this.getShippingAddress(order.shippingAddress);

    return {
      config,
      rmaSummary,
      rmaRequest,
      discounts,
      returnOptions,
      deliveryOptions,
      products,
      order,
      userData,
      shippingAddress,
    };
  };

  fetchOrderRmaRequest = async (orderId: number) => {
    const orderRmaRequests = await this.rmaRequestsCollection()
      .where('orderId', '==', orderId)
      .limit(1) // We for now have a guarantee that for each order there is only 1 request.
      .get();

    if (orderRmaRequests.empty) {
      // There is no rma request yet, so the user should start making one.
      return null;
    }

    return orderRmaRequests.docs[0].data() as BaseRmaRequest<app.firestore.Timestamp>;
  };
}
