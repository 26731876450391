import React, { MouseEventHandler, useState } from 'react';
import { Notification } from '../../Notification';
import { interpolateAppText, useAppTexts } from '../../../hooks/useAppTexts';

interface Props {
  additionalCommentLabel: string | undefined;
  optional: boolean;
  onSubmit: (additionalComment: string) => void;
}

const AdditionalComment = ({ additionalCommentLabel, optional, onSubmit }: Props) => {
  // if optional if can be empty
  const MIN_COMMENT_CHARACTERS = optional ? 0 : 5;

  const appTexts = useAppTexts();

  const [comment, setComment] = useState('');
  const [commentValidation, setCommentValidation] = useState('');

  const handleCommentValueChange = (newValue: string) => {
    setComment(newValue);

    // reset validation and error states
    setCommentValidation('');
  };

  const handleCommentValidation = () => {
    // guard
    if (optional) return;

    switch (true) {
      case comment === '' || comment.length <= 0:
        return appTexts.itemWizard.commentIsEmpty;
      case comment.length > 0 && comment.length < MIN_COMMENT_CHARACTERS:
        return interpolateAppText(appTexts.itemWizard.commentIsTooShort, {
          count: MIN_COMMENT_CHARACTERS.toString(),
        });
    }
  };

  const handleSubmitComment: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    const invalidMessage = handleCommentValidation();

    // exit if comment is invalid
    if (invalidMessage) {
      setCommentValidation(invalidMessage);
      return;
    }

    return onSubmit(comment);
  };

  return (
    <>
      {additionalCommentLabel && (
        <h2 className="item-wizard-comment-header">{additionalCommentLabel}</h2>
      )}

      <div className="item-wizard-content-body">
        <textarea
          autoFocus
          className="textarea item-wizard-comment-field"
          placeholder={
            optional
              ? appTexts.itemWizard.typeCommentHere
              : interpolateAppText(appTexts.itemWizard.typeCommentHereAndCount, {
                  count: MIN_COMMENT_CHARACTERS.toString(),
                })
          }
          onChange={(event) => handleCommentValueChange(event.target.value)}
        ></textarea>
      </div>
      <div className="item-wizard-content-footer">
        <Notification hide={commentValidation === ''} status="danger">
          <p>{commentValidation}</p>
        </Notification>
        <button className="button is-primary is-fullwidth" onClick={handleSubmitComment}>
          {appTexts.itemWizard.submitComment}
        </button>
      </div>
    </>
  );
};

export default AdditionalComment;
