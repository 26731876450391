import React from 'react';
import { useAppTexts } from '../hooks/useAppTexts';

import { Notification } from './Notification';

export const MultipleRefundsPossibleNotification = () => {
  const appTexts = useAppTexts();

  return (
    <Notification
      status="info"
      message={
        <>
          <p>{appTexts.generic.multipleRefundsPossible}</p>
        </>
      }
    />
  );
};
