import React from 'react';
import { useAppTexts } from '../hooks/useAppTexts';

import { Notification } from './Notification';
import SignOut from './SignOut';

export const ReturnAlreadySubmittedNotification = () => {
  const appTexts = useAppTexts();

  return (
    <Notification
      status="info"
      message={
        <>
          <p>{appTexts.generic.returnAlreadySubmitted}</p>
          <SignOut text={appTexts.generic.submitAnotherReturn} />
        </>
      }
    />
  );
};
