import React from 'react';
import { useHistory } from 'react-router-dom';
import { WizardContext } from '../contexts/WizardContext/WizardContext';
import { useFirebase } from '../firebase/context';
import { useNonNullableContext } from '../hooks/useNonNullableContext';

const SignOut = ({ text, isLink }: { text: string; isLink?: boolean }) => {
  const history = useHistory();
  const { resetWizard } = useNonNullableContext(WizardContext);
  const { resetSession, getPathTo } = useFirebase();

  const handleSignOut = async () => {
    try {
      await resetSession();
      resetWizard();

      // redirect to login
      history.push(getPathTo(''));
    } catch (error) {
      console.error('Error while signing out:', error);
    }
  };

  return (
    <div className="sign-out">
      <button className={`button${isLink ? ' custom-button is-link' : ''}`} onClick={handleSignOut}>
        <span>{text}</span>
      </button>
    </div>
  );
};

export default SignOut;
