import { AppTexts } from '../../../../../../functions/src/shared';

export const nl: AppTexts = {
  generic: {
    logo: 'Logo',
    signOut: 'Klik hier om alle retourvoorkeuren te verwijderen (start opnieuw).',
    goBack: 'Terug',
    exchange: 'Ruilen',
    return: 'Retourneren',
    returnAlreadySubmitted: 'Het lijkt erop dat u deze aanvraag al heeft ingediend.',
    multipleRefundsPossible:
      'Je doet een terugstortverzoek voor een order die je hebt omgeruild. Let op als wij deze afhandelen je meerdere notificaties hiervan ontvangt omdat het geld (deels) in de eerste order zit.',
    submitAnotherReturn: 'Dien nog een aanvraag in',
    free: 'Gratis',
    next: 'Volgende',
    error: {
      problemLoading: 'Het lukt momenteel niet om deze pagina te laden',
      technicalProblem: 'De pagina kan niet geladen worden door een technisch probleem.',
      comeBack: 'Kom terug over een aantal minuten en probeer het dan nogmaals',
      contactSupport: 'Neem contact op met de klantenservice',
      serverError: 'Server error',
      networkError: 'Network error',
      timeout:
        'De server doet er momenteel erg lang over. Dit kan liggen aan een slechte verbinding of een fout op onze servers. Probeer het later nog eens.',
      other:
        'Er gaat iets mis. Probeer het later nog eens of neem contact met ons op via {{supportEmail}}',
    },
    form: {
      fieldRequired: 'Dit veld is verplicht',
      invalidEmail: 'Ongeldig e-mailadres ingevuld',
    },
  },
  footer: {
    default: 'Hulp nodig met ruilen/retourneren? Neem contact op met {{supportEmail}}',
    privacyPolicy: 'Privacybeleid',
  },
  orderLookup: {
    title: 'Ruilen & Retourneren',
    pageTitle: 'Start een nieuwe retour',
    orderNumber: 'Ordernummer',
    postalcode: 'Postcode',
    emailaddress: 'E-mailadres',
    any: 'E-mailadres of postcode',
    submit: 'Start',
    error: {
      notFound: 'Order niet gevonden. Controleer of de gegevens correct zijn.',
      currencyNotSupported:
        'We kunnen alleen retourzendingen afhandelen in {{supportedCurrency}}. Neem contact op met {{supportEmail}} om je retourzending af te handelen.',
    },
  },
  itemsList: {
    title: 'Kies een product om te ruilen of retourneren',
    pageTitle: 'Order',
    ineligibleSubtitle: 'Niet alle items in deze order kunnen teruggestuurd worden',
    returnableUntil: 'Order {{orderName}} is terug te sturen tot {{returnableUntilDate}}',
    noItemsEligible: 'Er kunnen momenteel geen producten geretourneerd worden.',
    noReturnCountry:
      'Er komen op dit moment geen producten in aanmerking voor retournering, omdat we geen retourbeleid voor uw land hebben. Neem contact op met de support-afdeling om je retourzending af te handelen.',
    returnWindowClosed:
      'De retourperiode is verstreken. \n producten kunnen niet meer geretourneerd worden.',
    warrantyReturnPossible: 'Deze producten vallen nog onder de garantie.',
    submitWarrantyRequest: 'Dien een garantieaanvraag in.',
    returnAlreadySubmitted: 'Order {{orderName}} is al retour aangemeld',
    productsWithTechnicalErrors:
      'Een of meerdere producten uit je bestelling kunnen vanwege een technisch probleem niet worden geretourneerd of geruild. Laat het ons weten door contact met ons op te nemen via {{supportEmail}}',
    lastUpdate: 'Laatste update',
    selectedItems: '{{count}} product(en) geselecteerd',
    continueWithReturn: 'Doorgaan met retour',
    cannotContinueWithReturn:
      'De geselecteerde artikelen overschrijden de waarde van het beschikbare retourtegoed. Pas uw selectie aan zodat de waarde van de gevraagde artikelen groter of gelijk is aan de waarde van de artikelen die u wilt retourneren/ruilen.',
    expectedArrivalDate: 'Verwachte aankomstdatum',
    paymentPending: {
      title: 'Betaling in behandeling',
      message:
        'Wij wachten op uw betaling. Controleer uw e-mail of neem contact met ons op via {{email}}',
    },
    adjustmentsInCalculation: 'Berekening bevat aanpassingen',
  },
  shippingLabel: {
    isPaidLabel: 'Voor het {{carrier}} retour label dient er {{amount}} te worden afgerekend.',
  },
  item: {
    exchangeInfoDiscount:
      'Je {{exchangeValue}} korting wordt toegepast bij omruilen.\nAls je kiest voor retourneren dan wordt er {{refundValue}} terug gestort.',
    exchangeInfoPriceIncrease: {
      always:
        'Wegens een recente prijsverhoging\nis de omruilwaarde voor dit product {{exchangeValue}}.\nAls je kiest voor retourneren dan wordt er {{refundValue}} terug gestort.',
      'both-match':
        'Wegens een recente prijsverhoging\nis de omruilwaarde voor dit product {{exchangeValue}} bij geselecteerde producten.\nAls je kiest voor retourneren dan wordt er {{refundValue}} terug gestort.',
      'same-product':
        'Wegens een recente prijsverhoging\nis de omruilwaarde voor dit product {{exchangeValue}} bij ruilen voor een andere variant.\nAls je kiest voor retourneren dan wordt er {{refundValue}} terug gestort.',
      'same-variant':
        'Er is een recente prijsverhoging geweest, maar bij ruilen voor het exact zelfde product hoef je niks bij te betalen.\nAls je kiest voor retourneren dan wordt er {{refundValue}} terug gestort.',
      none: '',
    },
    adjustmentDecrease: 'Door een retour van een ander product is er meer korting van toepassing',
    adjustmentIncrease: 'Door een retour van een ander product is er minder korting van toepassing',
  },
  tracking: {
    status: {
      droppedOff: 'afgeleverd',
      inTransit: 'onderweg',
      submitted: 'aangemeld',
      delivered: 'ontvangen',
    },
  },
  itemWizard: {
    isCurrentVariant: 'Huidig',
    chooseVariant: 'Kies een variant',
    outOfStock: 'Niet op voorraad',
    reasonsInfoBottom: '',
    otherCollectionInfoTop: '',
    otherVariantInfoTop: '',
    reasonsWhyExchange: 'Waarom wil je ruilen?',
    reasonsWhyReturn: 'Waarom wil je retourneren?',
    typeCommentHereAndCount: 'Voer je opmerking hier in (min. {{count}} tekens)...',
    typeCommentHere: 'Voer je opmerking hier in...',
    commentIsEmpty: 'Dit veld mag niet leeg zijn.',
    commentIsTooShort: 'Dit veld moet minstens {{count}} tekens bevatten.',
    submitComment: 'Opmerking toevoegen',
    noProductFound: 'Geen gerelateerde producten gevonden',
    from: 'Vanaf',
    howMakeItRight: 'Hoe kunnen we het goedmaken?',
    sendSameProduct: 'Stuur hetzelfde product opnieuw',
    showMoreOptions: 'Meer opties',
  },
  shipping: {
    returnMethod: 'Verstuurmethode',
    pleaseSelectMethod: 'Selecteer een verzendmethode om uw pakketje terug te sturen',
  },
  review: {
    title: 'Controleer je gegevens',
    pageTitle: 'Controleer je gegevens',
    itemsToReceive: 'Te ontvangen product(en)',
    itemsToSendBack: 'Te versturen product(en)',
    shippingAddressTitle: 'Het product(en) zal verzonden worden naar',
    deliveryOptionTitle: 'Waar wil je je omruiling naartoe sturen?',
    deliveryOption: {
      billingAddress: 'Mijn thuisadres (factuuradres) of een adres toevoegen',
      pickupPoint: 'Een afhaalpunt uit een eerdere bestelling',
    },
    additionalRefundInfo:
      'We zullen het geld terugstorten op de rekening (of cadeaubon) die gebruikt is voor de oorspronkelijke transactie.',
    noItemsSelected:
      'Je hebt nog geen producten geselecteerd. Ga terug en selecteer minstens één product om te ruilen/retourneren.',
    submitReturn: 'Bevestig retour',
    submitReturnAndPay: 'Betaal voor retour label en bevestig retour',
    returnShippingCosts: 'Retour verzendkosten',
    removedDiscount: 'Bijbetaling van {{itemTitle}}',
    extraDiscount: 'Extra korting van {{itemTitle}}',
    refund: {
      title: 'Overzicht',
      calculationPriceIncreasement: 'Verrekening prijsverhoging(en)',
      valueOfReturnedProducts: 'Waarde van teruggestuurde producten',
      valueOfExchangeProducts: 'Waarde van te ruilen producten',
      appliedDiscountToOrder: 'Kortingen in oorspronkelijke order',
      totalRefundAmount: 'Totale terugbetaling',
      totalAdditionalPayment: 'Totaal bijbetaling',
      additionalPaymentInfo:
        'Er zijn openstaande betalingen. Zodra uw verzoek is goedgekeurd, sturen we een link om het openstaande bedrag te betalen naar: {{email}}',
      shippingCostRefundInfo:
        'Als je verzendkosten hebt betaald voor je order dan krijg je ook deze terug gestort. Deze staan niet hierboven vermeld maar worden toegevoegd aan dit bedrag als we je geld terugstorten.',
    },
    budbee: {
      typePhoneNumberHere: 'Typ hier uw mobiele telefoonnummer',
      hasBillingPhoneNumber:
        'We gebruiken {{phoneNumber}} voor je Budbee Pick-up. Wilt u een ander telefoonnummer gebruiken, vul dit dan hieronder in:',
      hasNoBillingPhoneNumber:
        'We hebben geen telefoonnummer van je. Budbee heeft dit nodig om je op de hoogte te houden van de Pick-up. Vul het hieronder in:',
      phoneNumberInvalid:
        'Uw telefoonnummer is ongeldig, vermeld de landcode en een "+", b.v. "+31987654321".',
    },
    sendcloud: {
      header: 'We hebben meer informatie nodig!',
      explanation:
        'Om de juiste informatie aan het verzendbedrijf te kunnen verstrekken, hebben we uw straatnaam en huisnummer nodig. Vul deze hieronder in, zodat we een retourzending voor u kunnen aanmaken.',
      streetNamePlaceholder: 'Straatnaam',
      streetNumberPlaceholder: 'Huisnummer',
      streetNameInvalid: 'Geen geldige straatnaam, vul alstublieft iets in.',
      streetNumberInvalid:
        "Geen geldig huisnummer. Het moet beginnen met een nummer, b.v. '42B' en de lengte ervan mag niet langer zijn dan 20 tekens.",
    },
    comment: {
      infoExchange:
        'Wil je het bovenstaande adres aanpassen of heb je een andere opmerking? Voeg dan een opmerking toe.',
      infoRefund: 'Mocht je nog vragen of opmerkingen hebben, voeg dan een opmerking toe.',
      addComment: 'Opmerking toevoegen',
      clearComment: 'Opmerking verwijderen',
      addAddress: 'Nieuw adres toevoegen',
      clearAddress: 'Verwijder nieuw adres',
      commentIsEmpty: 'Dit veld mag niet leeg zijn.',
      commentIsTooShort: 'Je opmerking moet minstens {{count}} tekens bevatten.',
    },
    error: {
      notAccepted: 'Deze order is al aangemeld voor retour.',
    },
  },
  summary: {
    title: 'Jouw retour overzicht',
    pageTitle: 'Retour overzicht',
    generatingLabel: 'Verwerken van retour label verzoek',
    submittingPickUp: 'Je Budbee-ophaalverzoek wordt ingediend',
    thanksSubmittingBudbee:
      'De geschatte ophaaltijd voor je pakket is op {{day}} tussen {{start}} en {{stop}}. Als je het ophalen wilt volgen of wijzigen, gebruik dan de Budbee-app met het telefoonnummer dat je bij deze retour hebt opgegeven: {{phoneNumber}}.',
    labelReady: 'Je verzendetiket is klaar om te printen',
    paymentLinkReady: 'Klik op de onderstaande knop als je niet wordt ge-redirect',
    thanksSubmitting: 'Dank u voor het indienen van uw retourzending',
    noLabel:
      'Het lijkt erop dat we het retouretiket niet kunnen downloaden. Neem even contact op met {{ supportEmail }}. Dan kunnen we het je toesturen.',
    labelNotPaid:
      'Het lijkt erop dat je retourlabel nog niet is betaald, gebruik de onderstaande knop om het betaalproces opnieuw te starten. Heb je wel betaald en zie je deze melding, neem dan even contact op met {{ supportEmail }}',
    downloadLabel: 'Download verzendetiket',
    redirectToPayment: 'Ga verder naar betaling',
    restartPayment: 'Start het betaalproces opnieuw',
    mailWillBeSentTo: 'Verzendetiket & instructies zijn ook per mail verstuurd naar',
    mailWillBeSentToOnlyInstructions: 'Instructies zijn ook per mail verstuurd naar',
    mailWillBeSentToPaperless: 'We hebben de retour instructies per mail verstuurd naar',
    mailWillBeSentToPaperlessSub:
      'Deze e-mail bevat ook een barcode die je nodig hebt als je je retour afgeeft.',
    howToTitle: 'Hoe retourneer je je producten',
    itemsToSendBack: 'Terug te sturen product(en)',
    itemsToReceive: 'Te ontvangen product(en)',
    estimatedProcessingTime:
      'Het duurt ongeveer {{count}} werkdag(en) om jouw retourzending te verwerken nadat je het pakketje hebt ingeleverd.',
    commercialInvoiceReady: 'Customs Invoice Ready',
    downloadCommercialInvoice: 'Download customs invoice',
    noCommercialInvoice:
      'It seems that something went wrong while generating the customs invoice.  Contact us at {{ supportEmail }} and we will send you the correct invoice to attach to your return.',
  },
  rma: {
    option: {
      genericExchange: 'Product ruilen',
      genericReturn: 'Product retourneren',
    },
    warning: {
      alreadyReturned: 'Product is al teruggestuurd',
      rmaInProcess: 'Product is al aangemeld',
      unfulfilledLineItem: 'Product is nog niet verzonden',
      noReturnGiftCards: 'Cadeaukaarten kunnen niet worden teruggestuurd',
      noPhysicalProduct: 'Digitale producten kunnen niet worden teruggestuurd',
      returnWindowExpired: 'Retourperiode verstreken',
      countryNotReturnable: 'Helaas kunnen we dit artikel niet vanuit uw land accepteren',
      notEligibleForReturn:
        'Dit product komt niet in aanmerking voor retournering, voor vragen kunt u contact opnemen met de klantenservice',
    },
  },
  pageNotFound: {
    title: '404',
    body: 'Pagina niet gevonden',
  },
  shopNotFound: {
    title: '403',
    body: 'Toegang niet toegestaan',
  },
  noApp: {
    title: 'Browser niet ondersteund',
    notPossible: 'Ruilen/retourneren is helaas niet mogelijk via Internet Explorer.',
    useDifferentBrowser: 'Gebruik een andere browser',
    orVisitWithPhone: 'of bezoek deze pagina via met uw mobiel.',
  },
  delivery: {
    other: 'Verstuur naar een ander adres',
    otherAddress: 'Gebruik het opmerkingenveld om een nieuw adres in te voeren',
  },
};
