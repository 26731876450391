import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Footer from './components/Footer';
import Head from './components/Head/Head';
import ItemsList from './components/ItemsList/ItemsList';
import { Logo } from './components/Logo';
import OrderLookup from './components/OrderLookup/OrderLookup';
import { PageNotFound } from './components/PageNotFound';
import { ProtectedRoute } from './components/ProtectedRoute';
import Review from './components/Review/Review';
import ScrollToTop from './components/ScrollToTop';
import Summary from './components/Summary/Summary';
import DownloadLabel from './components/DownloadLabel/DownloadLabel';
import WizardProvider from './contexts/WizardContext/WizardProvider';
import { Shipping } from './components/Shipping/Shipping';
import { usePublicConfig } from './firebase/hooks';
import { useFirebase } from './firebase/context';

export const App = () => {
  const firebase = useFirebase();
  const publicConfig = usePublicConfig();

  const customDomainRouteSwitch = () => {
    return (
      <Switch>
        <Route exact path="/" component={OrderLookup} />
        <ProtectedRoute exact path="/exchange" component={ItemsList} />
        <ProtectedRoute exact path="/shipping" component={Shipping} />
        <ProtectedRoute exact path="/review" component={Review} />
        <ProtectedRoute exact path="/summary" component={Summary} />
        <ProtectedRoute exact path="/download-label" component={DownloadLabel} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    );
  };

  const defaultRouteSwitch = () => {
    return (
      <Switch>
        <Route exact path="/" component={PageNotFound} />
        <Route exact path="/:shop" component={OrderLookup} />
        <ProtectedRoute exact path="/:shop/exchange" component={ItemsList} />
        <ProtectedRoute exact path="/:shop/shipping" component={Shipping} />
        <ProtectedRoute exact path="/:shop/review" component={Review} />
        <ProtectedRoute exact path="/:shop/summary" component={Summary} />
        <ProtectedRoute exact path="/:shop/download-label" component={DownloadLabel} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    );
  };

  // TODO: try to use the 'basename' property of the 'BrowserRouter'; this will
  // remove the need for two different <Switch> components
  const routeSwitch = () => {
    if (firebase.hasCustomDomain()) return customDomainRouteSwitch();

    return defaultRouteSwitch();
  };

  return (
    <div className="app-container">
      <Head />
      <WizardProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Logo src={publicConfig.theme.logo} />
          {routeSwitch()}
          {publicConfig && <Footer />}
        </BrowserRouter>
      </WizardProvider>
    </div>
  );
};
