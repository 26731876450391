import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useFirebase } from '../../firebase/context';
import {
  WizardContext,
  WizardAction,
  ExchangeAction,
  BaseWizardData,
  mapWizardActionToPayloadAction,
} from './WizardContext';
import { getCurrencyFormat } from '../../helpers/getCurrencyFormat';
import { useAdjustments } from '../../hooks/useAdjustments';
import { LoadedSessionData } from '../../firebase/firebase';
import { DerivedWizardData } from './WizardContext';
import {
  AppliedAdjustmentsData,
  calculateLineItemAdjustments,
  deriveOrder,
  deriveRootOrder,
  getExchangeValues,
} from '../../../../../functions/src/shared';
import { createRmaItems } from '../../components/Review/submitReturn';

// define key for local storage
const storeKey = `rmaWizard-${process.env.REACT_APP_BUILD ?? ''}`;

const getInitialData = (): BaseWizardData => {
  const storedString = localStorage.getItem(storeKey);
  // get initial data from local storage if it has been set or set defaults.
  const stored = storedString && JSON.parse(storedString);

  if (stored) {
    return stored;
  }

  return {
    collectedWizardData: {},
    isReturnAlreadySubmitted: false,
  };
};

const deriveValues = (
  { collectedWizardData, returnOption }: BaseWizardData,
  adjustments: AppliedAdjustmentsData | null,
  sessionData: LoadedSessionData | undefined,
  locale: string | undefined,
): DerivedWizardData => {
  const { order, config: globalConfig, discounts, rmaSummary } = sessionData ?? {};
  const { allowAdditionalPayment, lineItemDiscountsProvider } = globalConfig ?? {};
  const currency = order?.currency;

  // add discounts that were non-allocated and add them when all items are refunded
  const unallocatedDiscounts = discounts?.totalNonAllocatedDiscount || 0;
  const totalItemsToRefund = (rmaSummary?.lineItems || []).reduce((total, current) => {
    return total + current.refundedAt.filter((value) => value === null).length;
  }, 0);

  const actions = Object.values(collectedWizardData ?? {}).filter(
    (item): item is WizardAction => item != null,
  );

  const exchangeActions = actions.filter(
    (item): item is ExchangeAction => item.action === 'exchange',
  );

  const hasActions = actions.length > 0;
  const hasExchanges = exchangeActions.length > 0;

  const rmaItemsInProgress = createRmaItems(collectedWizardData);
  const currentState = order && deriveRootOrder(order);
  const nextState = currentState && deriveOrder(currentState, rmaItemsInProgress);
  const keptItemsAdjustments =
    nextState && lineItemDiscountsProvider
      ? calculateLineItemAdjustments(nextState, lineItemDiscountsProvider)
      : [];

  const returnCreditBeforeDiscounts = hasActions
    ? actions.reduce((total, { initialItem: { originalPrice } }) => {
        return (total += Number(originalPrice));
      }, 0)
    : 0;

  const { returnCredit, orderPriceCompensations } = hasActions
    ? actions.reduce<{ returnCredit: number; orderPriceCompensations: number }>(
        (prices, item) => {
          const { initialItem, exchangeFor } = item;
          const { discountedPrice, originalPrice } = initialItem;

          const paidPrice = discountedPrice ?? originalPrice;

          const { originalValue } =
            exchangeFor && currentState && nextState
              ? getExchangeValues(
                  currentState,
                  nextState,
                  mapWizardActionToPayloadAction(item),
                  {
                    originalProductId: initialItem.product.id,
                    originalVariantId: initialItem.variant.id,
                    originalVariantCurrentPrice: initialItem.currentVariantPrice,
                    originalPrice: initialItem.originalPrice,
                    originalProductTags: initialItem.product.tags,
                    originalDiscountedPrice: paidPrice,
                    exchangeProductId: exchangeFor.product.id,
                    exchangeVariantId: exchangeFor.variant.id,
                    exchangeVariantCurrentPrice: Number(exchangeFor.variant.price),
                    exchangeProductTags: exchangeFor.product.tags,
                  },
                  globalConfig,
                )
              : {
                  originalValue: paidPrice,
                };

          // determine if we have a 'price increase' of the product
          let itemPriceCompensation = 0;
          const hasPriceIncreased = initialItem.priceBand[0] !== initialItem.priceBand[1];

          /*
            initialItem.priceBand[0] => Lowest price (price in the order)
            initialItem.priceBand[1] => Max allowed highest price (new price)
          */

          // only if it is the boundaries
          if (hasPriceIncreased && originalValue <= initialItem.priceBand[1]) {
            itemPriceCompensation += originalValue - initialItem.priceBand[0]; // calculate the difference
          }

          return {
            returnCredit: (prices.returnCredit += originalValue),
            orderPriceCompensations: (prices.orderPriceCompensations += itemPriceCompensation),
          };
        },
        { returnCredit: 0, orderPriceCompensations: 0 },
      )
    : { returnCredit: 0, orderPriceCompensations: 0 };

  const amountOwedExchanges = hasExchanges
    ? exchangeActions.reduce((total, item) => {
        const { initialItem, exchangeFor } = item;
        const { discountedPrice, originalPrice } = initialItem;

        const { exchangeDiscountedPrice } =
          exchangeFor && currentState && nextState
            ? getExchangeValues(
                currentState,
                nextState,
                mapWizardActionToPayloadAction(item),
                {
                  originalProductId: initialItem.product.id,
                  originalVariantId: initialItem.variant.id,
                  originalVariantCurrentPrice: initialItem.currentVariantPrice,
                  originalPrice: initialItem.originalPrice,
                  originalProductTags: initialItem.product.tags,
                  originalDiscountedPrice: discountedPrice ?? originalPrice,
                  exchangeProductId: exchangeFor.product.id,
                  exchangeVariantId: exchangeFor.variant.id,
                  exchangeVariantCurrentPrice: Number(exchangeFor.variant.price),
                  exchangeProductTags: exchangeFor.product.tags,
                },
                globalConfig,
              )
            : {
                exchangeDiscountedPrice:
                  Math.floor(Number(item.exchangeFor.variant.price) * 100) / 100,
              };

        return (total += exchangeDiscountedPrice);
      }, 0)
    : 0;

  const amountOwedKeptItemAdjustments = keptItemsAdjustments.reduce(
    (acc, adj) => acc + adj.amount,
    0,
  );
  const amountOwed = amountOwedExchanges + amountOwedKeptItemAdjustments;

  // set order discounts, but only if all items that were available are refunded
  const orderDiscount =
    actions.length === totalItemsToRefund && exchangeActions.length === 0
      ? unallocatedDiscounts
      : 0;
  const formattedOrderDiscount = '-' + getCurrencyFormat(orderDiscount, currency, locale);
  const totalAdjustments = (adjustments?.totalAdjustments || 0) / 100;

  const extraRefunds =
    totalAdjustments - amountOwed - orderDiscount - (returnOption?.price?.amount ?? 0) / 100;

  // do a math.round because sometimes this calculation ends up with more than 2 decimals somehow
  const refundTotal = Math.round((returnCredit + extraRefunds) * 100) / 100;
  const refundTotalBeforeDiscount =
    Math.round((returnCreditBeforeDiscounts + extraRefunds) * 100) / 100;

  let returnCreditCompensated = returnCredit;
  if (orderPriceCompensations) returnCreditCompensated -= orderPriceCompensations;
  const formattedOrderPriceCompensations = getCurrencyFormat(
    orderPriceCompensations,
    currency,
    locale,
  );
  const formattedRefundTotal = getCurrencyFormat(
    allowAdditionalPayment && refundTotal < 0 ? -refundTotal : refundTotal,
    currency,
    locale,
  );
  const formattedRefundTotalBeforeDiscount = getCurrencyFormat(
    allowAdditionalPayment && refundTotalBeforeDiscount < 0
      ? -refundTotalBeforeDiscount
      : refundTotalBeforeDiscount,
    currency,
    locale,
  );

  // additional adjustment lines
  const adjustmentLines = adjustments?.adjustments?.map((adjustment) => ({
    ...adjustment,
    formattedAmount: getCurrencyFormat(adjustment.amount / 100, currency, locale),
  }));

  return {
    nrOfSelectedItems: actions.length,
    allActions: actions,
    exchangeActions,
    hasActions,
    hasExchangeActions: hasExchanges,
    returnCredit: returnCreditCompensated,
    orderPriceCompensations,
    orderDiscount,
    amountOwedExchanges,
    amountOwedKeptItemAdjustments,
    amountOwed,
    refundTotal,
    refundTotalBeforeDiscount,
    returnCreditBeforeDiscounts,
    formattedOrderPriceCompensations,
    formattedOrderDiscount,
    formattedRefundTotal,
    formattedRefundTotalBeforeDiscount,
    adjustmentLines,
    keptItemsAdjustments,
  };
};

const WizardProvider = ({ children }: PropsWithChildren) => {
  const { loadedSessionData: sessionData, publicConfig } = useFirebase();
  const [baseWizardData, updateWizardData] = useState<BaseWizardData>(getInitialData());
  const adjustments = useAdjustments(baseWizardData, sessionData);

  const setWizardData = useCallback(
    (data: Partial<BaseWizardData>) => {
      const { order } = sessionData ?? {};

      const currency = order?.currency;

      const newState: BaseWizardData = {
        ...baseWizardData,
        ...data,
        currency,
      };

      // set the new wizard state to local storage
      localStorage.setItem(storeKey, JSON.stringify({ ...newState }));

      updateWizardData(newState);
    },
    [baseWizardData, sessionData],
  );

  const derivedWizardData = useMemo(
    (): DerivedWizardData =>
      deriveValues(baseWizardData, adjustments, sessionData, publicConfig?.language),
    [baseWizardData, adjustments, sessionData, publicConfig?.language],
  );

  const resetWizard = () => {
    localStorage.removeItem(storeKey);
    setWizardData(getInitialData());
  };

  return (
    <WizardContext.Provider
      value={{
        resetWizard,
        setWizardData,
        ...baseWizardData,
        ...derivedWizardData,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};

export default WizardProvider;
