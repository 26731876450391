import { DerivedOrderState, SfyDiscountApplicationType, SfyLineItem } from "../..";
import { LineItemDiscountProviderFunc } from "../LineItemDiscountsProvider";
import { agradiDiscountSettings } from "./settings";

type LineItemProperty = SfyLineItem["properties"][number];

export const fetchAgradiLineItemDiscounts: LineItemDiscountProviderFunc = (
  originalLineItem,
  // Defined when the line item is an exchange
  nextLineItem,
  nextOrderState,
) => {
  const lineItemProperties = "properties" in originalLineItem ? originalLineItem.properties : [];

  const appliedDiscountProps = lineItemProperties.filter(
    (prop) =>
      prop.name.includes(agradiDiscountSettings.appliedDiscountPrefix) &&
      prop.name !== agradiDiscountSettings.totalDiscountKey && // Stupid enough these share same prefix
      prop.name !== agradiDiscountSettings.originalPriceKey, // Stupid enough these share same prefix
  );

  const discountsToTransfer = appliedDiscountProps.filter((prop) => {
    if (isSetDiscount(prop)) {
      const setProp = lineItemProperties.find(
        (searchProp) => searchProp.name === agradiDiscountSettings.setDiscountKey,
      );

      if (!setProp) return false;

      return isSetStillValid(nextOrderState, setProp.value);
    }

    return true;
  });

  const originalAppliedDiscounts = discountsToTransfer.map(
    (appliedDiscount): SfyDiscountApplicationType => {
      const discount: SfyDiscountApplicationType = {
        value_type: "percentage",
        value: (Number(appliedDiscount.value) * 100).toString(),
        allocationMethod: "across",
        code: null,
        description: null,
        targetSelection: "explicit",
        targetType: "line_item",
        title: "Unknown Discount",
        type: "manual",
      };

      if (isMemberPartnerDiscount(appliedDiscount)) {
        discount.title = "Member Discount";
      }

      if (isSetDiscount(appliedDiscount)) {
        const setProp = lineItemProperties.find(
          (searchProp) => searchProp.name === agradiDiscountSettings.setDiscountKey,
        );

        if (!setProp) {
          // We throw since above the applied discount should've been filtered out if prop couldn't be found
          throw new Error("Set discount property not found");
        }

        discount.title = `Set Discount - ${setProp.value}`;
      }

      return discount;
    },
  );

  // For now all types of discounts are kept
  const updatedDiscounts = [...originalAppliedDiscounts];

  return updatedDiscounts;
};

const isMemberPartnerDiscount = (prop: LineItemProperty) =>
  agradiDiscountSettings.memberPartnerGroups.some(
    (name) => prop.name === `${agradiDiscountSettings.appliedDiscountPrefix}${name}`,
  );

const isSetDiscount = (prop: LineItemProperty) =>
  prop.name ===
  agradiDiscountSettings.appliedDiscountPrefix + agradiDiscountSettings.badgeSetKeyword;

const isSetStillValid = (nextOrderState: DerivedOrderState, setId: string) => {
  const setItems = nextOrderState.lineItems.filter(
    (lineItem) =>
      "properties" in lineItem &&
      lineItem.properties.some(
        (prop) => prop.name === agradiDiscountSettings.setDiscountKey && prop.value === setId,
      ),
  );

  return setItems.length > 1;
};
