import { LoadedSessionData } from '../firebase/firebase';

/**
 * Extracts the processing time for this order's country.
 * Returns undefined if no processing time exists.
 * @param sessionData
 */
export const getEstimatedProcessingTime = (sessionData: LoadedSessionData): number | undefined => {
  const { countryCode } = sessionData.shippingAddress;
  const countrySettings = sessionData.config.countrySettings?.[countryCode || 'default'];

  return countrySettings?.shippingTime ?? countrySettings?.processingTime ?? undefined;
};
