import React from 'react';
import { useDefaultAppTexts } from '../hooks/useAppTexts';

export const ShopNotFound = () => {
  const appTexts = useDefaultAppTexts();

  return (
    <div className="app-container">
      <div className="box page-not-found-box">
        <h1 className="title is-1">{appTexts.shopNotFound.title}</h1>
        <p className="page-not-found-body">{appTexts.shopNotFound.body}</p>
      </div>
    </div>
  );
};
