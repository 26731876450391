import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';
import { WizardContext } from '../../contexts/WizardContext/WizardContext';
import { useFirebase } from '../../firebase/context';
import { warmUpFunctions } from '../../helpers/warmUpFunctions';
import { interpolateAppText, useAppTexts } from '../../hooks/useAppTexts';
import { useNonNullableContext } from '../../hooks/useNonNullableContext';
import { Notification } from '../Notification';
import { submitOrder } from './submitOrder';
import { usePublicConfig } from '../../firebase/hooks';

const OrderLookup = () => {
  const appTexts = useAppTexts();
  const { resetWizard } = useNonNullableContext(WizardContext);
  const firebase = useFirebase();
  const publicConfig = usePublicConfig();
  const { auth, authenticate, fetchInitialData, shopName } = firebase;
  const history = useHistory();
  const location = useLocation();
  const { appTitle, supportEmail, loginVerification } = publicConfig;

  const initData = {
    orderNr: '',
    secret: '',
  };

  const [formData, setFormData] = useState<Record<string, any>>(initData);
  const [formValidation, setFormValidation] = useState<Partial<Record<string, string>>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState('');

  const params = useMemo(() => {
    return Object.fromEntries(new URLSearchParams(location.search).entries());
  }, [location.search]);

  const ifHasUrlParams = () => {
    // exit if any required param is missing
    if (!params.order || !(params.zipcode || params.emailAddress || params.secret)) {
      return;
    }

    // when a redirect uri is present the user will be send to this url.
    // for instance the download-label page (Monta)
    const redirect = params?.redirect || 'exchange';
    const rmaRequestId = params?.request || undefined;
    const signature = params?.signature || undefined;

    const dataFromUrl = {
      orderNr: params.order,
      secret: params.zipcode || params.emailAddress || params.secret,
      redirect,
      rmaRequestId,
      signature,
    };

    // set params to state
    setFormData(dataFromUrl);

    // submit form
    return submitFromUrlParams(dataFromUrl);
  };

  // on first load effect
  useEffect(() => {
    (async () => {
      // check if this is a new login
      let isNewLogin = false;
      if (params.order || params.zipcode || params.emailAddress || params.secret) {
        isNewLogin = true;
      }

      if (auth.currentUser && !isNewLogin) {
        // redirect to exchange view if user is authenticated.
        history.push(firebase.getPathTo('exchange'));
        return;
      }

      // if not authenticated, warm up the user session cloud function
      if (isNewLogin) {
        await firebase.auth.signOut();
        // reset stored wizard data
        resetWizard();
      } else {
        warmUpFunctions('/CustomerSession/warmup');
      }

      // check if url params provided and submit if any
      ifHasUrlParams();
    })();
    // eslint-disable-next-line
  }, []);

  const handleFieldValueChange = (newValue: any, key: string) => {
    setFormData({
      ...formData,
      [key]: newValue,
    });

    // reset validation and error states
    setFormValidation({
      ...formValidation,
      [key]: '',
    });

    setFormError('');
  };

  const checkEmailAddres = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  };

  const handleFormValidation = () => {
    // validate form fields
    let validation = {};

    Object.keys(formData).forEach((key) => {
      // check if empty
      if (formData[key] === '') {
        validation = {
          ...validation,
          [key]: appTexts.generic.form.fieldRequired,
        };
      }

      if (
        key === 'secret' &&
        formData[key] &&
        (loginVerification === 'emailaddress' || formData[key].includes('@'))
      ) {
        if (!checkEmailAddres(formData[key])) {
          validation = {
            ...validation,
            [key]: appTexts.generic.form.invalidEmail,
          };
        }
      }
    });

    // set validation to state
    setFormValidation(validation);

    // return true if form invalid
    if (Object.keys(validation).length > 0 && validation.constructor === Object) {
      return true;
    } else {
      return false;
    }
  };

  function submitFromUrlParams({
    orderNr,
    secret,
    redirect,
    rmaRequestId,
    signature,
  }: {
    orderNr: string;
    secret: string;
    redirect: string;
    rmaRequestId?: string;
    signature?: string;
  }) {
    const search =
      rmaRequestId && signature
        ? `?order=${orderNr}&secret=${secret}&request=${rmaRequestId}&signature=${signature}`
        : '';

    // submit form
    return doSubmit({
      orderNr,
      secret,
      redirect,
      search,
    });
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // exit if form invalid
    if (handleFormValidation()) {
      return;
    }

    doSubmit({
      orderNr: formData.orderNr,
      secret: formData.secret,
      redirect: 'exchange',
      search: '',
    });
  };

  const doSubmit = async ({
    orderNr,
    secret,
    redirect,
    search,
  }: {
    orderNr: string;
    secret: string;
    redirect: string;
    search: string;
  }) => {
    setIsLoading(true);

    try {
      if (!shopName) {
        throw new Error('Shop name is missing');
      }

      // get a token if order exists
      const token = await submitOrder(orderNr, secret, shopName, appTexts, supportEmail);

      await authenticate(token);

      resetWizard();

      // fetch remaining data
      const { order } = await fetchInitialData(publicConfig.language);

      // TODO (CODERE-348): Once migration logic is in, migrate all shops to have cu-rrency in their public document
      if (shopName === 'alphadijk.myshopify.com' && order.currency !== 'EUR') {
        throw interpolateAppText(appTexts.orderLookup.error.currencyNotSupported, {
          supportedCurrency: 'EUR',
          supportEmail,
        });
      }

      // redirect on successful fetch
      history.push({
        pathname: firebase.getPathTo(redirect),
        search,
      });
    } catch (error: unknown) {
      if (typeof error === 'string') {
        setFormError(error);
      } else {
        setFormError(appTexts.orderLookup.error.notFound);
      }
    }

    setIsLoading(false);
  };

  const orderLookupMarkup = (
    <div className="box order-lookup-box">
      <h1 className="order-lookup-title">{appTexts.orderLookup.title}</h1>
      {appTexts.orderLookup.infoMessage && (
        <Notification>{appTexts.orderLookup.infoMessage}</Notification>
      )}

      <form onSubmit={handleSubmit}>
        <div className="field">
          <div className="control">
            <input
              className={`input ${formValidation.orderNr ? 'is-danger' : ''}`}
              type="text"
              id="cy-order-number-input"
              placeholder={appTexts.orderLookup.orderNumber}
              value={formData.orderNr}
              onChange={(event) => handleFieldValueChange(event.target.value, 'orderNr')}
              disabled={isLoading}
            />
          </div>
          <p className="help is-danger">{formValidation.orderNr}</p>
        </div>
        <div className="field">
          <div className="control">
            <input
              className={`input ${
                formValidation.postCode || formValidation.email ? 'is-danger' : ''
              }`}
              id="cy-secret-input"
              type="text"
              placeholder={appTexts.orderLookup[loginVerification]}
              value={formData.secret}
              onChange={(event) => handleFieldValueChange(event.target.value, 'secret')}
              disabled={isLoading}
            />
          </div>
          <p className="help is-danger">{formValidation.secret}</p>
        </div>
        <div className="field">
          <div className="control">
            <button
              type="submit"
              id="cy-submit-lookup-button"
              className={`button is-primary is-fullwidth ${isLoading ? 'is-loading' : ''}`}
            >
              {appTexts.orderLookup.submit}
            </button>
          </div>
        </div>
        <Notification hide={!formError || formError === ''} status="danger">
          <p id="cy-lookup-error">{formError}</p>
        </Notification>
      </form>
    </div>
  );

  return (
    <React.Fragment>
      {/* @ts-ignore see https://github.com/staylor/react-helmet-async/issues/182 */}
      <Helmet>
        <title>{`${appTitle} - ${appTexts.orderLookup.pageTitle}`}</title>
      </Helmet>
      {orderLookupMarkup}
    </React.Fragment>
  );
};

export default OrderLookup;
