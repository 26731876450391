import React, { useState } from 'react';
import { interpolateAppText, useAppTexts } from '../../hooks/useAppTexts';

import { MontaPaymentRequired } from '../MontaPaymentRequired';
import { ReviewSendcloudReturn } from './ReviewSendcloudReturn';
import budbee from './assets/budbee-round.png';
import { SelectedBudbeePickup, SelectedCarrier } from '../../../../../functions/src/shared';

interface Props {
  language: string;
  returnOption: SelectedCarrier;
  handlePhoneNumberValueChange: (value: string) => void;
  handleStreetAddressChange: (data: { streetName: string; streetNumber: string }) => void;
}

export const ReviewReturn = ({
  language,
  returnOption,
  handlePhoneNumberValueChange,
  handleStreetAddressChange,
}: Props) => {
  if (returnOption.labelProvider === 'budbee' && returnOption.method === 'budbee-pickup')
    return (
      <ReviewBudbeeReturn
        returnOption={returnOption}
        handlePhoneNumberValueChange={handlePhoneNumberValueChange}
      />
    );
  if (returnOption.labelProvider === 'monta')
    return (
      <div className="review-block">
        <div className="box">
          <MontaPaymentRequired language={language} returnOption={returnOption} inline={true} />
        </div>
      </div>
    );
  if (returnOption.labelProvider === 'sendcloud')
    return <ReviewSendcloudReturn handleStreetAddressChange={handleStreetAddressChange} />;

  return null;
};

const ReviewBudbeeReturn = ({
  returnOption,
  handlePhoneNumberValueChange,
}: {
  returnOption: SelectedBudbeePickup;
  handlePhoneNumberValueChange: (value: string) => void;
}) => {
  const appTexts = useAppTexts();
  const [phone, setPhone] = useState(returnOption.phoneNumber ?? '');

  const phoneNumberText = returnOption.phoneNumber
    ? interpolateAppText(appTexts.review.budbee.hasBillingPhoneNumber, {
        phoneNumber: returnOption.phoneNumber,
      })
    : appTexts.review.budbee.hasNoBillingPhoneNumber;

  return (
    <div className="review-block">
      <div className="box">
        <div className="review-budbee">
          <img src={budbee} alt="Budbee logo" />
          <h1>Budbee Pick-up</h1>
        </div>
        {phoneNumberText}
        <div className="field">
          <div className="control">
            <input
              className={`input review-budbee-phonenumber`}
              type="phone"
              placeholder={appTexts.review.budbee.typePhoneNumberHere}
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
                handlePhoneNumberValueChange(event.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
