import { defaultAppTexts } from '../contexts/AppTextsContext/defaultAppTexts';
import { useFirebase } from '../firebase/context';
import { AppTexts } from '../../../../functions/src/shared';
import merge from 'lodash/merge';
import { useMemo } from 'react';

const FALLBACK_LANGUAGE = 'en';

export const useDefaultAppTexts = (): AppTexts => {
  return defaultAppTexts[FALLBACK_LANGUAGE];
}

export const useAppTexts = (): AppTexts => {
  const { publicConfig } = useFirebase();

  return useMemo(() => {
    const defaultTexts =
      defaultAppTexts[publicConfig?.language || FALLBACK_LANGUAGE] ||
      defaultAppTexts[FALLBACK_LANGUAGE];
    const mergedTexts = merge(defaultTexts, publicConfig?.texts || {});

    return mergedTexts;
  }, [publicConfig?.language, publicConfig?.texts]);
};

/**
 * Interpolate double braced template string
 * @param text
 * @param itemsToInterpolate a map of key/value pairs of strings that need replacing
 */
export const interpolateAppText = (
  text: string,
  itemsToInterpolate: Record<string, string | undefined>,
) => {
  if (typeof itemsToInterpolate !== 'object') return text;

  let interpolated = text;

  for (const [key, value] of Object.entries(itemsToInterpolate)) {
    const regex = new RegExp('{{(?:\\s+)?(' + key + ')(?:\\s+)?}}');
    interpolated = interpolated.replace(regex, value ?? '');
  }

  return interpolated;
};
