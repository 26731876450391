import { useEffect, useState } from 'react';
import app from 'firebase';
import { useFirebase } from '../../../firebase/context';
import { BaseRmaRequest } from '../../../../../../functions/src/shared';

export const usePreviousRmas = (orderId: number) => {
  const firebase = useFirebase();
  const [rmaRequests, setRmaRequests] = useState<BaseRmaRequest<app.firestore.Timestamp>[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsub = firebase
      .rmaRequestsCollection()
      .where('orderId', '==', orderId)
      .onSnapshot((snapshot) => {
        const rmaRequests = snapshot.docs.map(
          (doc) => doc.data() as BaseRmaRequest<app.firestore.Timestamp>,
        );

        setRmaRequests(rmaRequests);
        setIsLoading(false);
      });

    return () => unsub();
  }, [firebase, orderId]);

  return [rmaRequests, isLoading] as const;
};
