/**
 * Format numbers to locale currency format using Intl​.Number​Format
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
 *
 * @param number   The number to be formatted
 * @param currency ISO 4217 currency code
 * @param locale
 */

export const getCurrencyFormat = (
  number: number | string | null,
  currency: string | undefined,
  locale: string | undefined,
) => {
  if (!currency || !locale) return;

  const formatted = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(Number(number));

  return formatted;
};
