import { useFirebase } from './context';

export const usePublicConfig = () => {
  const { publicConfig } = useFirebase();

  if (!publicConfig) {
    throw new Error('usePublicData: public config not loaded');
  }

  return publicConfig;
};

export const useSessionData = () => {
  const { loadedSessionData } = useFirebase();

  if (!loadedSessionData) {
    throw new Error('useSessionData: session data not loaded');
  }

  return loadedSessionData;
};

export const useLoadedFirebaseData = () => {
  const { publicConfig, loadedSessionData } = useFirebase();

  if (!publicConfig || !loadedSessionData) {
    throw new Error('useLoadedFirebaseData: firebase data not loaded');
  }

  return { publicConfig, ...loadedSessionData };
};
