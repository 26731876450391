import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { isIE } from 'react-device-detect';
import React from 'react';
import ReactDOM from 'react-dom';
import { FirebaseContextProvider } from './firebase/provider';
import { HelmetProvider } from 'react-helmet-async';
import './icons/library';
import './styles/App.scss';
import { App } from './App';
import { NoApp } from './NoApp';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    {/** @ts-ignore types of helmetprovider are not correct */}
    <HelmetProvider>
      <FirebaseContextProvider>{isIE ? <NoApp /> : <App />}</FirebaseContextProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
