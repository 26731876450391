import React from 'react';
import { useLoadedFirebaseData } from '../../../firebase/hooks';
import { NoImageFound } from '../../NoImageFound';
import { Notification } from '../../Notification';
import { getCurrencyFormat } from '../../../helpers/getCurrencyFormat';
import { resizeImage } from '../../../helpers/resizeImage';
import { formatProductOptions } from '../../../helpers/formatProductOptions';
import { useAppTexts } from '../../../hooks/useAppTexts';
import {
  CollectedWizardData,
  ExchangeAction,
  ExchangeWizardItem,
  FullWizardItem,
  WizardContext,
  makeExchangeWizardItem,
  mapWizardActionToPayloadAction,
} from '../../../contexts/WizardContext/WizardContext';
import { useOrderData } from '../../../hooks/useOrderData';
import { useProductsByTag } from './useProductsByTag';
import {
  TProductData,
  deriveOrder,
  deriveRootOrder,
  getExchangeValues,
} from '../../../../../../functions/src/shared';
import { createRmaItems } from '../../Review/submitReturn';
import { useNonNullableContext } from '../../../hooks/useNonNullableContext';
import { getInventoryQuantityByLocationId } from '../../../helpers/getInventoryByLocation';

type Props =
  | {
      itemData: FullWizardItem;
      tag: string;
      mode: 'pick-product';
      onSubmit: (data: TProductData) => void;
    }
  | {
      itemData: FullWizardItem;
      tag: string;
      frozenOptions: string[];
      mode: 'pick-variant';
      onSubmit: (data: ExchangeAction['exchangeFor']) => void;
    };

const PickByTag = (props: Props) => {
  const appTexts = useAppTexts();
  const firebase = useLoadedFirebaseData();
  const { itemData } = props;

  const { config, publicConfig: appThemeData, order: sfyOrder } = firebase;
  const { order } = useOrderData();
  const { allowAdditionalPayment, inventoryThreshold, inventoryThresholdHideBelow, useOverallInventory, selectedInventoryLocations } = config;
  const { collectedWizardData } = useNonNullableContext(WizardContext);

  const [productData, isLoading] = useProductsByTag(props.tag, firebase.userData?.token ?? '');

  const optionsMarkup = productData?.flatMap<JSX.Element[]>((product, i) => {
    const productTitle = product.title;
    const { variants } = product;

    const variantsToShow =
      props.mode === 'pick-product'
        ? [variants[0]]
        : variants.filter((v) =>
            // Also returns true if frozeOptions is empty array
            props.frozenOptions.every((optionLabel) => {
              return itemData.variant.options[optionLabel] === v.options[optionLabel];
            }),
          );

    return variantsToShow.map((variant) => {
      const variantImage = resizeImage(variant.image, 1200, 1200);

      // get the lowest price
      const variantPrice =
        props.mode === 'pick-product'
          ? variants.reduce((lowestPrice, variant) => {
              if (lowestPrice === 0 || lowestPrice >= Number(variant.price))
                return Number(variant.price);
              return lowestPrice;
            }, 0)
          : Number(variant.price);

      // check if we're out of stock; considering also the inventoryThreshold value
      const isOutOfStock = !variants.find((variant) => {
        if (useOverallInventory || useOverallInventory === undefined) {
          return (
            variant.inventory_quantity >= (inventoryThreshold ?? 0) ||
            variant.inventory_policy === 'continue'
          );
        } else {
          return (
            getInventoryQuantityByLocationId(
              (selectedInventoryLocations || []).map((location) => location.id),
              variant.inventory_at_location || {},
            ) > (inventoryThreshold ?? 0) || variant.inventory_policy === 'continue'
          );
        }
      });

      if (isOutOfStock && inventoryThresholdHideBelow) return [];

      // define selected step data
      const exchangeFor: ExchangeWizardItem = {
        product,
        variant,
      };

      const newAction: ExchangeAction = {
        action: 'exchange',
        initialItem: itemData,
        exchangeFor,
      };

      const draftNewRma: CollectedWizardData = {
        ...collectedWizardData,
        [itemData.selectionId]: newAction,
      };

      const rmaItems = createRmaItems(draftNewRma);
      const currentState = deriveRootOrder(sfyOrder);
      const nextState = deriveOrder(currentState, rmaItems);

      const { originalExchangeValue, exchangeDiscountedPrice } = getExchangeValues(
        currentState,
        nextState,
        mapWizardActionToPayloadAction(newAction),
        {
          exchangeProductId: product.id,
          exchangeProductTags: product.tags,
          exchangeVariantCurrentPrice: variantPrice,
          exchangeVariantId: variant.id,
          originalDiscountedPrice: itemData.discountedPrice ?? itemData.originalPrice,
          originalPrice: itemData.originalPrice,
          originalProductId: itemData.product.id,
          originalProductTags: itemData.product.tags,
          originalVariantCurrentPrice: itemData.currentVariantPrice,
          originalVariantId: itemData.variant.id,
        },
        config,
      );

      const isPriceTooHigh = exchangeDiscountedPrice > originalExchangeValue;
      const priceDifference = isPriceTooHigh ? exchangeDiscountedPrice - originalExchangeValue : 0;
      const isSelectable = !((!allowAdditionalPayment && isPriceTooHigh) || isOutOfStock);

      const exchangeForSpecificVariant = makeExchangeWizardItem(product, variant);

      return [
        <div key={i} className="column is-half-mobile is-half-tablet is-one-third-desktop">
          <div
            className={`card item-wizard-card ${!isSelectable ? 'is-disabled' : ''}`}
            onClick={
              isSelectable
                ? () => {
                    props.mode === 'pick-product'
                      ? props.onSubmit(product)
                      : props.onSubmit(exchangeForSpecificVariant);
                  }
                : undefined
            }
          >
            <div className="item-wizard-card-image">
              {variantImage ? <img src={variantImage} alt={productTitle} /> : <NoImageFound />}
              {isOutOfStock && (
                <div className="item-wizard-card-outofstock">
                  <span className="tag is-danger">{appTexts.itemWizard.outOfStock}</span>
                </div>
              )}
            </div>
            <footer className="item-wizard-card-footer">
              <div className="item-wizard-card-row item-wizard-card-title">{productTitle}</div>
              {props.mode === 'pick-variant' && (
                <div className="item-wizard-card-row item-wizard-card-options">
                  {formatProductOptions(product.options)} {variant.title}
                </div>
              )}
              <div
                className={`item-wizard-card-price ${
                  !allowAdditionalPayment && isPriceTooHigh ? 'is-too-high' : ''
                } ${props.mode === 'pick-variant' ? 'item-wizard-card-row' : ''}`}
              >
                <span>
                  {getCurrencyFormat(
                    exchangeDiscountedPrice,
                    order.currency,
                    appThemeData.language,
                  )}
                </span>
                {allowAdditionalPayment && priceDifference > 0 && (
                  <span className="tag is-primary">
                    + {getCurrencyFormat(priceDifference, order.currency, appThemeData.language)}
                  </span>
                )}
              </div>
            </footer>
          </div>
        </div>,
      ];
    });
  });

  if (isLoading) {
    return <div className="custom-loader is-loading"></div>;
  }

  return (
    <React.Fragment>
      <div className="columns is-mobile is-multiline">{optionsMarkup}</div>
      <Notification size="small" hide={!optionsMarkup || optionsMarkup.length > 0}>
        <p>{appTexts.itemWizard.noProductFound}</p>
      </Notification>
    </React.Fragment>
  );
};

export default PickByTag;
