import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AppTexts,
  CarrierOption,
  filterByCondition,
  formatCurrency,
  getCarrierOptionKey,
} from '../../../../../functions/src/shared';
import { useFirebase } from '../../firebase/context';
import { useAppTexts } from '../../hooks/useAppTexts';
import { RadioSelector } from './RadioSelector';
import './Shipping.scss';
import { useNonNullableContext } from '../../hooks/useNonNullableContext';
import { WizardContext } from '../../contexts/WizardContext/WizardContext';
import ContinueWithReturn from '../ItemsList/ContinueWithReturn';
import { useLoadedFirebaseData } from '../../firebase/hooks';
import { filterByWeight } from '../../helpers/filterByWeight';

const makeId = (option: CarrierOption): string =>
  `${getCarrierOptionKey(option)}${option.condition}`;

export const Shipping = () => {
  const firebase = useFirebase();
  const { publicConfig: appThemeData, returnOptions } = useLoadedFirebaseData();
  const appTexts = useAppTexts();
  const history = useHistory();
  const { setWizardData, returnOption, hasExchangeActions, collectedWizardData } =
    useNonNullableContext(WizardContext);

  const options = useMemo(() => {
    const applicableOptions = filterByCondition(
      returnOptions?.options ?? [],
      hasExchangeActions ? 'exchange' : 'return',
    );

    const availableOptionByWeight = filterByWeight(applicableOptions, collectedWizardData);

    return availableOptionByWeight.sort((a, b) => {
      // Absent price should be at bottom
      return (
        (a.price?.amount ?? Number.MAX_SAFE_INTEGER) - (b.price?.amount ?? Number.MAX_SAFE_INTEGER)
      );
    });
  }, [returnOptions?.options, hasExchangeActions, collectedWizardData]);

  return (
    <>
      <div className="shipping-page">
        <h1 className="items-list-title">{appTexts.shipping.returnMethod}</h1>
        <h2 className="items-list-subtitle">{appTexts.shipping.pleaseSelectMethod}</h2>

        <div className="shipping-selector-container">
          <RadioSelector
            options={
              options.map((option) => ({
                id: makeId(option),
                label: option.label,
                alwaysChildren: (
                  <div
                    className="card-selector__description"
                    dangerouslySetInnerHTML={{ __html: option.descriptionHtml ?? '' }}
                  />
                ),
                tag: option.price && (
                  <PriceTag
                    price={option.price.amount}
                    currency={option.price.currency}
                    appTexts={appTexts}
                    language={appThemeData.language}
                  />
                ),
              })) ?? []
            }
            selected={returnOption ? makeId(returnOption) : null}
            onSelect={(optionId) => {
              const selectedOption = returnOptions?.options.find((option) => {
                return makeId(option) === optionId;
              });

              if (!selectedOption) {
                throw new Error('Failed to find selected option');
              }

              setWizardData({
                returnOption: selectedOption,
              });
            }}
          />
        </div>
      </div>
      <ContinueWithReturn
        continueDisabled={!returnOption}
        onContinue={() => {
          history.push(firebase.getPathTo('review'));
        }}
      />
    </>
  );
};

const PriceTag = ({
  price,
  currency,
  language,
  appTexts,
}: {
  price: number;
  currency: string;
  language: string;
  appTexts: AppTexts;
}) => {
  return (
    <span className={`tag ${price === 0 ? 'is-success' : 'is-light'}`}>
      {price === 0 ? appTexts.generic.free : formatCurrency(price, currency, language)}
    </span>
  );
};
