import { AppTexts } from '../../../../../../functions/src/shared';

export const es: AppTexts = {
  generic: {
    logo: 'Logotipo',
    signOut: 'Haz clic aquí para eliminar todas las preferencias de devolución (empezar de nuevo).',
    goBack: 'Volver',
    exchange: 'Cambio',
    return: 'Devolución',
    returnAlreadySubmitted: 'Parece que ya has enviado esta devolución.',
    multipleRefundsPossible:
      'Estás solicitando el reembolso de un pedido. En este caso, recibirás más de una notificación de reembolso porque tus fondos están (parcialmente) en el primer pedido realizado.',
    submitAnotherReturn: 'Solicitar otra devolución',
    free: 'Gratis',
    next: 'Siguiente',
    error: {
      problemLoading: 'Hay un problema para cargar la página',
      technicalProblem:
        'Hay un problema técnico que está impidiendo que la página se cargue correctamente.',
      comeBack: 'Vuelve en unos minutos e inténtalo de nuevo.',
      contactSupport: 'Contactar con soporte',
      serverError: 'Error del servidor',
      networkError: 'Error de red',
      timeout:
        'Parece que el servidor está tardando demasiado en responder. Esto puede deberse a una mala conectividad o a un error del servidor. Por favor, inténtalo de nuevo más tarde.',
      other:
        'Parece que algo ha salido mal. Vuelve a intentarlo más tarde o ponte en contacto con nosotros a través de {{supportEmail}}',
    },
    form: {
      fieldRequired: 'Este campo es obligatorio',
      invalidEmail: 'La dirección de correo electrónico no es válida',
    },
  },
  footer: {
    default:
      '¿Necesitas ayuda para devolver tus productos? Por favor, ponte en contacto con nosotros a través de {{supportEmail}}',
    privacyPolicy: 'Política de privacidad',
  },
  orderLookup: {
    title: 'Cambios y devoluciones',
    pageTitle: 'Iniciar una nueva devolución o cambio',
    orderNumber: 'Número de pedido',
    postalcode: 'Código postal',
    emailaddress: 'Dirección de correo electrónico',
    any: 'Dirección de correo electrónico o código postal',
    submit: 'Comenzar',
    error: {
      notFound: 'Imposible encontrar el pedido. Por favor, revisa tus datos e inténtalo de nuevo.',
      currencyNotSupported:
        'This platform can only handle returns in {{supportedCurrency}}. Please contact {{supportEmail}} to handle your return.',
    },
  },
  itemsList: {
    title: 'Selecciona el producto que deseas cambiar o devolver',
    pageTitle: 'Pedido',
    ineligibleSubtitle: 'No se pueden devolver todos los artículos de este pedido.',
    returnableUntil: 'Puedes devolver el pedido {{orderName}} hasta el {{returnableUntilDate}}',
    noItemsEligible: 'En estos momentos, no hay ningún producto que puedas devolver.',
    noReturnCountry:
      'Ningún producto es elegible para devolución en este momento, porque no tenemos una política de devolución para su país. Póngase en contacto con el soporte para completar su devolución.',
    returnWindowClosed:
      'El plazo de devolución ha expirado. \n Ya no puedes solicitar la devolución de los productos.',
    warrantyReturnPossible: 'Estos productos todavía están en garantía.',
    submitWarrantyRequest: 'Enviar solicitud de garantía.',
    returnAlreadySubmitted:
      'Ya se ha enviado una solicitud de devolución para el pedido {{orderName}}.',
    productsWithTechnicalErrors:
      'No puedes devolver ni cambiar uno o más productos de tu pedido debido a un problema técnico. Por favor, ponte en contacto con nosotros a través de {{supportEmail}}',
    lastUpdate: 'Última actualización',
    selectedItems: '{{count}} producto(s) seleccionado(s)',
    continueWithReturn: 'Continuar con devolución',
    cannotContinueWithReturn:
      'Los artículos seleccionados superan el importe de la devolución. Ajusta la selección para que el valor de los artículos solicitados sea mayor o igual al de los artículos que deseas devolver/cambiar.',
    expectedArrivalDate: 'Fecha de llegada prevista',
    paymentPending: {
      title: 'Pago pendiente',
      message:
        'Estamos a la espera de que completes el pago. Por favor, revisa tu correo electrónico o ponte en contacto con nosotros a través de {{email}}',
    },
    adjustmentsInCalculation: 'El cálculo incluye costes adicionales',
  },
  shippingLabel: {
    isPaidLabel:
      'You will have to pay {{amount}} in order to receive your {{carrier}} return label.',
  },
  item: {
    exchangeInfoDiscount:
      'El importe del producto que deseas cambiar es {{exchangeValue}}.\nSi decides devolver el artículo, se te reembolsará {{refundValue}}.',
    exchangeInfoPriceIncrease: {
      always:
        'Debido a un reciente aumento de precio\nel valor de cambio de este producto es {{exchangeValue}}.\nSi decides devolver el artículo se te reembolsará {{refundValue}}.',
      'both-match':
        'Debido a un reciente aumento de precio\nel valor de cambio de este producto es {{exchangeValue}} para los productos seleccionados.\nSi decides devolver el artículo se te reembolsará {{refundValue}}.',
      'same-product':
        'Debido a un reciente aumento de precio\nel valor de cambio de este producto es {{exchangeValue}} cuando se cambia por otra variante.\nSi decides devolver el artículo se te reembolsará {{refundValue}}.',
      'same-variant':
        'Debido a un reciente aumento de precio\nel valor de cambio de este producto es {{exchangeValue}} cuando se cambia por el mismo producto.\nSi decides devolver el artículo se te reembolsará {{refundValue}}.',
      none: '',
    },
    adjustmentDecrease: 'Debido a la devolución de otro producto, se aplica más descuento.',
    adjustmentIncrease: 'Debido a la devolución de otro producto, se aplica menos descuento.',
  },
  tracking: {
    status: {
      droppedOff: 'entregado en el centro de envío',
      inTransit: 'en tránsito',
      submitted: 'enviado',
      delivered: 'entregado',
    },
  },
  itemWizard: {
    isCurrentVariant: 'Actual',
    chooseVariant: 'Selecciona una variante',
    outOfStock: 'Agotado',
    reasonsInfoBottom: '',
    otherCollectionInfoTop: '',
    otherVariantInfoTop: '',
    reasonsWhyExchange: '¿Por qué deseas cambiar el artículo?',
    reasonsWhyReturn: '¿Por qué deseas devolver el artículo?',
    typeCommentHereAndCount: 'Escribe tu comentario aquí (mín. {{count}} caracteres)...',
    typeCommentHere: 'Escribe tu comentario aquí...',
    commentIsEmpty: 'El campo de comentarios no puede estar vacío.',
    commentIsTooShort: 'El comentario debe contener al menos {{count}} caracteres.',
    submitComment: 'Enviar comentario',
    noProductFound: 'No se ha encontrado ningún producto relacionado',
    from: 'De',
    howMakeItRight: '¿Cómo podemos solucionarlo?',
    sendSameProduct: 'Envíame el mismo producto',
    showMoreOptions: 'Mostrar más opciones',
  },
  shipping: {
    returnMethod: 'Método de devolución',
    pleaseSelectMethod: 'Selecciona un método de devolución',
  },
  review: {
    title: 'Revisa tu devolución',
    pageTitle: 'Revisa tu devolución',
    itemsToReceive: 'Producto(s) que recibirás',
    itemsToSendBack: 'Producto(s) a devolver',
    shippingAddressTitle: 'Enviaremos el cambio a',
    deliveryOptionTitle: '¿A dónde debemos enviar el artículo?',
    returnShippingCosts: 'Costes de envío de devolución',
    deliveryOption: {
      billingAddress: 'Mi domicilio (dirección de facturación) o añadir nueva dirección',
      pickupPoint: 'Al punto de recogida seleccionado en el pedido original',
    },
    noItemsSelected:
      'Todavía no has seleccionado ningún producto. Vuelve y selecciona los artículos que deseas cambiar o devolver.',
    submitReturn: 'Enviar devolución',
    submitReturnAndPay: 'Pay for return label and confirm return',
    removedDiscount: 'Pago adicional por {{itemTitle}}',
    extraDiscount: 'Descuento extra por {{itemTitle}}',
    refund: {
      title: 'Resumen',
      calculationPriceIncreasement: 'Ajuste por aumento de precio',
      valueOfReturnedProducts: 'Importe de los productos devueltos',
      valueOfExchangeProducts: 'Importe de los productos cambiados',
      appliedDiscountToOrder: 'Descuento del pedido original',
      totalRefundAmount: 'Importe total del reembolso',
      totalAdditionalPayment: 'Pago adicional total',
      additionalPaymentInfo:
        'Hay pagos pendientes por completar. Una vez aprobemos tu solicitud, te enviaremos un enlace a: {{email}} para que completes el pago pendiente',
      shippingCostRefundInfo:
        'Cuando hayas pagado los gastos de envío, también te los reembolsaremos. Aunque no se incluyen en el importe total del reembolso, se añadirán al procesarlo.',
    },
    budbee: {
      typePhoneNumberHere: 'Escriba su número de teléfono móvil aquí',
      hasBillingPhoneNumber:
        'Usaremos {{phoneNumber}} para su recogida de Budbee. Si desea utilizar otro número de teléfono, rellénelo a continuación:',
      hasNoBillingPhoneNumber:
        'No tenemos su número de teléfono. Budbee lo necesita para informarle sobre la recogida. Complételo a continuación:',
      phoneNumberInvalid:
        'Su número de teléfono no es válido, incluya el código de país y un "+", p. "+34987654321".',
    },
    sendcloud: {
      header: '¡Necesitamos más información!',
      explanation:
        'Para proporcionar la información correcta a la empresa de envíos, necesitamos tu dirección y número de calle. Por favor, complétalos a continuación para que podamos crear una devolución para ti.',
      streetNamePlaceholder: 'Nombre de la calle',
      streetNumberPlaceholder: 'Número de calle',
      streetNameInvalid: 'No es un nombre de calle válido, por favor complete algo.',
      streetNumberInvalid:
        "No es un número de calle válido. Debería comenzar con un número, p.e. '42B' y su longitud no puede exceder los 20 caracteres.",
    },
    comment: {
      infoExchange:
        'Si deseas cambiar de dirección, introduce una nueva en el siguiente campo de comentarios.',
      infoRefund:
        'Si deseas añadir alguna observación adicional, por favor, escribe un comentario.',
      addComment: 'Escribir comentario',
      clearComment: 'Borrar comentario',
      addAddress: 'Añadir nueva dirección',
      clearAddress: 'Borrar nueva dirección',
      commentIsEmpty: 'El campo de comentarios no puede estar vacío.',
      commentIsTooShort: 'El comentario debe contener al menos {{count}} caracteres.',
    },
    error: {
      notAccepted: 'Esta devolución ya se ha enviado.',
    },
    additionalRefundInfo:
      'Realizaremos el reembolso a la cuenta (o tarjeta regalo) que se utilizó para completar la transacción original.',
  },
  summary: {
    title: 'Resumen de tu devolución',
    pageTitle: 'Resumen de devolución',
    generatingLabel: 'Procesando la solicitud de devolución de etiquetas',
    submittingPickUp: 'Se está enviando su solicitud de recogida de Budbee',
    labelReady: 'La etiqueta está lista para imprimir',
    paymentLinkReady: 'Presiona el botón de abajo cuando no seas redirigido',
    thanksSubmitting: 'Gracias por enviar la devolución',
    thanksSubmittingBudbee:
      'La hora estimada de recogida de su paquete es el {{day}} entre {{start}} y {{stop}}. Si desea realizar un seguimiento o cambiar la recogida, utilice la aplicación Budbee con el número de teléfono que envió con esta devolución: {{phoneNumber}}.',
    noLabel:
      'Parece que se ha producido un error al obtener la etiqueta. Ponte en contacto con nosotros a través de {{ supportEmail }} y te enviaremos la etiqueta correcta cuanto antes.',
    labelNotPaid:
      'Parece que no se paga la etiqueta de devolución. Utilice el botón de abajo para reiniciar el proceso de pago. En caso de que ya haya pagado y vea este error, contáctenos en {{ supportEmail }}',
    downloadLabel: 'Descargar etiqueta de envío',
    redirectToPayment: 'Continuar con el pago',
    restartPayment: 'Reiniciar proceso de pago',
    mailWillBeSentTo:
      'La etiqueta de envío y las instrucciones también se enviarán por correo electrónico a',
    mailWillBeSentToOnlyInstructions:
      'Las instrucciones también se enviarán por correo electrónico a',
    mailWillBeSentToPaperless: 'Las instrucciones se enviarán por correo electrónico a',
    mailWillBeSentToPaperlessSub:
      'Este correo electrónico también contiene un código de barras que necesitarás al entregar el paquete.',
    howToTitle: 'Cómo devolver los artículos',
    itemsToSendBack: 'Producto(s) a devolver',
    itemsToReceive: 'Producto(s) que recibirás',
    estimatedProcessingTime:
      'Una vez entregas el paquete, solemos tardar {{count}} día(s) hábil(es) en procesar la devolución.',
    commercialInvoiceReady: 'Customs Invoice Ready',
    downloadCommercialInvoice: 'Download customs invoice',
    noCommercialInvoice:
      'It seems that something went wrong while generating the customs invoice.  Contact us at {{ supportEmail }} and we will send you the correct invoice to attach to your return.',
  },
  rma: {
    option: {
      genericExchange: 'Cambio por otro producto',
      genericReturn: 'Devolver producto',
    },
    warning: {
      alreadyReturned: 'El producto ya se ha devuelto',
      rmaInProcess: 'Producto ya enviado',
      unfulfilledLineItem: 'Producto no recibido',
      noReturnGiftCards: 'Las tarjetas de regalo no son elegibles para devoluciones',
      noPhysicalProduct: 'Los productos digitales no son elegibles para devoluciones',
      returnWindowExpired: 'El plazo de devolución ha vencido',
      countryNotReturnable: 'Lamentablemente, no podemos aceptar este artículo de su país',
      notEligibleForReturn:
        'Este producto no es elegible para devolución, si tiene preguntas, comuníquese con el servicio al cliente',
    },
  },
  pageNotFound: {
    title: '404',
    body: 'Página no encontrada',
  },
  shopNotFound: {
    title: '403',
    body: 'Acceso no permitido',
  },
  noApp: {
    title: 'Navegador no compatible',
    notPossible:
      'Lamentablemente, no es posible realizar cambios o devoluciones desde Internet Explorer.',
    useDifferentBrowser: 'Utiliza un navegador diferente',
    orVisitWithPhone: 'o visita esta página desde el móvil',
  },
  delivery: {
    other: 'Enviar a otra dirección',
    otherAddress: 'Utiliza el campo de comentarios para introducir una nueva dirección',
  },
};
