import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';

export interface WizardSelectorOption<T> {
  label: string;
  subText?: string;
  tagLeft?: ReactNode;
  tagRight?: ReactNode;
  disabled?: boolean;
  chevronOverride?: ReactNode;
  formattedPrice?: string;
  value: T;
}

interface Props<T> {
  options: WizardSelectorOption<T>[];
  onSelect: (value: T) => void;
}

export default function WizardSelector<T>({ options, onSelect }: Props<T>) {
  return (
    <>
      {options.map((option, i) => {
        return (
          <button
            key={i}
            className="button is-fullwidth item-wizard-option"
            onClick={() => onSelect(option.value)}
            disabled={option.disabled}
          >
            <div className="item-wizard-option-texts">
              <div>
                <div>{option.label}</div>
                {option.subText && (
                  <div className="item-wizard-option-subtext">{option.subText}</div>
                )}
              </div>
              {option.tagLeft}
            </div>
            {option.formattedPrice && (
              <span className="item-wizard-option-price">{option.formattedPrice}</span>
            )}
            {option.tagRight}
            {option.chevronOverride || <FontAwesomeIcon icon="chevron-right" />}
          </button>
        );
      })}
    </>
  );
}
