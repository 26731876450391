export const resizeImage = (image: string, width: number, height: number) => {
  if (!image) return null;

  const imageParts = image.split('.');
  const newImage =
    image.replace(`.${imageParts[imageParts.length - 1]}`, '') +
    `_${width}x${height}.${imageParts[imageParts.length - 1]}`;

  return newImage;
};
