import {
  BaseRmaRequest,
  RichRmaItem,
  RichRmaRequest,
  RmaStatus,
  TRMAItem,
  TRMAOperation,
  TRMATimeline,
} from "./rma_request_types";
import { formatCurrency } from "./formatCurrency";
import { formatLocalizedDate, formatLocalizedTimeDistance } from "./formatLocalizedDate";
import { SfyOrderType } from "./sfy_order_types";
import { GlobalConfig } from "./configs/GlobalConfig";

export const enrichRmaDocData = (
  rmaDocData: BaseRmaRequest & { rmaId: string },
  config: GlobalConfig | undefined,
  locale: string,
  timezone?: string,
): RichRmaRequest => {
  return {
    ...rmaDocData,
    status: getRmaStatus(rmaDocData, locale, timezone),
    orderCreatedAtFormatted: formatLocalizedDate(rmaDocData.orderCreatedAt, locale, timezone),
    rmaProcessedAtFormatted: formatLocalizedDate(rmaDocData.rmaProcessedAt, locale, timezone),
    rmaReturnedAtFormatted: formatLocalizedDate(rmaDocData.rmaReturnedAt, locale, timezone),
    rmaShippedAtFormatted: formatLocalizedDate(rmaDocData.rmaShippedAt, locale, timezone),
    rmaSubmittedAtFormatted: formatLocalizedDate(rmaDocData.rmaSubmittedAt, locale, timezone),
    rmaTotalProductReturnWorthFormatted: formatCurrency(
      rmaDocData.rmaTotalProductReturnWorth,
      rmaDocData.orderCurrency,
      locale,
    ),
    rmaTotalProductExchangeWorthFormatted: formatCurrency(
      rmaDocData.rmaTotalProductExchangeWorth,
      rmaDocData.orderCurrency,
      locale,
    ),
    rmaTotalOrderRefundWorthFormatted: formatCurrency(
      rmaDocData.rmaTotalOrderRefundWorth,
      rmaDocData.orderCurrency,
      locale,
    ),
    rmaTotalOrderRefundOldWorthFormatted: formatCurrency(
      rmaDocData.rmaTotalOrderRefundOldWorth,
      rmaDocData.orderCurrency,
      locale,
    ),
    rmaTotalOrderAdditionalPaymentWorthFormatted: formatCurrency(
      rmaDocData.rmaTotalOrderAdditionalPaymentWorth,
      rmaDocData.orderCurrency,
      locale,
    ),
    rmaItems: Array.isArray(rmaDocData.rmaItems)
      ? rmaDocData.rmaItems.map((item): RichRmaItem => {
          const { originalPrice, originalDiscountedPrice, exchangePrice, exchangeDiscountedPrice } =
            item;
          return {
            ...item,
            originalPriceFormatted: formatCurrency(originalPrice, rmaDocData.orderCurrency, locale),
            originalDiscountedPriceFormatted:
              typeof originalDiscountedPrice === "number" &&
              typeof originalPrice === "number" &&
              originalDiscountedPrice < originalPrice
                ? formatCurrency(originalDiscountedPrice, rmaDocData.orderCurrency, locale)
                : null,
            exchangePriceFormatted: formatCurrency(exchangePrice, rmaDocData.orderCurrency, locale),
            exchangeDiscountedPriceFormatted:
              typeof exchangeDiscountedPrice === "number" &&
              typeof exchangePrice === "number" &&
              exchangeDiscountedPrice < exchangePrice
                ? formatCurrency(exchangeDiscountedPrice, rmaDocData.orderCurrency, locale)
                : null,
          };
        })
      : [],
    itemTypeCounters: Array.isArray(rmaDocData.rmaItems)
      ? getItemTypeCounters(rmaDocData.rmaItems)
      : {
          exchange: 0,
          return: 0,
          combination: 0,
          unknown: 0,
        },
    timeline: rmaDocData.timeline && transformTimeline(rmaDocData.timeline, locale, timezone),
    tracking: rmaDocData.tracking && {
      ...rmaDocData.tracking,
      lastUpdateFormatted: rmaDocData.tracking.lastUpdate
        ? formatLocalizedDate(rmaDocData.tracking.lastUpdate, locale, timezone)
        : null,
    },
    isReturnWindowClosed: !calculateIfReturnWindowIsOpen(rmaDocData, config),
  };
};

const getRmaStatus = (
  {
    rmaSubmittedAt,
    rmaIsShipping,
    rmaShippedAt,
    rmaIsReturned,
    rmaReturnedAt,
    rmaIsPaymentPending,
    rmaPaymentPendingAt,
    rmaIsProcessed,
    rmaProcessedAt,
    rmaIsProcessError,
    rmaProcessErrorAt,
    rmaIsFlagged,
  }: BaseRmaRequest,
  locale: string,
  timezone?: string,
): RichRmaRequest["status"] => {
  if (rmaIsProcessError && rmaIsFlagged)
    return {
      id: RmaStatus.ProcessError,
      date: rmaProcessErrorAt,
      dateFormatted: formatLocalizedDate(rmaProcessErrorAt, locale, timezone),
    };

  if (rmaIsProcessed)
    return {
      id: RmaStatus.Processed,
      date: rmaProcessedAt,
      dateFormatted: formatLocalizedDate(rmaProcessedAt, locale, timezone),
    };

  if (rmaIsPaymentPending)
    return {
      id: RmaStatus.PaymentPending,
      date: rmaPaymentPendingAt,
      dateFormatted: formatLocalizedDate(rmaPaymentPendingAt, locale, timezone),
    };

  if (rmaIsReturned)
    return {
      id: RmaStatus.Returned,
      date: rmaReturnedAt,
      dateFormatted: formatLocalizedDate(rmaReturnedAt, locale, timezone),
    };

  if (rmaIsShipping)
    return {
      id: RmaStatus.Shipping,
      date: rmaShippedAt,
      dateFormatted: formatLocalizedDate(rmaShippedAt, locale, timezone),
    };

  return {
    id: RmaStatus.Submitted,
    date: rmaSubmittedAt,
    dateFormatted: formatLocalizedDate(rmaSubmittedAt, locale, timezone),
  };
};

const getItemTypeCounters = (rmaItems: TRMAItem[]): Record<TRMAOperation, number> => {
  return rmaItems.reduce<Record<TRMAOperation, number>>(
    (itemTypes, { type }) => {
      if (!type) return itemTypes;

      ++itemTypes[type];
      return itemTypes;
    },
    {
      exchange: 0,
      return: 0,
      combination: 0,
      unknown: 0,
    },
  );
};

const transformTimeline = (
  timeline: TRMATimeline<number>[],
  locale: string,
  timezone?: string,
): RichRmaRequest["timeline"] =>
  timeline.map((timelineItem) => {
    const { time } = timelineItem;

    return {
      ...timelineItem,
      exactTimeFormatted: formatLocalizedDate(time, locale, timezone),
      distanceTimeFormatted: formatLocalizedTimeDistance(time, locale, timezone),
    };
  });

export const calculateIfReturnWindowIsOpen = (
  rmaOrOrder: BaseRmaRequest | SfyOrderType,
  config: GlobalConfig | undefined,
): boolean => {
  const orderTags =
    typeof rmaOrOrder.tags === "string" ? rmaOrOrder.tags.split(", ") : rmaOrOrder.tags;

  const hasReturnWindowException = orderTags?.some((tag) => {
    return config?.returnWindowExceptionTags?.includes(tag);
  });

  return (
    hasReturnWindowException ||
    calculateIfWindowIsOpen(rmaOrOrder, config?.maximumAgeInDays ?? Number.MAX_SAFE_INTEGER)
  );
};

export const calculateIfWindowIsOpen = (
  rmaOrOrder: BaseRmaRequest | SfyOrderType,
  days: number,
): boolean => {
  return Date.now() < getMaxDateMillis(rmaOrOrder, days);
};

export const getMaxDateMillis = (
  rmaOrOrder: BaseRmaRequest | SfyOrderType,
  days: number,
): number => {
  const milliseconds = days * 24 * 60 * 60 * 1000;
  return getOrderFirstFulfillmentAtMillis(rmaOrOrder) + milliseconds;
};

const getOrderFirstFulfillmentAtMillis = (rmaOrOrder: BaseRmaRequest | SfyOrderType): number => {
  if ("rmaName" in rmaOrOrder) {
    return rmaOrOrder.orderFirstFulfillmentAt ?? rmaOrOrder.orderCreatedAt ?? 0;
  }

  if (!("billingAddress" in rmaOrOrder)) {
    // Typescript couldn't deduct type, so we added this guard. Should never happen.
    throw new Error("Invalid order type");
  }

  const fulfillmentsAscending = [...(rmaOrOrder.fulfillments ?? [])].sort(
    (a, b) => a.createdAt.toMillis() - b.createdAt.toMillis(),
  );

  const firstFulfillmentDate = fulfillmentsAscending[0]?.createdAt;

  return firstFulfillmentDate?.toMillis() ?? rmaOrOrder.createdAt?.toMillis() ?? 0;
};
