import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { WizardContext } from '../../contexts/WizardContext/WizardContext';
import { interpolateAppText, useAppTexts } from '../../hooks/useAppTexts';
import { useNonNullableContext } from '../../hooks/useNonNullableContext';
import { BottomBar } from '../BottomBar/BottomBar';
import { Notification } from '../Notification';
import { useLoadedFirebaseData } from '../../firebase/hooks';

const ContinueWithReturn = ({
  onContinue,
  continueDisabled,
}: {
  onContinue: () => void;
  continueDisabled?: boolean;
}) => {
  const history = useHistory();
  const location = useLocation();
  const firebase = useLoadedFirebaseData();
  const appTexts = useAppTexts();
  const {
    nrOfSelectedItems,
    allActions,
    exchangeActions,
    refundTotal,
    refundTotalBeforeDiscount,
    formattedRefundTotal,
    formattedRefundTotalBeforeDiscount,
    adjustmentLines,
  } = useNonNullableContext(WizardContext);

  const hasAdjustmentLines = adjustmentLines && adjustmentLines.length > 0;

  const { allowAdditionalPayment } = firebase.config;

  const exchangeItemsAmount = exchangeActions.length;
  const returnItemsAmount = allActions.length - exchangeActions.length;
  const hasDiscount = Math.abs(refundTotalBeforeDiscount) > Math.abs(refundTotal);

  return nrOfSelectedItems > 0 ? (
    <BottomBar
      leftChildren={
        !location.pathname.includes('/exchange') && (
          <button
            className="button is-white custom-button has-icon"
            onClick={() => history.goBack()}
            disabled={false}
          >
            <FontAwesomeIcon icon="chevron-left" />
            <span>{appTexts.generic.goBack}</span>
          </button>
        )
      }
      middleChildren={
        <>
          {!allowAdditionalPayment && refundTotal < 0 && (
            <Notification
              status="warning"
              size="small"
              message={appTexts.itemsList.cannotContinueWithReturn}
            />
          )}
          <table className="continue-with-return-info">
            <tbody>
              <tr>
                <td>
                  {interpolateAppText(appTexts.itemsList.selectedItems, {
                    count: String(nrOfSelectedItems),
                  })}
                  :
                </td>
                <td>
                  {exchangeItemsAmount > 0 && (
                    <span className="tag is-secondary">
                      <span className="has-text-weight-semibold">{exchangeItemsAmount} </span>
                      <span>{appTexts.generic.exchange}</span>
                    </span>
                  )}
                  {returnItemsAmount > 0 && (
                    <span className="tag is-secondary">
                      <span className="has-text-weight-semibold">{returnItemsAmount}</span>
                      <span>{appTexts.generic.return}</span>
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  {
                    appTexts.review.refund[
                      allowAdditionalPayment && refundTotal < 0
                        ? 'totalAdditionalPayment'
                        : 'totalRefundAmount'
                    ]
                  }
                  :
                </td>
                <td className="has-text-weight-semibold">
                  {hasDiscount ? (
                    <span>
                      <span className="item-card-price-discount">
                        {formattedRefundTotalBeforeDiscount}
                      </span>
                      &nbsp;
                      <span>{formattedRefundTotal}</span>
                    </span>
                  ) : (
                    <span>{formattedRefundTotal}</span>
                  )}
                  {hasAdjustmentLines && (
                    <span
                      className="has-tooltip-arrow"
                      data-tooltip={
                        appTexts.itemsList.adjustmentsInCalculation +
                        ':\n' +
                        adjustmentLines
                          .map(
                            (adjustment) =>
                              `${adjustment.description} (${adjustment.formattedAmount})\n`,
                          )
                          .join('')
                      }
                    >
                      <FontAwesomeIcon icon="question-circle" />
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      }
      nextButton={{
        onClick: onContinue,
        disabled: (!allowAdditionalPayment && refundTotal < 0) || Boolean(continueDisabled),
        label: appTexts.itemsList.continueWithReturn,
      }}
    />
  ) : null;
};

export default ContinueWithReturn;
