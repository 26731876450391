import React from 'react';
import { ShippingAddress } from '../../firebase/firebase';
import { useAppTexts } from '../../hooks/useAppTexts';

export const ReviewAddress = ({ address }: { address: ShippingAddress }) => {
  const appTexts = useAppTexts();

  return (
    <div className="review-block">
      <h2 className="review-block-title">{appTexts.review.shippingAddressTitle}</h2>
      <div className="review-address">
        <div className="review-address-block">
          <p>{address.addressLineA}</p>
          <p>{address.addressLineB}</p>
        </div>
      </div>
    </div>
  );
};
