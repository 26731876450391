import React, { useMemo } from 'react';

import { WizardContext } from '../../contexts/WizardContext/WizardContext';
import { useLoadedFirebaseData } from '../../firebase/hooks';
import { interpolateAppText, useAppTexts } from '../../hooks/useAppTexts';
import { useNonNullableContext } from '../../hooks/useNonNullableContext';
import { Notification } from '../Notification';
import { useCurrencyFormatter } from '../../hooks/useCurrencyFormatter';

export const ReviewRefund = ({ email }: { email: string }) => {
  const appTexts = useAppTexts();
  const firebase = useLoadedFirebaseData();
  const { config, discounts, order } = firebase;
  const { allowAdditionalPayment, countrySettings } = config;
  const { countryCode } = firebase.shippingAddress;
  const formatCurrency = useCurrencyFormatter();

  const shippingCountrySettings = countrySettings?.[countryCode] || countrySettings?.default;
  const refundShippingCostsPolicy = shippingCountrySettings?.refundShippingCosts ?? 'none';
  const isExchangeOrder = discounts?.isExchangeOrder || false;

  const shippingCostsRefund =
    (refundShippingCostsPolicy === 'original_only' && !isExchangeOrder) ||
    refundShippingCostsPolicy === 'all';

  const refundableItemsInOrder = order?.lineItems
    .filter((item) => typeof item.variantId === 'number')
    .reduce((total, item) => total + item.quantity, 0);
  const {
    formattedOrderDiscount,
    formattedOrderPriceCompensations,
    amountOwedExchanges,
    returnCredit,
    adjustmentLines,
    hasExchangeActions,
    orderDiscount,
    orderPriceCompensations,
    nrOfSelectedItems,
    refundTotal,
    formattedRefundTotal,
    returnOption,
    keptItemsAdjustments,
  } = useNonNullableContext(WizardContext);

  const showRefundShippingCosts =
    shippingCostsRefund === true &&
    hasExchangeActions === false &&
    refundableItemsInOrder === nrOfSelectedItems;

  const additionalPaymentInfoElement = useMemo(() => {
    const customText = interpolateAppText(appTexts.review.refund.additionalPaymentInfo, {
      email: email,
    });
    return customText;
  }, [appTexts.review.refund.additionalPaymentInfo, email]);

  return (
    <div className="review-block">
      <h2 className="review-block-title">{appTexts.review.refund.title}</h2>
      <div className="review-refund">
        <table className="is-fullwidth review-refund-table">
          <tbody>
            <tr className="review-refund-table--value-returned-products">
              <td>{appTexts.review.refund.valueOfReturnedProducts}</td>
              <td>{formatCurrency(returnCredit)}</td>
            </tr>
            {orderDiscount > 0 && (
              <tr className="review-refund-table--applied-discount">
                <td>{appTexts.review.refund.appliedDiscountToOrder}</td>
                <td>{formattedOrderDiscount}</td>
              </tr>
            )}
            {hasExchangeActions && (
              <tr className="review-refund-table--value-exchange-products">
                <td>{appTexts.review.refund.valueOfExchangeProducts}</td>
                <td>-{formatCurrency(amountOwedExchanges)}</td>
              </tr>
            )}
            {orderPriceCompensations > 0 && (
              <tr className="review-refund-table--price-compenations">
                <td>{appTexts.review.refund.calculationPriceIncreasement}</td>
                <td>{formattedOrderPriceCompensations}</td>
              </tr>
            )}
            {returnOption && returnOption.price !== null && (
              <tr className="review-refund-table--shipping-costs">
                <td>
                  {appTexts.review.returnShippingCosts}
                  {returnOption.label ? ` (${returnOption.label})` : ''}
                </td>
                <td>
                  {returnOption.price.amount === 0
                    ? appTexts.generic.free
                    : `-${formatCurrency(returnOption.price.amount / 100)}`}
                </td>
              </tr>
            )}
            {adjustmentLines?.map((adjustment, i) => (
              <tr key={`adjustment-line-${i}`} className={`review-refund-table--adjustment-${i}`}>
                <td>{adjustment.description}</td>
                <td>{adjustment.formattedAmount}</td>
              </tr>
            ))}
            {keptItemsAdjustments.map((adjustment, i) => {
              const lineItem = order.lineItems.find((item) => item.id === adjustment.lineItemId);

              return (
                <tr
                  key={`kept-items-adjustment-line-${i}`}
                  className={`review-refund-table--kept-items-adjustment-line-${i}`}
                >
                  <td>
                    {interpolateAppText(
                      appTexts.review[adjustment.amount > 0 ? 'removedDiscount' : 'extraDiscount'],
                      { itemTitle: lineItem?.name },
                    )}
                  </td>
                  <td>
                    {adjustment.amount > 0 ? '-' : ''}
                    {formatCurrency(adjustment.amount)}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th>
                {
                  appTexts.review.refund[
                    allowAdditionalPayment && refundTotal < 0
                      ? 'totalAdditionalPayment'
                      : 'totalRefundAmount'
                  ]
                }
              </th>
              <th>{formattedRefundTotal}</th>
            </tr>
            {allowAdditionalPayment && refundTotal < 0 && (
              <tr>
                <td colSpan={2} className="is-notification-cell">
                  <Notification status="info" size="small">
                    <div dangerouslySetInnerHTML={{ __html: additionalPaymentInfoElement }} />
                  </Notification>
                </td>
              </tr>
            )}
            {showRefundShippingCosts && (
              <tr>
                <td colSpan={2} className="is-notification-cell">
                  <Notification status="info" size="small">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: appTexts.review.refund.shippingCostRefundInfo,
                      }}
                    />
                  </Notification>
                </td>
              </tr>
            )}
          </tfoot>
        </table>
      </div>
    </div>
  );
};
