import { AppTexts } from '../../../../../../functions/src/shared';

export const fr: AppTexts = {
  generic: {
    logo: 'Logo',
    signOut: 'Cliquez ici pour effacer toutes vos préférences de retour (recommencer).',
    goBack: 'Précédent',
    exchange: 'Échanges',
    return: 'Retours',
    returnAlreadySubmitted: 'Il semble que vous ayez déjà soumis cette déclaration.',
    multipleRefundsPossible:
      "Vous demandez le remboursement d'une commande d'échange. Lors de leur traitement, vous recevrez plus d'une notification de remboursement car vos fonds sont (partiellement) dans la première commande que vous avez passée.",
    submitAnotherReturn: 'Soumettre une autre déclaration',
    free: 'Gratuit',
    next: 'Suivant',
    error: {
      problemLoading: 'Il y a un problème de chargement de la page',
      technicalProblem: 'Un problème technique empêche le chargement de la page.',
      comeBack: 'Essayez à nouveau dans quelques minutes.',
      contactSupport: 'Contactez notre service clients',
      serverError: 'Erreur du serveur',
      networkError: 'Erreur réseau',
      timeout:
        'Il semblerait que le serveur prenne trop longtemps à répondre. Ceci peut être dû à une mauvaise connexion ou à une erreurs de nos serveurs. Merci de réessayer plus tard.',
      other:
        "On dirait que quelque chose s'est mal passé. Réessayez plus tard ou contactez-nous au {{supportEmail}}",
    },
    form: {
      fieldRequired: 'Ce champ doit être rempli',
      invalidEmail: "L'adresse mail est invalide",
    },
  },
  footer: {
    default:
      "Besoin d'aide pour retourner vos produits ? Merci de nous contacter au {{supportEmail}}",
    privacyPolicy: 'Politique de confidentialité',
  },
  orderLookup: {
    title: 'Échanges et Retours',
    pageTitle: 'Commencer un nouvel échange ou un retour',
    orderNumber: 'Numéro de commande',
    postalcode: 'Code postal',
    emailaddress: 'Adresse e-mail',
    any: 'Adresse e-mail ou code postal',
    submit: 'Commencer',
    error: {
      notFound: 'Commande non trouvée. Merci de vérifier le numéro et de réessayer.',
      currencyNotSupported:
        'This platform can only handle returns in {{supportedCurrency}}. Please contact {{supportEmail}} to handle your return.',
    },
  },
  itemsList: {
    title: 'Choisissez un produit à échanger ou retourner',
    pageTitle: 'Numéro de commande',
    ineligibleSubtitle: 'Certains articles de cette commande ne peuvent pas être retournés',
    returnableUntil:
      "La commande {{orderName}} peut être renvoyée jusqu'au {{returnableUntilDate}}",
    noItemsEligible: 'Aucun produit ne peut être renvoyé.',
    noReturnCountry:
      "Aucun produit ne peut être retourné pour le moment, car nous n'avons pas de politique de retour pour votre pays. Veuillez contacter l'assistance pour effectuer votre retour.",
    returnWindowClosed:
      'La période de retour est dépassée. \n produits ne peuvent pas être renvoyés.',
    warrantyReturnPossible: 'Ces produits sont toujours sous garantie.',
    submitWarrantyRequest: 'Soumettre une demande de garantie.',
    returnAlreadySubmitted:
      'Une demande de retour pour la commande {{orderName}} a déjà été envoyée',
    productsWithTechnicalErrors:
      "Un ou plusieurs produits de votre commande ne peuvent être ni repris ni échangés en raison d'un problème technique. Veuillez nous en informer en nous contactant à {{supportEmail}}",
    lastUpdate: 'Dernière mise à jour',
    selectedItems: '{{count}} produit(s) sélectionné',
    continueWithReturn: 'Procéder au retour',
    cannotContinueWithReturn:
      'Les articles sélectionnés dépassent la valeur du crédit de retour disponible. Veuillez ajuster votre sélection afin que la valeur des articles demandés soit supérieure ou égale à la valeur des articles que vous souhaitez retourner/échanger.',
    expectedArrivalDate: "Date d'arrivée prévue",
    paymentPending: {
      title: 'Paiement en attente',
      message:
        'Nous attendons votre paiement. Veuillez vérifier votre courrier électronique ou nous contacter à {{email}}',
    },
    adjustmentsInCalculation: 'Le calcul contient des ajustements',
  },
  shippingLabel: {
    isPaidLabel:
      'You will have to pay {{amount}} in order to receive your {{carrier}} return label.',
  },
  item: {
    exchangeInfoDiscount:
      "La valeur d'échange de ce produit est de {{exchangeValue}}.\nLorsque vous choisissez de retourner l'article, {{refundValue}} sont remboursés.",
    exchangeInfoPriceIncrease: {
      always:
        'En raison d’une récente augmentation des prix\nla valeur d’échange de ce produit est de {{exchangeValue}}.\nLorsque vous choisissez de retourner l’article {{refundValue}} sont remboursés.',
      'both-match':
        'En raison d’une récente augmentation des prix\nla valeur d’échange de ce produit est de {{exchangeValue}} pour les produits sélectionnés.\nLorsque vous choisissez de retourner l’article {{refundValue}} sont remboursés.',
      'same-product':
        'En raison d’une récente augmentation des prix\nla valeur d’échange de ce produit est de {{exchangeValue}} lors de l’échange pour une autre variante.\nLorsque vous choisissez de retourner l’article {{refundValue}} sont remboursés.',
      'same-variant':
        'En raison d’une récente augmentation des prix\nla valeur d’échange de ce produit est de {{exchangeValue}} lors de l’échange pour le même produit.\nLorsque vous choisissez de retourner l’article {{refundValue}} sont remboursés.',
      none: '',
    },
    adjustmentDecrease:
      "En raison du retour d'un autre produit, une réduction plus importante est applicable.",
    adjustmentIncrease:
      "En raison du retour d'un autre produit, une réduction moindre est applicable.",
  },
  tracking: {
    status: {
      droppedOff: 'Déposé',
      inTransit: 'En chemin',
      submitted: 'Demande reçue',
      delivered: 'Livré',
    },
  },
  itemWizard: {
    isCurrentVariant: 'Actuelle',
    chooseVariant: 'Choisissez une variante',
    outOfStock: 'Épuisé',
    reasonsInfoBottom: '',
    otherCollectionInfoTop: '',
    otherVariantInfoTop: '',
    reasonsWhyExchange: 'Quelle est la raison de votre échange ?',
    reasonsWhyReturn: 'Quelle est la raison de votre retour ?',
    typeCommentHereAndCount: 'Écrivez votre commentaire ici (min. {{count}} caractères)...',
    typeCommentHere: 'Écrivez votre commentaire ici...',
    commentIsEmpty: 'Le champ de commentaire ne peut être vide.',
    commentIsTooShort: 'Le commentaire doit contenir au moins {{count}} caractères.',
    submitComment: 'Envoyer le commentaire',
    noProductFound: 'No related product has been found',
    from: 'À partir de',
    howMakeItRight: 'Comment pouvons-nous vous aider ?',
    sendSameProduct: 'Envoyer le même produit',
    showMoreOptions: "Afficher plus d'options",
  },
  shipping: {
    returnMethod: 'Méthode de retour',
    pleaseSelectMethod: 'Veuillez sélectionner la méthode de retour',
  },
  review: {
    title: 'Veuillez vérifier votre retour',
    pageTitle: 'Veuillez vérifier retour',
    itemsToReceive: 'Produit(s) que vous allez recevoir',
    itemsToSendBack: 'Produit(s) à renvoyer',
    shippingAddressTitle: 'Le produit(s) sera envoyé à',
    deliveryOptionTitle: 'Où devons-nous expédier votre échange ?',
    deliveryOption: {
      billingAddress: 'Mon adresse de facturation ou ajouter une adresse',
      pickupPoint: 'Un point de collecte d’une commande précédente',
    },
    additionalRefundInfo:
      'Nous rembourserons le montant sur le compte (ou le bon) utilisé pour la transaction originale.',
    noItemsSelected:
      "Vous n'avez pas encore sélectionné de produits. Retournez en arrière et sélectionnez les produits que vous souhaitez échanger ou retourner.",
    submitReturn: 'Confirmer le retour',
    submitReturnAndPay: 'Pay for return label and confirm return',
    returnShippingCosts: 'Frais de retour',
    removedDiscount: 'Paiement supplémentaire pour {{itemTitle}}',
    extraDiscount: 'Réduction supplémentaire pour {{itemTitle}}',
    refund: {
      title: 'Résumé',
      calculationPriceIncreasement: "Ajustement de l'augmentation des prix",
      valueOfReturnedProducts: 'Montant des produits retournés',
      valueOfExchangeProducts: 'Montant des produits échangés',
      appliedDiscountToOrder: 'Réduction sur la commande originale',
      totalRefundAmount: 'Montant total remboursé',
      totalAdditionalPayment: 'Paiement supplémentaire total',
      additionalPaymentInfo:
        'Il y a des impayés. Une fois votre demande approuvée, nous enverrons un lien pour payer le montant restant à : {{email}}',
      shippingCostRefundInfo:
        "Lorsque vous avez payé les frais d'expédition, nous vous les rembourserons également. Ceux-ci ne font pas partie du montant total du remboursement ci-dessus et seront ajoutés lorsque nous rembourserons votre commande.",
    },
    budbee: {
      typePhoneNumberHere: 'Tapez ici votre numéro de téléphone mobile',
      hasBillingPhoneNumber:
        'Nous utiliserons {{phoneNumber}} pour votre ramassage Budbee. Si vous souhaitez utiliser un autre numéro de téléphone, remplissez-le ci-dessous :',
      hasNoBillingPhoneNumber:
        "Nous n'avons pas de numéro de téléphone de votre part. Budbee en a besoin pour vous tenir au courant de la prise en charge. Veuillez le remplir ci-dessous:",
      phoneNumberInvalid:
        'Votre numéro de téléphone n\'est pas valide, veuillez inclure le code du pays et un "+", par ex. "+33987654321".',
    },
    sendcloud: {
      header: "Nous avons besoin de plus d'informations!",
      explanation:
        'Afin de fournir les bonnes informations à la compagnie de livraison, nous avons besoin de votre adresse postale et du numéro. Veuillez les renseigner ci-dessous afin que nous puissions créer un retour pour vous.',
      streetNamePlaceholder: 'Nom de la rue',
      streetNumberPlaceholder: 'Numéro de rue',
      streetNameInvalid: 'Nom de rue invalide, veuillez remplir quelque chose.',
      streetNumberInvalid:
        "Pas un numéro de rue valide. Il doit commencer par un chiffre, par ex. '42B' et sa longueur ne peut pas dépasser 20 caractères.",
    },
    comment: {
      infoExchange:
        "Si vous souhaitez changer l'adresse ou ajouter une remarque, merci d'ajouter un commentaire à ce retour. ",
      infoRefund:
        "Si vous souhaitez ajouter une remarque, merci d'ajouter un commentaire à ce retour. ",
      addComment: 'Ajouter un commentaire',
      clearComment: 'Effacer le commentaire',
      addAddress: "Changer d'adresse",
      clearAddress: "Effacer l'adresse",
      commentIsEmpty: 'Le champ de commentaire ne peut être vide.',
      commentIsTooShort: 'Le commentaire doit contenir au moins {{count}} caractères.',
    },
    error: {
      notAccepted: 'Une demande de retour a déjà été faite pour cette commande. ',
    },
  },
  summary: {
    title: 'Résumé de votre retour',
    pageTitle: 'Résumé de retour',
    generatingLabel: "Traiter la demande d'étiquette de retour",
    submittingPickUp: 'Votre demande de ramassage Budbee est en cours de soumission',
    labelReady: 'Vous pouvez désormais imprimer votre étiquette',
    paymentLinkReady: "Appuyez sur le bouton ci-dessous lorsque vous n'êtes pas redirigé",
    thanksSubmitting: "Merci d'avoir soumis votre déclaration",
    thanksSubmittingBudbee:
      "L'heure de ramassage estimée pour votre colis est le {{day}} entre {{start}} et {{stop}}. Si vous souhaitez suivre ou modifier le ramassage, veuillez utiliser l'application Budbee avec le numéro de téléphone que vous avez fourni avec ce retour : {{phoneNumber}}.",
    noLabel: 'Aucune étiquette disponible, merci de nous contacter via {{ supportEmail }}.',
    labelNotPaid:
      "It seems that the return label is not paid. Please use the button below to restart the payment process. In case you paid already and you see this error, please contact us atIl semble que l'étiquette de retour ne soit pas payée. Veuillez utiliser le bouton ci-dessous pour relancer le processus de paiement. Si vous avez déjà payé et que vous voyez cette erreur, veuillez nous contacter à {{ supportEmail }}",
    downloadLabel: "Télécharger l'étiquette d'expédition",
    redirectToPayment: 'Continuer vers le paiement',
    restartPayment: 'Redémarrer le processus de paiement',
    mailWillBeSentTo: "L'étiquette d'expédition ainsi que les instructions sont aussi envoyées à",
    mailWillBeSentToOnlyInstructions: 'Les instructions sont aussi envoyées à',
    mailWillBeSentToPaperless: 'Les instructions sont envoyées par e-mail à',
    mailWillBeSentToPaperlessSub:
      'Cet e-mail contient un code-barres dont vous aurez besoin lors remis votre colis.',
    howToTitle: 'Comment renvoyer vos produits',
    itemsToSendBack: 'Produit(s) à renvoyer',
    itemsToReceive: 'Produit(s) que vous allez recevoir',
    estimatedProcessingTime:
      'Il faut environ {{count}} jour(s) ouvrable pour traiter votre retour après avoir remis votre colis.',
    commercialInvoiceReady: 'Customs Invoice Ready',
    downloadCommercialInvoice: 'Download customs invoice',
    noCommercialInvoice:
      'It seems that something went wrong while generating the customs invoice.  Contact us at {{ supportEmail }} and we will send you the correct invoice to attach to your return.',
  },
  rma: {
    option: {
      genericExchange: 'Échange pour un produit différent',
      genericReturn: 'Retour et remboursement',
    },
    warning: {
      alreadyReturned: 'Ce produit a déjà été renvoyé',
      rmaInProcess: 'Produit déjà soumis',
      unfulfilledLineItem: "Ce produit n'est pas encore envoyé",
      noReturnGiftCards: 'Les cartes cadeaux ne sont pas éligibles aux retours',
      noPhysicalProduct: 'Les produits numériques ne sont pas éligibles aux retours',
      returnWindowExpired: 'La période de retour est dépassée',
      countryNotReturnable:
        'Malheureusement, nous ne pouvons pas accepter cet article de votre pays',
      notEligibleForReturn:
        "Ce produit n'est pas éligible au retour, pour toute question, veuillez contacter le service client",
    },
  },
  pageNotFound: {
    title: '404',
    body: 'Page non trouvée',
  },
  shopNotFound: {
    title: '403',
    body: 'Accès non autorisé',
  },
  noApp: {
    title: 'Navigateur non compatible.',
    notPossible:
      'Les échanges et les retours ne peuvent malheureusement pas être faits sur Internet Explorer.',
    useDifferentBrowser: "Merci d'utiliser un navigateur différent",
    orVisitWithPhone: 'ou de visiter cette page depuis votre téléphone.',
  },
  delivery: {
    other: 'Envoyer à une autre adresse',
    otherAddress: 'Utilisez le champ de commentaire pour entrer une nouvelle adresse',
  },
};
